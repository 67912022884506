.rewarded {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 45px;
    column-gap: 25px;

    @media screen and (min-width: 1535px) {
      grid-template-columns: 1fr 567px;
    }
  }


  & .section__header {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 1535px) {
      align-items: flex-start;
    }

    & .section__title {
      margin-bottom: 20px;
      text-align: center;
      text-wrap: pretty;

      @media screen and (min-width: 1535px) {
        text-align: left;
        max-width: 30ch;
      }
    }

    .btn {
      width: 263px;
      min-height: 60px;

      &:active {
        color: var(--full-dark);
      }
    }
  }

  & .business-model {
    grid-column: 1;
    grid-row: 2;

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 6px;
      transition: var(--transition);
      will-change: transform;

      @media screen and (min-width: 1535px) {
        width: 100%;
        flex-wrap: nowrap;
        gap: 6px;
      }
    }

    &__item {
      flex: 0 0 300px;

      @media screen and (max-width: 374px) {
        flex: 0 0 290px;
      }

      &.lazy-background.visible {
        background-image: url(../img/business-model/bg.svg);
      }

      &.item {
        position: relative;
        padding: 11px 16px 16px;
        aspect-ratio: 300/258;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .item__wrapper {
          padding: 15px 20px 5px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          text-align: center;
          background: var(--bg);
          border-radius: 20px;
          overflow: hidden;
        }

        .item__image {
          width: 70px;
          height: 60px;
          flex-shrink: 0;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .item__desc {
          margin-top: 25px;
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 24px;
          text-align: center;
          color: var(--white);
        }

        & .btn {
          position: absolute;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
      }
    }

  }

  &__image {
    display: none;

    @include for-desktop {
      grid-column: 1;
      grid-row: 3;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (min-width: 1535px) {
      grid-column: 2;
      grid-row: 1/3;
    }
  }

}