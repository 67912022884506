.bonuses {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;

  .section__header {
    margin-bottom: clamp(32px, 1.737rem + 1.02vw, 40px);
    text-align: center;
  }

  .section__subtitle {
    margin-left: auto;
    margin-right: auto;
    max-width: 120ch;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    @media screen and (max-width:992px) {
      padding-bottom: 20px;
      grid-template-columns: 1fr;
    }

    @media screen and (min-width:1200px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    min-width: 345px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .section__body {
    flex: auto;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 2px;

    @media screen and (min-width:1200px) {
      flex-direction: column;
    }
  }

  &__position,
  &__index {
    flex: 1;
    padding: 0 20px 20px;
    border: 1px solid var(--gray-90);
    border-radius: 22px 5px;

    @media screen and (min-width:1200px) {
      padding: 2px;
    }
  }

  .position {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-left cubic-bezier(.075, .5, 0, 1) 2s;
      }
    }

    @media screen and (min-width:1200px) {
      flex-direction: row;
    }

    &__name {
      padding: clamp(12px, -1.517rem + 2.7vw, 22px) clamp(12px, -1.706rem + 3.02vw, 25px);
      flex: 0 0 87px;
      text-align: center;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: var(--white);

      @media screen and (min-width:1200px) {
        text-align: left;
        flex: 0 0 clamp(255px, -1.61rem + 23.4vw, 379px);
      }
    }

    &__list {
      width: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 2px;

      @media screen and (min-width:1200px) {
        width: auto;
        flex-direction: row;
      }
    }

    &__item {
      border-radius: 20px 4px;
      flex: 1;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bg-light);
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 32px;
      color: var(--yellow);
    }
  }

  .index {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-left cubic-bezier(.075, .5, 0, 1) 2.5s;
      }
    }

    @media screen and (min-width:1200px) {
      flex-direction: row;
    }

    &__name {
      padding: clamp(12px, -1.517rem + 2.7vw, 22px) clamp(12px, -1.706rem + 3.02vw, 25px);
      flex: 0 0 87px;
      text-align: center;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: var(--white);

      @media screen and (min-width:1200px) {
        text-align: left;
        flex: 0 0 clamp(255px, -1.61rem + 23.4vw, 379px);
      }
    }

    &__list {
      width: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 2px;

      @media screen and (min-width:1200px) {
        width: auto;
        flex-direction: row;
      }
    }

    &__item {
      border-radius: 20px 4px;
      flex: 1;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bg-light);
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 24px;
      color: var(--gray-30);
    }
  }
}