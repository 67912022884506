.profile {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);

  & .section__header {
    margin-bottom: 45px;
  }

  &__wrapper {

    & img {
      width: 100%;
      height: auto;
      aspect-ratio: 355/157;
      object-fit: contain;
    }
  }


}