.what {
  padding-top: clamp(30px, 1.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;
  z-index: 10;

  @media screen and (min-width: 567px) {
    overflow: unset;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: clamp(15px, 0.13rem + 3.12vw, 56px);

    @include for-desktop {
      grid-template-columns: 423px auto;
      grid-template-rows: repeat(2, auto);
      column-gap: clamp(15px, 0.13rem + 3.12vw, 56px);
    }
  }

  & .section__header {
    grid-column: 1;
    grid-row: 1;

    @include for-desktop {
      grid-column: 2;
      // transform: translate3d(0, calc(var(--scrollTop) / -9.5), 0);
    }
  }

  & .section__subtitle {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(16px, 0.921rem + 0.3vw, 20px);
    line-height: 160%;
    color: var(--white);
  }

  & .section__body {
    grid-column: 1;
    grid-row: 3;
    transition: var(--transition);
    will-change: transform;

    @include for-desktop {
      grid-column: 2;
      grid-row: 2;
      // transform: translate3d(0, calc(var(--scrollTop) / -9.5), 0);
    }
  }

  &__image {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    grid-column: 1;
    grid-row: 2;
    width: 277px;
    aspect-ratio: 1 / 1;
    transition: var(--transition);
    will-change: transform;

    @include for-desktop {
      grid-row: 1/3;
      width: 100%;
      align-self: center;
      // transform: translate3d(0, calc(var(--scrollTop) / -9), 0);
    }

    &::before {
      content: '';
      position: absolute;
      width: 145%;
      aspect-ratio: 78/47;
      top: 27%;
      left: 7%;
      background-image: url(../img/what/bg.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      z-index: -1;
    }

    & img {
      height: auto;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: clamp(15px, 0.682rem + 0.99vw, 28px);
  }

  &__item {
    display: flex;
    align-items: flex-start;
    column-gap: 13px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, 1.014rem + 0.91vw, 32px);
    color: var(--yellow);

    &::before {
      content: '';
      margin-top: clamp(0px, -0.227rem + 1.14vw, 4px);
      display: block;
      width: 21px;
      height: 21px;
      flex-shrink: 0;
      background: url(../img/star.svg) no-repeat center / contain;
    }
  }
}

.referral--page .what .section__body .btn {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 263px;
  min-height: 60px;

  &:active {
    color: var(--full-dark);
  }

  @include for-desktop {
    margin-left: 0;
    margin-right: 0;
  }
}