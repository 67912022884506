.simplebar-track.simplebar-vertical {
  background-color: #453A37;
  width: 10px;
  border-radius: 5px;
}

.simplebar-content-wrapper {
  padding-right: 15px;
}

.simplebar-scrollbar::before {
  left: 2px;
  top: 0;
  right: 2px;
  bottom: 0;
  opacity: 1;
  background-color: var(--white);
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 0;
  bottom: 0;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}


[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-placeholder {
  display: none !important;
}