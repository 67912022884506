:root {
  // base
  --font-family: "Londrina Solid", sans-serif;
  --second-family: "Roboto", sans-serif;

  --content-width: 1525px;
  --content-offset: 15px;
  --header-height: 74px;

  --bg: #0c0d08;
  --white: #fff;
  --yellow: #ffb800;
  --full-dark: #000;
  --bg-light: #191a15;
  --gray-90: #453a37;
  --gray-60: #715f5b;
  --gray-30: #a5928e;
  --color-dark-red: #d72600;
  --color-green: #17b502;
  --color-yellow-light: #f6fd5e;
  --color-text-link: #1389cb;

  --index-wh: calc(1vw + 1vh);
  --text: #e7e7e0;
  --transition: transform .55s cubic-bezier(.075, .5, 0, 1);
}