.profile-referral-statistics {
  padding-top: 50px;

  & .section__header {
    margin-bottom: 15px;
  }

  &__wrapper {
    width: 100%;
    padding: clamp(10px, 0.483rem + 0.61vw, 15px) clamp(10px, 0.341rem + 1.21vw, 20px);
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-90);
    border-radius: 20px;
    background: var(--full-dark);
  }
}

.statistics {
  --count-index-bonus: 7;

  &__form {
    margin-bottom: 35px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    @media screen and (min-width: 520px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr auto;
    }

    & .input-box {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    & input {
      width: 100%;
      padding: 11px 15px 11px 55px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 24px;
      color: var(--color-dark-red);
      border-radius: 6px;
      height: 51px;
      background: var(--white);

      &::placeholder {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--color-dark-red);
      }
    }

    & .icon {
      position: absolute;
      bottom: 5px;
      left: 7px;
      width: 40px;
      height: 41px;
      z-index: 1;
    }

    & button[type="submit"] {
      margin-left: auto;
      grid-column: 1;
      grid-row: 4;

      &:active {
        color: var(--full-dark);
      }

      @media screen and (min-width: 520px) {
        margin-top: auto;
        grid-column: 2;
        grid-row: 2;
      }

      @media screen and (min-width: 768px) {
        grid-column: 3;
        grid-row: 2;
      }

      @media screen and (min-width: 992px) {
        margin-top: auto;
        grid-column: 4;
        grid-row: 1;
      }
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(24px, 1.358rem + 0.6vw, 32px);
    color: var(--white);
  }

  &__link {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(16px, 0.929rem + 0.3vw, 20px);
    line-height: 160%;
    color: var(--color-text-link);
    transition: 0.2s;

    @include for-tablet {
      width: max-content;
    }

    @include hover {
      color: var(--yellow);
    }
  }

  &-bonus {
    --width-active: 0;

    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 31px;

    @media screen and (min-width: 375px) {
      row-gap: 29px;
    }

    @media screen and (min-width: 567px) {
      row-gap: 27px;
    }

    @media screen and (min-width: 768px) {
      row-gap: 25px;
    }

    @media screen and (min-width: 1200px) {
      row-gap: 23px;
    }

    @media screen and (min-width: 1500px) {
      row-gap: 21px;
    }

    &__info {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__title {
      width: clamp(64px, -0.688rem + 20vw, 229px);

      span {
        width: max-content;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(20px, 0.909rem + 1.45vw, 32px);
        background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__list {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__item {
      position: relative;

      --size-point: clamp(24px, 1.252rem + 1.06vw, 38px);

      span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(20px, 0.909rem + 1.45vw, 32px);
        line-height: 1;
        color: var(--gray-30);
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -46px;
        transform: translateX(-50%);
        width: var(--size-point);
        height: var(--size-point);
        background: url(../img/profile/point.svg) no-repeat center/contain;
        z-index: 2;
      }

      &.bonus-active {

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -46px;
          transform: translateX(-50%);
          width: var(--size-point);
          height: var(--size-point);
          background: url(../img/profile/point-ok.svg) no-repeat center/contain;
          z-index: 3;
        }
      }

      &.no-active {
        &::after {
          display: none;
        }
      }
    }

    &__progress {
      width: 100%;
      position: relative;
      height: 8px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px;
        height: 8px;
        width: var(--width-active);
        background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px;
        width: 98%;
        height: 8px;
        background: var(--gray-90);
      }
    }
  }
}