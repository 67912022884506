.tokenomics {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media screen and (min-width: 1800px) {
    aspect-ratio: 184/122;
  }

  & .bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    & .layer {
      position: absolute;
      z-index: 1;
    }

    & .fon {
      inset: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center;
    }

    & .top-jungle {
      top: -185px;
      left: 0;
      width: 100%;
      height: 450px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;

      @media screen and (min-width: 1800px) {
        height: auto;
        aspect-ratio: 178/45;
      }
    }

    & .ahead {
      bottom: -92px;
      left: 0;
      height: 323px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 5;

      @media screen and (min-width: 1800px) {
        height: auto;
        aspect-ratio: 172/30;
      }
    }
  }

  & .section__header {
    @include for-desktop {}
  }

  &__body {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    z-index: 5;

    .section__header {
      text-align: center;
    }

    .section__subtitle {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 88ch;
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-desktop {
      margin-top: 40px;
      margin-bottom: 40px;
      flex-direction: row;
      column-gap: clamp(20px, -3.25rem + 6vw, 50px);
    }

  }

  &__chart {
    position: relative;
    width: 100%;
    flex: 0.5;
    min-height: 270px;
    text-align: center;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-left cubic-bezier(.075, .5, 0, 1) .7s;
      }
    }


    @include for-desktop {
      width: auto;
      height: auto;
      flex: 0 1 50%;
    }

    & img {
      position: absolute;
      aspect-ratio: 1/1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: clamp(300px, 16.629rem + 8.2vw, 350px);
      height: auto;

      @include for-desktop {
        width: 130%;
      }
    }
  }

  &__legend {
    flex: 1;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-right cubic-bezier(.075, .5, 0, 1) 1s;
      }
    }

    & .mobil-thead {
      padding-right: 15vw;
      padding-bottom: 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: var(--white);

      @media screen and (min-width:680px) {
        display: none;
      }
    }

    @media screen and (max-width:680px) {
      width: 100%;
    }

    @include for-desktop {
      flex: 0 1 50%;
    }

    & .table__wrapper {
      width: 100%;
      height: 100%;
      display: flex;

      @media screen and (max-width:680px) {
        height: 315px;
        max-height: 315px;

      }
    }

    & table {
      width: 100%;
      max-width: 760px;
      margin: 0;
      padding: 0;

      & tr {
        margin-bottom: 2px;
      }

      & th,
      & td {
        padding: 5px;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        color: var(--white);
        text-align: center;
      }

      & tr th {
        padding-bottom: 25px;
      }

      & tr th:first-child,
      & tr td:nth-child(2) {
        text-align: left;
      }

      & tbody tr td:first-child span {
        display: block;
        border-radius: 20px;
        width: 19px;
        height: 36px;
      }

      & tbody tr td:nth-child(2) span {
        display: block;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 16px;
        line-height: 137%;
        color: var(--yellow);
      }

      & tbody tr td:last-child {
        border-radius: 20px 4px;
        width: 250px;
        height: 56px;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        background: var(--bg);

        span {
          color: var(--yellow);
        }
      }

      @media screen and (max-width:680px) {
        display: flex;
        flex-direction: column;
        // max-height: 315px;
        padding-right: 20px;
        overflow-y: scroll;

        & td {
          display: flex;
        }

        & thead {
          display: none;
        }

        & thead tr {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 0.6fr;
        }

        & thead tr th:last-child {
          display: none;
        }

        & tbody {
          width: 100%;
          display: flex;
          flex-direction: column;

        }

        & tbody tr {
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr 0.45fr;
        }

        & tbody tr td:first-child {
          grid-column: 1;
        }

        & tbody tr td:nth-child(2) {
          grid-column: 2;
          flex-direction: column;
        }

        & tbody tr td:nth-child(3) {
          grid-column: 3;
        }

        & tbody tr td:last-child {
          width: 100%;
          padding: 12px clamp(20px, -3.361rem + 19.6vw, 80px);
          grid-column: 1/4;
          grid-row: 2;
          justify-content: space-between;
        }

        & tbody tr td:last-child:before {
          content: attr(data-label);
          color: white;
        }
      }
    }
  }


}