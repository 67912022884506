:root {
  --font-family: "Londrina Solid", sans-serif;
  --second-family: "Roboto", sans-serif;
  --content-width: 1525px;
  --content-offset: 15px;
  --header-height: 74px;
  --bg: #0c0d08;
  --white: #fff;
  --yellow: #ffb800;
  --full-dark: #000;
  --bg-light: #191a15;
  --gray-90: #453a37;
  --gray-60: #715f5b;
  --gray-30: #a5928e;
  --color-dark-red: #d72600;
  --color-green: #17b502;
  --color-yellow-light: #f6fd5e;
  --color-text-link: #1389cb;
  --index-wh: calc(1vw + 1vh);
  --text: #e7e7e0;
  --transition: transform .55s cubic-bezier(.075, .5, 0, 1);
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Londrina Solid";
  src: url("../fonts/LondrinaSolid-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:where(ul, ol):where([class]) {
  padding-left: 0;
}

body,
:where(blockquote, figure):where([class]) {
  margin: 0;
}

body {
  background: var(--bg);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  text-rendering: optimizeSpeed;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
body.disable-scroll {
  overflow: hidden !important;
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
  }
}
.container {
  width: min(var(--content-width), 100% - var(--content-offset) * 2);
  margin-left: auto;
  margin-right: auto;
}

.profile--page .container {
  width: min(var(--content-width), 100% - 20px);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

section {
  position: relative;
  scroll-snap-align: start;
}

.section__title {
  --size-fz-section-title: clamp(32px, 1.476rem + 2.03vw, 54px);
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--size-fz-section-title);
  color: var(--white);
}
.section__title span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--size-fz-section-title);
  background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section__title.title-center {
  text-align: center;
}

.profile--page .section__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__subtitle {
  margin-top: 10px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(16px, 0.921rem + 0.3vw, 20px);
  line-height: 160%;
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

.stage-title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: url(../img/hero/tabs/title.svg) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  text-transform: capitalize;
}

p {
  text-wrap: pretty;
}

img,
picture,
video,
iframe {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
}

picture img,
picture source {
  position: absolute;
  display: block;
  inset: 0;
  max-width: 100%;
  object-fit: contain;
}

.img-wrapper {
  position: relative;
  shape-margin: 0.75rem;
  margin-right: 1rem;
  float: left;
}

.img-wrapper .img-alt {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: -1;
}

iframe {
  width: 100%;
  background: #0A0E14 !important;
  border-radius: 20px;
}

.icon {
  flex-shrink: 0;
  fill: currentColor;
  transition: 0.2s;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s !important;
}
@media (any-hover: hover) {
  a:hover {
    transition: 0.2s !important;
  }
}

svg * {
  transition-property: fill, stroke;
}

.is-hidden {
  display: none !important;
}

*::selection,
*::-moz-selection,
*::-webkit-selection {
  color: var(--light-color);
  background-color: var(--accent-color);
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.dis-scroll {
  position: fixed;
  top: unset !important;
  overflow: hidden;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

@media screen and (max-width: 768px) {
  .no-mobile {
    display: none !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
.btn {
  position: relative;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--white);
  line-height: 1;
  transition: 0.3s !important;
}
.btn .bg {
  position: absolute;
  inset: 0;
  top: -1px;
  width: 100%;
  z-index: 0;
}
.btn .bg path {
  transition: 0.3s !important;
}
.btn span {
  position: relative;
  z-index: 1;
}
.btn:focus {
  outline: none;
}
.btn:focus-visible {
  outline: 2px solid var(--color-green);
  outline-offset: 3px;
}
@media (any-hover: hover) {
  .btn:hover {
    color: var(--full-dark);
    transition: 0.2s !important;
  }
}
.btn:active {
  color: var(--full-dark);
  transition: 0.2s !important;
}
.btn:active .bg path {
  fill: var(--full-dark);
  stroke: var(--color-green);
  transition: 0.2s !important;
}
.btn:disabled {
  opacity: 0.5;
  color: var(--white);
  pointer-events: none;
}
.btn:disabled .bg path {
  fill: var(--gray-60);
  stroke-width: 2px;
  stroke: var(--bg-light);
}
.btn-lang {
  background-color: transparent;
  padding: 0 10px;
}
.btn-lang .flag__wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}
.btn-lang .arrow path {
  transition: 0.3s !important;
}
.btn-lang:hover .arrow path {
  stroke: var(--color-green);
  transition: 0.2s !important;
}
.btn-trans {
  display: flex;
  align-items: center;
  gap: 14px;
  color: var(--white);
  background-color: transparent;
  padding: 0;
  font-family: var(--font-family);
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  pointer-events: all;
  transition: 0.3s !important;
}
.btn-trans .flag {
  font-size: 0;
  pointer-events: none;
}
.btn-trans .lang {
  transition: 0.2s;
  pointer-events: none;
}
.btn-trans:hover {
  transition: 0.2s !important;
}
.btn-trans:hover .lang {
  color: var(--yellow);
  transition: 0.2s !important;
}
.btn.primary-btn {
  width: 182px;
  height: 50px;
  max-height: 50px;
}
.btn.primary-btn .bg, .btn.primary-btn svg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: -1px;
  z-index: 0;
}
.btn.primary-btn:hover {
  transition: 0.2s !important;
}
.btn.primary-btn:active {
  color: var(--color-green);
}
.btn.btn-win {
  width: 154px;
  height: 64px;
  padding-top: 17px;
  background: url(../img/hero/win.png) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--white);
  text-transform: uppercase;
}
.btn.btn-win:hover {
  color: var(--yellow);
  transition: 0.2s !important;
}
.btn.btn-buy-pre {
  width: 181px;
  height: 64px;
  padding-top: 17px;
  background: url(../img/hero/buy.png) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--white);
  text-transform: capitalize;
}
.btn.btn-buy-pre:hover {
  transition: 0.2s !important;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--white-white100);
  background: rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  z-index: 20;
}
.header__container {
  max-width: 1428px;
}
.header.topheader {
  top: -300px;
}
.header.sticky {
  position: fixed;
  top: 0;
  backdrop-filter: blur(25px);
}
.header.sticky .header__container {
  padding-top: 8px;
  padding-bottom: 8px;
}
.header.sticky .header__logo {
  margin-top: -15px;
}
@media (min-width: 1024px) {
  .header.sticky .header__logo {
    margin-top: 0;
    width: clamp(110px, -4.019rem + 17.02vw, 174px);
    left: 43.5%;
  }
}
@media screen and (min-width: 1200px) {
  .header.sticky .header__logo {
    left: 45.2%;
  }
}
@media screen and (min-width: 1500px) {
  .header.sticky .header__logo {
    left: 48vw;
  }
}
@media screen and (min-width: 1700px) {
  .header.sticky .header__logo {
    left: 48vw;
  }
}
.header.sticky .header__logo .logo-desktop {
  display: none;
}
.header.sticky .header__logo .logo-mobile {
  display: block;
  left: 0;
  top: 6px;
  transform: none;
}
@media screen and (min-width: 414px) {
  .header.sticky .header__logo .logo-mobile {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.header.sticky .header__logo .lian {
  display: none;
}
.header.menu--active::after {
  left: 0;
}
.header__logo {
  position: relative;
  flex-shrink: 0;
  width: clamp(140px, 1.516rem + 36.17vw, 174px);
  height: 50px;
  z-index: 5;
}
.header__logo .logo-desktop {
  display: none;
}
.header__logo .logo-mobile {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1024px) {
  .header__logo .logo-mobile {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 414px) {
  .header__logo .logo-mobile {
    width: 110px;
    left: 0;
    transform: none;
  }
}
.header__logo .lian {
  position: absolute;
  width: 66px;
  height: 129px;
  bottom: -128px;
  left: 4px;
  z-index: -1;
  rotate: -17deg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 414px) {
  .header__logo .lian {
    bottom: -104px;
    left: -6px;
  }
}
@media (min-width: 1024px) {
  .header__logo .lian {
    width: 130px;
    height: 255px;
    bottom: calc(100% - clamp(16.313rem, 9.027rem + 11.38vw, 22.688rem));
    left: clamp(1.25rem, -5.179rem + 10.04vw, 110px);
  }
}
@media screen and (min-width: 1024px) {
  .header__logo {
    position: absolute;
    top: 0;
    left: clamp(435px, -6rem + 51.57vw, 787px);
    transform: translateX(-50%);
    width: clamp(170px, -9.665rem + 31.52vw, 390px);
    height: auto;
    aspect-ratio: 27/14;
  }
  .header__logo .logo-desktop {
    display: block;
  }
  .header__logo .logo-mobile {
    display: none;
  }
}
@media screen and (min-width: 1728px) {
  .header__logo {
    left: 46%;
  }
}
.header__logo img {
  position: absolute;
  inset: 0;
  object-fit: contain;
}
.header__container {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(0.625rem, -0.185rem + 4.05vw, 1.25rem);
}
@media (min-width: 1024px) {
  .header__container {
    padding-top: 10px;
    padding-bottom: 13px;
    gap: clamp(15px, -2.297rem + 5.05vw, 34px);
  }
}
.header__nav {
  position: fixed;
  padding: clamp(0.938rem, 0.511rem + 2.13vw, 1.875rem) 11px;
  top: 65px;
  right: -102%;
  width: 100%;
  max-width: 375px;
  height: 100vh;
  height: 100lvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: var(--bg);
  transition: 0.4s ease-in-out;
  overflow-y: auto;
}
.header__nav .mobile__actions {
  width: 100%;
  padding-top: 5vh;
  display: flex;
  justify-content: center;
  gap: 15px;
}
@media (min-width: 1024px) {
  .header__nav .mobile__actions {
    display: none !important;
  }
}
.header__nav .btn-win {
  width: 154px;
  background: url(../img/buttons/primary.png) no-repeat center/contain;
}
.header__nav .primary-btn {
  width: 100%;
  max-width: 330px;
  max-height: 60px;
  margin: 0 auto;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .header__nav .primary-btn {
    display: none !important;
  }
}
.header__nav.menu--active {
  right: 0;
}
@media (min-width: 1024px) {
  .header__nav {
    flex: auto;
    width: auto;
    max-width: none;
    position: relative;
    padding: 0;
    top: 0;
    left: 0;
    height: auto;
    box-shadow: none;
    background-color: transparent;
    gap: clamp(10px, -9rem + 7.83vw, 70px);
    overflow: initial;
  }
}
.header-menu {
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  transition: 0.3s;
}
@media (min-width: 1024px) {
  .header-menu {
    padding: 0;
    width: 100%;
    flex-direction: row;
    gap: 0;
  }
}
.header-menu__item {
  position: relative;
  margin-right: clamp(25px, -2.588rem + 6.45vw, 70px);
  transition: 0.3s;
}
.header-menu__item:nth-of-type(5) {
  margin-left: auto;
  margin-right: clamp(25px, -0.601rem + 2.88vw, 40px);
}
.header-menu__item:nth-of-type(6) {
  margin-right: clamp(15px, -2.668rem + 4.81vw, 40px);
}
.header-menu__item:nth-of-type(7) {
  margin-right: 0;
}
.header-menu__link {
  position: relative;
  margin: 0 auto;
  border: none;
  display: flex;
  justify-content: center;
  padding-top: clamp(0.375rem, 0.261rem + 0.57vw, 0.625rem);
  padding-bottom: clamp(0.375rem, 0.261rem + 0.57vw, 0.625rem);
  font-size: 20px;
  font-family: var(--font-family);
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .header-menu__link {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 20px;
    color: var(--white);
  }
}
.header-menu__link::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0%;
  height: 2px;
  background: linear-gradient(91deg, var(--color-green) 0%, var(--yellow) 80%);
  transition: 0.2s;
}
.header-menu__link:focus {
  outline: none;
}
.header-menu__link:focus-visible {
  outline: 2px solid var(--color-text-link);
  outline-offset: 3px;
  color: var(--yellow);
}
@media (any-hover: hover) {
  .header-menu__link:hover {
    color: var(--yellow);
  }
  .header-menu__link:hover::after {
    width: 100%;
  }
}
.header-menu__link._active::after {
  width: 100%;
}
.header-menu__link:active {
  color: var(--yellow);
}
.header__actions {
  display: flex;
  align-items: center;
  column-gap: 14px;
}
.header .burger {
  position: relative;
  --burger-width: 30px;
  --burger-height: 23px;
  --burger-line-height: 3px;
  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--text-color);
  background-color: transparent;
  cursor: pointer;
  pointer-events: none;
}
.header .burger::before, .header .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.header .burger::before {
  top: 0;
}
.header .burger::after {
  top: calc(100% - var(--burger-line-height));
}
.header .burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: var(--burger-line-height);
  background-color: currentColor;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.header .burger--active::before {
  top: 50%;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.header .burger--active::after {
  top: 50%;
  transform: rotate(-45deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
.header .burger--active .burger__line {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
.header .burger__wrapper {
  position: relative;
  padding: clamp(6px, -0.902rem + 6.38vw, 12px) clamp(10px, -3.63rem + 21.28vw, 30px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .header .burger__wrapper {
    display: none;
  }
}
.header .burger__wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: clamp(78px, 1.896rem + 14.89vw, 92px);
  height: clamp(36px, -0.729rem + 14.89vw, 50px);
  transform: translate(-50%, -50%);
  background: url(../img/buttons/burger-bg.svg) no-repeat center/contain;
  z-index: -1;
}
@media (min-width: 1024px) {
  .header .burger {
    display: none;
  }
}
.header-btn {
  display: none;
}
@media (min-width: 1024px) {
  .header-btn {
    display: flex;
    width: 182px;
    height: 50px;
  }
}
.header-btn:active {
  color: var(--color-green);
}

.faq--page .header__logo {
  width: 175px;
  left: 48%;
}
@media screen and (max-width: 1024px) {
  .faq--page .header__logo {
    left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .faq--page .header__logo {
    width: clamp(100px, -26.188rem + 50.68vw, 174px);
    left: clamp(417px, -19.192rem + 70.9vw, 898px);
  }
}

.faq--page .header__logo,
.howtobuy--page .header__logo,
.referral--page .header__logo,
.win--page .header__logo {
  width: 110px;
  top: -5px;
  left: -10px;
}
@media screen and (min-width: 414px) {
  .faq--page .header__logo,
  .howtobuy--page .header__logo,
  .referral--page .header__logo,
  .win--page .header__logo {
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .faq--page .header__logo,
  .howtobuy--page .header__logo,
  .referral--page .header__logo,
  .win--page .header__logo {
    width: clamp(110px, 0.983rem + 9.21vw, 175px);
    left: clamp(445px, -7.088rem + 54.53vw, 830px);
  }
}
@media screen and (min-width: 1730px) {
  .faq--page .header__logo,
  .howtobuy--page .header__logo,
  .referral--page .header__logo,
  .win--page .header__logo {
    left: 48vw;
  }
}

.faq--page .header .lian,
.howtobuy--page .header .lian,
.referral--page .header .lian,
.win--page .header .lian,
.faq--page .header .logo-desktop,
.howtobuy--page .header .logo-desktop,
.referral--page .header .logo-desktop,
.win--page .header .logo-desktop {
  display: none;
}

.faq--page .header .logo-mobile,
.howtobuy--page .header .logo-mobile,
.referral--page .header .logo-mobile,
.win--page .header .logo-mobile {
  display: block;
}

.profile--page .header__logo {
  position: relative;
  left: 0 !important;
  width: 175px !important;
  height: 50px !important;
  transform: none !important;
}
.profile--page .header__logo .logo-mobile {
  display: flex;
  position: absolute;
  top: -9px;
  left: 0 !important;
  transform: none !important;
}
.profile--page .header-menu {
  justify-content: flex-end;
  column-gap: clamp(9px, -3.319rem + 6.07vw, 30px);
}
.profile--page .header-menu__item {
  margin: 0;
}
.profile--page .header .user-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile--page .header .user-mobile .user-info {
  display: flex;
  align-items: center;
  gap: 9px;
}
.profile--page .header .user-mobile .user-info__avatar {
  width: 47px;
  height: 47px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}
.profile--page .header .user-mobile .user-info__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile--page .header .user-mobile .exit-btn {
  display: flex;
}
.profile--page .header .user-mobile .exit-btn svg path {
  transition: 0.3s !important;
}
.profile--page .header .user-mobile .exit-btn:hover svg path {
  stroke: var(--color-green);
  transition: 0.2s !important;
}
@media screen and (min-width: 1024px) {
  .profile--page .header .user-mobile {
    display: none;
  }
}
.profile--page .header .user-info {
  display: none;
}
@media screen and (min-width: 1024px) {
  .profile--page .header .user-info {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .profile--page .header .user-info__avatar {
    width: 47px;
    height: 47px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
  }
  .profile--page .header .user-info__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.profile--page .header .exit-btn {
  display: none;
}
@media screen and (min-width: 1024px) {
  .profile--page .header .exit-btn {
    display: flex;
  }
  .profile--page .header .exit-btn svg path {
    transition: 0.3s !important;
  }
  .profile--page .header .exit-btn:hover svg path {
    stroke: var(--color-green);
    transition: 0.2s !important;
  }
}

.main-header {
  position: relative;
}

.main-header::after {
  content: "";
  position: absolute;
  z-index: 100;
  width: 100%;
  height: calc(var(--index) * 10);
  background-image: url(../img/ground.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  bottom: calc(var(--index) * -4.5);
}

.layers {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.layer {
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  will-change: transform;
  z-index: 2;
  transition: var(--transition);
}

.layers__base {
  transform: translate3d(0, calc(var(--scrollTop) / 1.6), 0);
  z-index: 0;
}

.layers__middle {
  transform: translate3d(0, calc(var(--scrollTop) / 2.5), 0);
}

.layers__front {
  transform: translate3d(0, calc(var(--scrollTop) / 5.7), 0);
}

.layer__header {
  z-index: 1;
  transform: translate3d(0, calc(var(--scrollTop) / 2), 0);
  transition: var(--transition);
  will-change: transform;
  text-transform: uppercase;
  font-weight: 900;
  color: var(--text);
  text-shadow: 0 0 15px #9d822b;
}

.layers__title {
  font-size: calc(var(--index) * 2.65);
  letter-spacing: calc(var(--index) / 2.25);
}

.layers__caption {
  font-size: calc(var(--index) / 1.175);
  letter-spacing: calc(var(--index) / 3.5);
  margin-top: calc(var(--index) * -0.75);
}

.main-article {
  --main-article-transform: translate3d(0, calc(var(--scrollTop) / -7.5), 0);
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  /* Update: */
  top: -1px;
  z-index: 10;
}

.main-article__header {
  text-transform: uppercase;
  font-size: calc(var(--index) * 1.8);
  letter-spacing: calc(var(--index) / 7.5);
  transform: var(--main-article-transform);
  transition: var(--transition);
  will-change: transform;
}

.main-article__paragraph {
  max-width: calc(var(--index) * 30);
  font-size: calc(var(--index) * 0.9);
  margin-top: calc(var(--index) / 1.25);
  transform: var(--main-article-transform);
  transition: var(--transition);
  will-change: transform;
}

.content {
  will-change: transform;
}

.copy {
  position: absolute;
  bottom: calc(var(--index) * 2.5);
  opacity: 0.45;
  font-size: calc(var(--index) * 0.75);
  letter-spacing: calc(var(--index) / 37.5);
}

.hero {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 660px;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .hero {
    min-height: 870px;
  }
}
@media screen and (min-width: 1820px) {
  .hero {
    min-height: clamp(870px, 51.902rem + 2.17vw, 900px);
  }
}
.hero::before {
  content: "";
  position: absolute;
  top: -31%;
  left: -21%;
  width: 69%;
  aspect-ratio: 78/47;
  background: url(../img/what/bg.svg) no-repeat center/contain;
  z-index: 3;
}
.hero__bg {
  position: relative;
  flex-shrink: 0;
  height: clamp(500px, 133.33vw, 800px);
  pointer-events: none;
}
@media (min-width: 1024px) {
  .hero__bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
.hero__bg .mobile__actions {
  margin-top: clamp(350px, 11.161rem + 53.57vw, 500px);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  pointer-events: all;
  z-index: 12;
}
.hero__bg .mobile__actions .btn-win {
  display: flex;
  top: 0;
  left: 0;
  width: 154px;
  background: url(../img/buttons/primary.png) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
}
.hero__bg .mobile__actions .primary-btn {
  width: 100%;
  max-width: 330px;
  max-height: 60px;
  margin: 0 auto;
  white-space: nowrap;
}
@media screen and (min-width: 600px) {
  .hero__bg .mobile__actions {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .hero__bg::before, .hero__bg::after {
    content: "";
    position: absolute;
    bottom: -130px;
    width: 260px;
    height: 150px;
    background: var(--bg);
    z-index: 10;
  }
}
@media screen and (min-width: 1200px) {
  .hero__bg::before, .hero__bg::after {
    bottom: -70px;
  }
}
.hero__bg::before {
  left: 0;
}
.hero__bg::after {
  right: 0;
}
.hero__bg .layer {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: var(--transition);
  will-change: transform;
}
.hero__bg .bg {
  top: 0;
  right: 0;
  width: 1130px;
  height: 610px;
  background-position: right top;
  background-size: contain;
  z-index: -1;
}
.hero__bg .icicles {
  display: none;
}
@media screen and (min-width: 600px) {
  .hero__bg .icicles {
    display: block;
    position: absolute;
    width: 697px;
    height: 199px;
    top: 0;
    left: -17vw;
    z-index: 12;
  }
}
@media screen and (min-width: 992px) {
  .hero__bg .icicles {
    left: -3vw;
  }
}
@media screen and (min-width: 1728px) {
  .hero__bg .icicles {
    left: calc((100vw - 1728px) / 2 - 22px);
  }
}
.hero__bg .fon-mobil {
  display: none;
  z-index: 1;
}
@media screen and (min-width: 600px) {
  .hero__bg .fon-mobil {
    display: none;
  }
}
.hero__bg .fon {
  display: none;
}
@media screen and (min-width: 600px) {
  .hero__bg .fon {
    --offset-right: clamp(0px, -37.103rem + 40.38vw, 105px);
    display: block;
    top: 8px;
    right: calc(100vw - clamp(786px, 29.621rem + 52.01vw, 1006px));
    width: 896px;
    height: auto;
    aspect-ratio: 90/69;
    background-position: right top;
    background-size: contain;
    z-index: 0;
  }
}
@media (min-width: 1024px) {
  .hero__bg .fon {
    right: calc(100vw - clamp(1224px, 41.917rem + 54.04vw, 1465px));
  }
}
@media screen and (min-width: 1470px) {
  .hero__bg .fon {
    right: clamp(0px, -37.103rem + 40.38vw, 105px);
  }
}
@media screen and (min-width: 1760px) {
  .hero__bg .fon {
    margin-right: calc((100vw - 1728px) / 2);
  }
}
.hero__bg .fon-mini {
  top: 98px;
  left: 0;
  width: 100%;
  min-width: 480px;
  height: clamp(418px, 14.2rem + 58.57vw, 644px);
  z-index: 1;
  background-position: left;
  background-size: contain;
}
@media screen and (min-width: 600px) {
  .hero__bg .fon-mini {
    display: none;
  }
}
.hero__bg .finger {
  position: absolute;
  width: 172px;
  height: 172px;
  top: 244px;
  right: 233px;
  background-position: center;
  background-size: contain;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-3;
  animation-timing-function: cubic-bezier(0.075, 0.5, 0, 1);
}
.hero__bg .btn-win {
  top: 414px;
  left: 445px;
  width: 203px;
  height: 80px;
  padding-top: 17px;
  background: url(../img/hero/win.png) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--white);
  text-transform: uppercase;
  pointer-events: all;
  z-index: 12;
}
.hero__bg .grass {
  display: none;
}
@media screen and (min-width: 600px) {
  .hero__bg .grass {
    display: block;
    width: clamp(111px, 4.865rem + 8.01vw, 214px);
    height: auto;
    aspect-ratio: 21/22;
    bottom: 152px;
    left: calc(5vw + clamp(0rem, -15.301rem + 40.8vw, 173px));
    z-index: 2;
  }
}
@media (min-width: 1024px) {
  .hero__bg .grass {
    left: calc(40vw + clamp(20px, -4.926rem + 9.65vw, 230px));
    bottom: clamp(187px, 8.737rem + 2.27vw, 179px);
  }
}
.hero__bg .leaf {
  display: none;
}
@media screen and (min-width: 600px) {
  .hero__bg .leaf {
    display: block;
    width: clamp(199px, 8.776rem + 14.15vw, 381px);
    height: auto;
    aspect-ratio: 38/17;
    bottom: clamp(151px, 6.961rem + 6.6vw, 179px);
    left: calc(50% + 20px);
    z-index: 3;
  }
}
@media (min-width: 1024px) {
  .hero__bg .leaf {
    bottom: clamp(179px, 6.746rem + 6.94vw, 228px);
    left: calc(50% + clamp(175px, 6.379rem + 7.12vw, 330px));
  }
}
.hero__bg .black {
  bottom: calc(var(--index-wh) * 2.8);
  left: 50%;
  width: 100%;
  height: 450px;
  transform: translateX(-50%);
  background-position: center bottom;
  background-size: cover;
  min-width: 1600px;
  z-index: 8;
}
.hero__bg .ahead {
  bottom: calc(100% - 594px);
  left: 30%;
  width: 100%;
  aspect-ratio: 179/44;
  transform: translateX(-50%);
  background-position: center bottom;
  background-size: contain;
  min-width: clamp(788px, -48.875rem + 418.67vw, 1730px);
  z-index: 10;
}
@media screen and (min-width: 375px) {
  .hero__bg .ahead {
    bottom: calc(100% - clamp(594px, 2.125rem + 149.33vw, 930px));
  }
}
@media screen and (min-width: 600px) {
  .hero__bg .ahead {
    left: 50%;
    bottom: -92px;
  }
}
@media (min-width: 1024px) {
  .hero__bg .ahead {
    bottom: -39px;
  }
}
@media screen and (min-width: 1730px) {
  .hero__bg .ahead {
    bottom: calc(100% - clamp(910px, 32.949rem + 22.13vw, 1153px));
  }
}
.hero-content {
  padding-top: 45px;
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 11;
}
@media screen and (min-width: 600px) {
  .hero-content {
    padding-top: 0;
  }
}
@media (min-width: 1024px) {
  .hero-content {
    padding-top: 77px;
    padding-left: calc(100% - clamp(1020px, 15.893rem + 76.78vw, 1685px));
    justify-content: flex-start;
  }
}
.hero-tabs {
  width: clamp(320px, 4rem + 80vw, 380px);
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: all;
}

.tabs {
  max-width: 395px;
}
.tabs__nav {
  margin-bottom: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: clamp(50px, 1.525rem + 8vw, 56px);
}
.tabs__nav-item {
  flex: 0 1 180px;
  aspect-ratio: 180/56;
  height: inherit;
}
.tabs__nav-btn {
  position: relative;
  padding: 16px 18px;
  width: 100%;
  height: 100%;
  transition: 0.2s;
}
.tabs__nav-btn:focus {
  outline: none;
}
.tabs__nav-btn:focus-visible {
  outline: 2px solid var(--color-green);
  outline-offset: 3px;
}
@media (any-hover: hover) {
  .tabs__nav-btn:hover {
    color: var(--full-dark);
  }
}
.tabs__nav-btn:active {
  color: var(--full-dark);
}
.tabs__nav-btn svg {
  position: absolute;
  inset: 0;
  height: 100%;
  z-index: -1;
}
.tabs__nav-btn.tabs__nav-btn--active {
  opacity: 1;
}
.tabs__nav-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.tabs__content {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 100%;
  max-width: 380px;
  height: 424px;
  background: url(../img/hero/tabs/bg-tabs.svg) no-repeat center/contain;
}
.tabs__panel {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: top center;
  width: 100%;
  background: url(../img/hero/tabs/bg-panel.svg) no-repeat center/contain;
  opacity: 0;
  animation-name: tabs__up;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}
.tabs__panel.tabs__panel--active {
  animation-name: tabs__down;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}
.tabs__wrapper {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.tabs__header {
  padding-left: 13px;
  padding-right: 13px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-90);
}
.tabs__header span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--color-dark-red);
  text-transform: uppercase;
}
.tabs__title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: clamp(138px, -1.2rem + 45.33vw, 172px);
  height: 48px;
  background: url(../img/hero/tabs/title.svg) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--white);
  text-transform: capitalize;
}
.tabs__bonus {
  padding-left: 16px;
  padding-right: 16px;
}
.tabs .bonus__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 7px;
  column-gap: 14px;
}
.tabs .bonus__item {
  position: relative;
  flex: 0 0 clamp(82px, 0.4rem + 24vw, 105px);
  padding: 5px clamp(7px, -2.708rem + 6.67vw, 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3px;
  border-radius: 5px;
  background: var(--gray-90);
}
.tabs .bonus__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100.35%;
  width: 14px;
  height: 13px;
  background: url(../img/hero/tabs/plus.svg) no-repeat center/contain;
}
.tabs .bonus__item:nth-child(2n+3)::after {
  display: none;
}
.tabs .bonus__item p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  color: var(--white);
}
.tabs .bonus__item span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--yellow);
}
.tabs .token {
  padding-left: clamp(25px, -1.104rem + 13.33vw, 35px);
  padding-right: clamp(30px, -0.792rem + 13.33vw, 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs .token__title {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--color-dark-red);
}
.tabs .token__index {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tabs .token .current, .tabs .token .next {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.tabs .token .current .index, .tabs .token .next .index {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--bg);
}
.tabs .token .current .index span, .tabs .token .next .index span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--color-dark-red);
}
.tabs .token .current .desc, .tabs .token .next .desc {
  color: var(--gray-90);
}
.tabs .clock {
  margin-top: -5px;
  padding-left: clamp(24px, -2.104rem + 13.33vw, 35px);
  padding-right: clamp(28px, -1.792rem + 13.33vw, 40px);
  display: flex;
  flex-direction: column;
}
.tabs .clock__wrapper, .tabs .clock .trend {
  display: flex;
}
.tabs .clock__wrapper {
  padding-top: 5px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: clamp(15px, -2.699rem + 18.18vw, 25px);
  padding-right: clamp(15px, -2.699rem + 18.18vw, 25px);
  background: var(--white);
  border-radius: 4px;
}
.tabs .clock__wrapper .block {
  flex: 0 0 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs .clock__wrapper .block:not(:last-child) span::after {
  content: ":";
  position: absolute;
  top: 0;
  right: -35%;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--color-dark-red);
}
@media screen and (max-width: 375px) {
  .tabs .clock__wrapper .block:not(:last-child) span::after {
    right: -3.2vw;
  }
}
.tabs .clock__wrapper span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
  color: var(--color-dark-red);
}
.tabs .clock__wrapper .smalltext {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-90);
}
.tabs .clock .trend {
  background: var(--gray-60);
  border-radius: 4px;
}
.tabs .clock .trend__desc {
  position: relative;
  padding: 2px 5px 2px 7px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: -0.02em;
  color: var(--white);
  background: var(--color-green);
  border-radius: 4px;
  z-index: 1;
}
@media screen and (max-width: 375px) {
  .tabs .clock .trend__desc {
    line-height: 1.2;
  }
}
.tabs .clock .trend__index {
  padding: 2px 5px 2px 7px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: -0.02em;
  color: var(--white);
}
@media screen and (max-width: 375px) {
  .tabs .clock .trend__index {
    line-height: 1.2;
  }
}
.tabs__footer {
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.tabs__footer .total__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--bg);
}
.tabs__footer .total__info span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--color-dark-red);
}
@media screen and (max-width: 375px) {
  .tabs__footer .btn {
    font-size: 18px;
  }
}

.what {
  padding-top: clamp(30px, 1.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;
  z-index: 10;
}
@media screen and (min-width: 567px) {
  .what {
    overflow: unset;
  }
}
.what__body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: clamp(15px, 0.13rem + 3.12vw, 56px);
}
@media (min-width: 1024px) {
  .what__body {
    grid-template-columns: 423px auto;
    grid-template-rows: repeat(2, auto);
    column-gap: clamp(15px, 0.13rem + 3.12vw, 56px);
  }
}
.what .section__header {
  grid-column: 1;
  grid-row: 1;
}
@media (min-width: 1024px) {
  .what .section__header {
    grid-column: 2;
  }
}
.what .section__subtitle {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(16px, 0.921rem + 0.3vw, 20px);
  line-height: 160%;
  color: var(--white);
}
.what .section__body {
  grid-column: 1;
  grid-row: 3;
  transition: var(--transition);
  will-change: transform;
}
@media (min-width: 1024px) {
  .what .section__body {
    grid-column: 2;
    grid-row: 2;
  }
}
.what__image {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  grid-column: 1;
  grid-row: 2;
  width: 277px;
  aspect-ratio: 1/1;
  transition: var(--transition);
  will-change: transform;
}
@media (min-width: 1024px) {
  .what__image {
    grid-row: 1/3;
    width: 100%;
    align-self: center;
  }
}
.what__image::before {
  content: "";
  position: absolute;
  width: 145%;
  aspect-ratio: 78/47;
  top: 27%;
  left: 7%;
  background-image: url(../img/what/bg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}
.what__image img {
  height: auto;
}
.what__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(15px, 0.682rem + 0.99vw, 28px);
}
.what__item {
  display: flex;
  align-items: flex-start;
  column-gap: 13px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 1.014rem + 0.91vw, 32px);
  color: var(--yellow);
}
.what__item::before {
  content: "";
  margin-top: clamp(0px, -0.227rem + 1.14vw, 4px);
  display: block;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  background: url(../img/star.svg) no-repeat center/contain;
}

.referral--page .what .section__body .btn {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 263px;
  min-height: 60px;
}
.referral--page .what .section__body .btn:active {
  color: var(--full-dark);
}
@media (min-width: 1024px) {
  .referral--page .what .section__body .btn {
    margin-left: 0;
    margin-right: 0;
  }
}

.business-model {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  z-index: 10;
}
.business-model__body {
  width: min(var(--content-width), 100% - 4px);
}
.business-model .section__header {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  transition: var(--transition);
}
@media screen and (min-width: 1555px) {
  .business-model .section__header {
    padding-left: 0;
    padding-right: 0;
  }
}
.business-model__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(293px, 1fr));
  justify-items: center;
  gap: 30px 15px;
  transition: var(--transition);
}
@media (min-width: 1024px) {
  .business-model__list {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
.business-model.notitle .business-model__list {
  grid-template-columns: repeat(auto-fill, minmax(293px, 1fr));
  gap: 12px 6px;
  transition: var(--transition);
}
@media (min-width: 1024px) {
  .business-model.notitle .business-model__list {
    transform: none;
  }
}
.business-model .item {
  position: relative;
  padding-top: 11px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  width: 100%;
  aspect-ratio: 37/26;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/business-model/bg.svg);
  z-index: 0;
}
.business-model .item.element-animation {
  opacity: 0;
  bottom: -130px;
  transition: var(--transition);
}
.business-model .item.element-animation.element-show {
  opacity: 1;
  bottom: 0;
  animation: scroll-up cubic-bezier(0.075, 0.5, 0, 1);
}
.business-model .item.element-animation:nth-child(2).element-show {
  transition: all 0.5s cubic-bezier(0.075, 0.5, 0, 1) 0.25s;
}
.business-model .item.element-animation:nth-child(3).element-show {
  transition: all 0.5s cubic-bezier(0.075, 0.5, 0, 1) 0.5s;
}
.business-model .item.element-animation:nth-child(4).element-show {
  transition: all 0.5s cubic-bezier(0.075, 0.5, 0, 1) 0.75s;
}
.business-model .item.element-animation:nth-child(5).element-show {
  transition: all 0.5s cubic-bezier(0.075, 0.5, 0, 1) 1s;
}
.business-model .item__wrapper {
  position: relative;
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  background: var(--bg);
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
}
.business-model .item__image {
  width: clamp(57px, 2.312rem + 4.83vw, 95px);
  height: clamp(60px, 3.092rem + 2.54vw, 80px);
  flex-shrink: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.business-model .item__title {
  position: relative;
  margin-bottom: 9px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  z-index: 2;
}
.business-model .item__desc {
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--yellow);
  z-index: 2;
}
.business-model .item .btn {
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.business-model.notitle .item {
  padding: 0;
  display: flex;
  aspect-ratio: 30/26;
  background-image: url(../img/business-model/bg.png);
}
.business-model.notitle .item .item__wrapper {
  margin: auto;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
}

.island {
  position: relative;
  min-height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 10;
}
@media (min-width: 1024px) {
  .island {
    width: 100%;
  }
}
.island.lazy-background.visible {
  background-image: url(../img/island/malenkii-mir.png);
}
.island .bg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.island .bg::before, .island .bg::after {
  content: "";
  position: absolute;
  bottom: -70px;
  width: 260px;
  height: 150px;
  background: var(--bg);
  z-index: 3;
}
.island .bg::before {
  left: 0;
}
.island .bg::after {
  right: 0;
}
.island .bg .layer {
  position: absolute;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: var(--transition);
}
.island .bg .top-jungle {
  top: -170px;
  left: -1%;
  width: 101%;
  min-width: 900px;
  height: 422px;
  background-position: center bottom;
  background-size: cover;
  z-index: 1;
}
.island .bg .rays {
  top: calc(var(--index-wh) * -3);
  right: calc(100vw - clamp(514px, 13.635rem + 78.89vw, 1026px));
  width: 472px;
  height: 559px;
  z-index: 0;
}
@media (min-width: 1024px) {
  .island .bg .rays {
    right: clamp(0px, -18.391rem + 28.74vw, 200px);
  }
}
@media screen and (min-width: 1700px) {
  .island .bg .rays {
    right: calc(var(--index-wh) * 6);
  }
}
.island .bg .floating {
  width: clamp(212px, 11.479rem + 6.84vw, 300px);
  height: auto;
  bottom: 385px;
  right: clamp(75px, 0.7rem + 17.5vw, 325px);
  z-index: 2;
}
@media screen and (min-width: 992px) {
  .island .bg .floating {
    bottom: 420px;
  }
}
@media screen and (min-width: 1700px) {
  .island .bg .floating {
    right: calc(var(--index-wh) * 12);
  }
}
.island .bg .floating img {
  width: 100%;
  height: auto;
  aspect-ratio: 30/38;
}
.island .bg .floating::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 589px;
  height: auto;
  aspect-ratio: 60/47;
  transform: translate(-50%, -50%);
  background: url(../img/island/ellipse.svg) no-repeat center/contain;
  z-index: -1;
}
.island .bg .light {
  width: 900px;
  aspect-ratio: 90/47;
  right: calc(100vw - clamp(536px, 10.545rem + 88.71vw, 1322px));
  bottom: 160px;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
@media screen and (min-width: 1300px) {
  .island .bg .light {
    right: clamp(0px, -12.712rem + 16.65vw, 115px);
  }
}
.island .bg .owners {
  width: clamp(274px, 12.095rem + 19.44vw, 524px);
  height: auto;
  aspect-ratio: 52/45;
  bottom: calc(var(--index-wh) * 7);
  left: clamp(0px, -4.97rem + 19.21vw, 247px);
}
@media screen and (min-width: 414px) {
  .island .bg .owners {
    bottom: calc(var(--index-wh) * 5);
  }
}
@media screen and (min-width: 567px) {
  .island .bg .owners {
    bottom: calc(var(--index-wh) * 7);
  }
}
@media screen and (min-width: 992px) {
  .island .bg .owners {
    bottom: calc(var(--index-wh) * 4);
    z-index: 3;
  }
}
@media screen and (min-width: 1750px) {
  .island .bg .owners {
    bottom: calc(var(--index-wh) * 4);
    left: calc((100vw - 1700px) / 2);
  }
}
.island .bg .owners svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.island .bg .owners::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 68%;
  width: clamp(600px, 14.549rem + 35.86vw, 900px);
  aspect-ratio: 40/15;
  transform: translate(-50%, -50%);
  background: url(../img/island/ellipse2.svg) no-repeat center/contain;
  z-index: -1;
}
.island .bg .grass {
  width: clamp(111px, 4.865rem + 8.01vw, 214px);
  height: auto;
  aspect-ratio: 21/22;
  bottom: calc(var(--index-wh) * 6);
  right: clamp(20px, -13.791rem + 58.13vw, 785px);
  z-index: 2;
}
@media (min-width: 1024px) {
  .island .bg .grass {
    bottom: calc(var(--index-wh) * 5);
  }
}
.island .bg .leaf {
  display: none;
}
@media (min-width: 1024px) {
  .island .bg .leaf {
    display: block;
    width: clamp(199px, 8.776rem + 14.15vw, 381px);
    height: auto;
    aspect-ratio: 38/17;
    bottom: calc(var(--index-wh) * 7);
    right: clamp(0px, -14.357rem + 22.5vw, 205px);
    z-index: 3;
  }
}
.island .bg .ahead {
  bottom: -115px;
  left: 0;
  top: unset;
  width: 100%;
  height: 517px;
  background-position: center bottom;
  background-size: cover;
  min-width: clamp(852px, 46.405rem + 17.26vw, 900px);
  z-index: 10;
}
@media screen and (min-width: 1800px) {
  .island .bg .ahead {
    height: auto;
    aspect-ratio: 173/53;
  }
}
.island .section__header {
  position: relative;
  margin-top: calc(100% - clamp(183px, -0.482rem + 73.6vw, 546px));
  max-width: 55vw;
  transition: var(--transition);
  z-index: 4;
}
@media screen and (max-width: 767px) {
  .island .section__header {
    max-width: none !important;
  }
}
@media screen and (min-width: 768px) {
  .island .section__header {
    margin-top: 215px;
  }
}
.island .section__subtitle {
  font-size: clamp(16px, 0.92rem + 0.31vw, 20px);
}
@media screen and (max-width: 767px) {
  .island .section__subtitle {
    text-align: justify;
  }
}

.faq--page .island-lite,
.howtobuy--page .island-lite,
.referral--page .island-lite,
.win--page .island-lite {
  height: auto;
  min-height: clamp(306px, 15.127rem + 12.44vw, 453px);
  overflow: hidden;
}
.faq--page .island-lite .bg,
.howtobuy--page .island-lite .bg,
.referral--page .island-lite .bg,
.win--page .island-lite .bg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.faq--page .island-lite .bg::before, .faq--page .island-lite .bg::after,
.howtobuy--page .island-lite .bg::before,
.howtobuy--page .island-lite .bg::after,
.referral--page .island-lite .bg::before,
.referral--page .island-lite .bg::after,
.win--page .island-lite .bg::before,
.win--page .island-lite .bg::after {
  content: "";
  position: absolute;
  bottom: -134px;
  width: clamp(120px, 3.333rem + 20.83vw, 260px);
  height: 150px;
  background: var(--bg);
  z-index: 9;
}
@media screen and (min-width: 992px) {
  .faq--page .island-lite .bg::before, .faq--page .island-lite .bg::after,
  .howtobuy--page .island-lite .bg::before,
  .howtobuy--page .island-lite .bg::after,
  .referral--page .island-lite .bg::before,
  .referral--page .island-lite .bg::after,
  .win--page .island-lite .bg::before,
  .win--page .island-lite .bg::after {
    bottom: -90px;
    z-index: 10;
  }
}
.faq--page .island-lite .bg::before,
.howtobuy--page .island-lite .bg::before,
.referral--page .island-lite .bg::before,
.win--page .island-lite .bg::before {
  left: 0;
}
.faq--page .island-lite .bg::after,
.howtobuy--page .island-lite .bg::after,
.referral--page .island-lite .bg::after,
.win--page .island-lite .bg::after {
  right: 0;
}
.faq--page .island-lite .bg .bg,
.howtobuy--page .island-lite .bg .bg,
.referral--page .island-lite .bg .bg,
.win--page .island-lite .bg .bg {
  display: block;
  position: absolute;
  width: 100%;
  aspect-ratio: 173/35;
}
.faq--page .island-lite .bg .icicles,
.howtobuy--page .island-lite .bg .icicles,
.referral--page .island-lite .bg .icicles,
.win--page .island-lite .bg .icicles {
  display: none;
}
@media screen and (min-width: 600px) {
  .faq--page .island-lite .bg .icicles,
  .howtobuy--page .island-lite .bg .icicles,
  .referral--page .island-lite .bg .icicles,
  .win--page .island-lite .bg .icicles {
    display: block;
    position: absolute;
    width: 697px;
    height: 199px;
    top: 0;
    left: -17vw;
    z-index: 5;
  }
}
@media screen and (min-width: 992px) {
  .faq--page .island-lite .bg .icicles,
  .howtobuy--page .island-lite .bg .icicles,
  .referral--page .island-lite .bg .icicles,
  .win--page .island-lite .bg .icicles {
    left: -3vw;
  }
}
@media screen and (min-width: 1728px) {
  .faq--page .island-lite .bg .icicles,
  .howtobuy--page .island-lite .bg .icicles,
  .referral--page .island-lite .bg .icicles,
  .win--page .island-lite .bg .icicles {
    left: calc((100vw - 1728px) / 2 - 22px);
  }
}
.faq--page .island-lite .bg .grass,
.howtobuy--page .island-lite .bg .grass,
.referral--page .island-lite .bg .grass,
.win--page .island-lite .bg .grass {
  width: clamp(101px, 4.039rem + 8.79vw, 214px);
  right: clamp(-50px, -16.791rem + 58.13vw, 785px);
  height: auto;
  aspect-ratio: 21/22;
  bottom: calc(var(--index-wh) * 3);
  right: clamp(20px, -13.791rem + 58.13vw, 785px);
  z-index: 2;
}
.faq--page .island-lite .bg .green,
.howtobuy--page .island-lite .bg .green,
.referral--page .island-lite .bg .green,
.win--page .island-lite .bg .green {
  width: 62px;
  height: 62px;
  left: 10px;
  bottom: 84px;
  z-index: 2;
}
.faq--page .island-lite .bg .leaf,
.howtobuy--page .island-lite .bg .leaf,
.referral--page .island-lite .bg .leaf,
.win--page .island-lite .bg .leaf {
  display: none;
}
@media (min-width: 1024px) {
  .faq--page .island-lite .bg .leaf,
  .howtobuy--page .island-lite .bg .leaf,
  .referral--page .island-lite .bg .leaf,
  .win--page .island-lite .bg .leaf {
    display: block;
    width: clamp(199px, 8.776rem + 14.15vw, 381px);
    height: auto;
    aspect-ratio: 38/17;
    bottom: calc(var(--index-wh) * 7);
    right: clamp(0px, -14.357rem + 22.5vw, 205px);
    z-index: 3;
  }
}
.faq--page .island-lite .bg .owners,
.howtobuy--page .island-lite .bg .owners,
.referral--page .island-lite .bg .owners,
.win--page .island-lite .bg .owners {
  width: clamp(198px, 10.688rem + 8.44vw, 333px);
  height: auto;
  aspect-ratio: 52/45;
  bottom: calc(var(--index-wh) * 2);
  left: clamp(0px, -4.97rem + 19.21vw, 247px);
}
.faq--page .island-lite .bg .owners svg,
.howtobuy--page .island-lite .bg .owners svg,
.referral--page .island-lite .bg .owners svg,
.win--page .island-lite .bg .owners svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.faq--page .island-lite .bg .owners::after,
.howtobuy--page .island-lite .bg .owners::after,
.referral--page .island-lite .bg .owners::after,
.win--page .island-lite .bg .owners::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 68%;
  width: clamp(600px, 14.549rem + 35.86vw, 900px);
  aspect-ratio: 40/15;
  transform: translate(-50%, -50%);
  background: url(../img/island/ellipse2.svg) no-repeat center/contain;
  z-index: -1;
}
.faq--page .island-lite .bg .ahead,
.howtobuy--page .island-lite .bg .ahead,
.referral--page .island-lite .bg .ahead,
.win--page .island-lite .bg .ahead {
  bottom: -54px;
  min-width: clamp(802px, 48.153rem + 7.62vw, 900px);
  height: auto;
  aspect-ratio: 173/52;
  left: 0;
  top: unset;
  width: 100%;
  background-position: center bottom;
  background-size: cover;
  z-index: 10;
}
@media screen and (min-width: 992px) {
  .faq--page .island-lite .bg .ahead,
  .howtobuy--page .island-lite .bg .ahead,
  .referral--page .island-lite .bg .ahead,
  .win--page .island-lite .bg .ahead {
    bottom: -60px;
  }
}

.ecosystem {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 9.28vw, 80px);
  background: var(--bg);
  overflow: hidden;
}
.ecosystem .section__header {
  margin-bottom: clamp(25px, 0.969rem + 2.97vw, 60px);
}
@media screen and (max-width: 1200px) {
  .ecosystem .section__title {
    text-align: center;
  }
}
.ecosystem__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 1024px) {
  .ecosystem__wrapper {
    flex-direction: row;
  }
}
.ecosystem .what__list {
  position: relative;
  z-index: 1;
}
.ecosystem__image {
  position: relative;
  width: 100%;
  min-height: 820px;
  z-index: 0;
}
.ecosystem__image img {
  position: absolute;
  will-change: transform;
}
.ecosystem__image::after {
  content: "";
  position: absolute;
  width: 750px;
  height: 474px;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  background: url(../img/ellipse-2.svg) no-repeat center/contain;
  z-index: -1;
}
@media (min-width: 1024px) {
  .ecosystem__image {
    position: absolute;
    width: 900px;
    top: -45px;
    right: -140px;
    z-index: 0;
  }
  .ecosystem__image::after {
    left: 50%;
  }
}
.ecosystem__image .confident {
  top: 0;
  left: 50%;
  min-width: 410px;
  transform: translateX(-50%);
  width: 410px;
  height: 580;
  object-fit: contain;
  z-index: 3;
}
@media screen and (min-width: 1024px) {
  .ecosystem__image .confident {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
  }
}
.ecosystem__image .group {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 525px;
  height: 525px;
}
@media screen and (min-width: 1024px) {
  .ecosystem__image .group {
    left: 50%;
  }
}
.ecosystem__image .light {
  top: 28px;
  right: 84px;
}
.ecosystem__image .stone {
  left: 52%;
  transform: translateX(-50%);
  top: 473px;
}
.ecosystem__image .rings {
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 52%;
  width: 347px;
  height: 287px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  transition: var(--transition);
  z-index: -1;
}
.ecosystem__image .rings img {
  position: absolute;
}
.ecosystem__image .rings .rings-1 {
  bottom: 157px;
  opacity: 0;
  width: 314px;
  height: 73px;
}
.ecosystem__image .rings .rings-1.element-show {
  animation-delay: 1s;
  animation: rings-1-up 0.7s ease-in-out;
  bottom: 215px;
  opacity: 1;
}
.ecosystem__image .rings .rings-2 {
  bottom: 10px;
  opacity: 0;
  width: 347px;
  height: 107px;
}
.ecosystem__image .rings .rings-2.element-show {
  animation-delay: 2s;
  animation: rings-2-up 0.7s ease-in-out;
  bottom: 157px;
  opacity: 1;
}
.ecosystem__image .rings .rings-3 {
  bottom: -160px;
  opacity: 0;
  width: 258px;
  height: 152px;
}
.ecosystem__image .rings .rings-3.element-show {
  animation-delay: 3s;
  animation: rings-3-up 0.7s ease-in-out;
  bottom: 10px;
  opacity: 1;
}
.ecosystem__image .stone-1 {
  bottom: 146px;
  left: 10vw;
  transition: var(--transition);
}
@media screen and (min-width: 567px) {
  .ecosystem__image .stone-1 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__image .stone-1 {
    opacity: 0;
    left: -120px;
    bottom: -30px;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__image .stone-1.element-show {
    animation: stone-left 1.2s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 146px;
    left: 222px;
    opacity: 1;
  }
}
.ecosystem__image .stone-2 {
  bottom: 156px;
  right: 10vw;
}
@media screen and (min-width: 567px) {
  .ecosystem__image .stone-2 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__image .stone-2 {
    opacity: 0;
    bottom: -20px;
    right: -10px;
    transition: var(--transition);
  }
}
@media screen and (min-width: 768px) {
  .ecosystem__image .stone-2.element-show {
    animation: stone-right 1.45s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 156px;
    right: 203px;
    opacity: 1;
  }
}

.presale-stage {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
}
.presale-stage .section__header {
  margin-bottom: clamp(12px, -0.336rem + 4.2vw, 45px);
}
.presale-stage .presale-slider {
  width: 100%;
  user-select: none;
}
.presale-stage .presale-slider .swiper-wrapper {
  padding-top: 18px;
  padding-bottom: 18px;
}
.presale-stage .presale-slider .swiper-slide {
  display: flex;
  justify-content: center;
}
.presale-stage .presale-slider__item {
  height: 100%;
  padding-top: 80px;
  padding-left: 34px;
  padding-right: 32px;
  padding-bottom: 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  aspect-ratio: 37/39;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.presale-stage .presale-slider__item.sold {
  opacity: 0.7;
}
.presale-stage .presale-slider__item.sold .sold-out {
  display: flex;
  width: 157px;
  height: 80px;
  background: url(../img/presale/sold.svg) no-repeat center/contain;
}
.presale-stage .presale-slider__item.sold .primary-btn {
  display: none;
}
.presale-stage .presale-slider__item.lazy-background.visible {
  background-image: url(../img/presale/bg.svg);
}
.presale-stage .presale-slider__item-header {
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.presale-stage .presale-slider__item-title {
  width: 213px;
}
.presale-stage .presale-slider__item-discont {
  padding-bottom: 8px;
  position: absolute;
  top: -10px;
  right: 0;
  width: 85px;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../img/presale/discont.svg) no-repeat center/contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
}
.presale-stage .presale-slider__item .feature__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.presale-stage .presale-slider__item .feature__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.presale-stage .presale-slider__item .feature__item span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--bg);
}
.presale-stage .presale-slider__item .feature__item span:last-child {
  color: var(--color-dark-red);
}
.presale-stage .presale-slider__item-footer {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.presale-stage .presale-slider__item-footer .sold-out {
  display: none;
}
.presale-stage .presale-slider__item-footer .btn {
  margin-top: 15px;
  margin-bottom: 15px;
}
.presale-stage .presale-slider .buttons-arrow {
  margin-top: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow {
  transition: 0.3s !important;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow svg {
  transition: 0.3s !important;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow svg .fon, .presale-stage .presale-slider .buttons-arrow .btn-arrow svg .arrow {
  transition: 0.3s !important;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow:hover .fon {
  fill: var(--yellow);
  transition: 0.2s !important;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow:hover .arrow {
  stroke: black;
  transition: 0.2s !important;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow.swiper-button-disabled {
  opacity: 0.5;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow.swiper-button-disabled:hover .arrow {
  stroke: #FFB800;
}
.presale-stage .presale-slider .buttons-arrow .btn-arrow.swiper-button-disabled:hover .fon {
  fill: black !important;
}

.partners {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;
}
.partners .section__header {
  margin-bottom: clamp(11px, 0.062rem + 2.42vw, 30px);
}
.partners__body {
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 30px;
}
.partners__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.partners__item {
  flex: 0 0 clamp(165px, 8.3rem + 6.91vw, 260px);
  width: clamp(165px, 8.3rem + 6.91vw, 260px);
  height: 81px;
}
.partners__item a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.partners__item a .bg {
  position: absolute;
  inset: 0;
  z-index: 0;
}
.partners__item a .bg svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.partners__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(145px, 8.115rem + 3.66vw, 180px);
  height: 34px;
  z-index: 1;
}
.partners__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.partners__statistic {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
}
.partners__statistic span {
  display: flex;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: -0.02em;
  color: var(--yellow);
}

.tokenomics {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media screen and (min-width: 1800px) {
  .tokenomics {
    aspect-ratio: 184/122;
  }
}
.tokenomics .bg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.tokenomics .bg .layer {
  position: absolute;
  z-index: 1;
}
.tokenomics .bg .fon {
  inset: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
}
.tokenomics .bg .top-jungle {
  top: -185px;
  left: 0;
  width: 100%;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}
@media screen and (min-width: 1800px) {
  .tokenomics .bg .top-jungle {
    height: auto;
    aspect-ratio: 178/45;
  }
}
.tokenomics .bg .ahead {
  bottom: -92px;
  left: 0;
  height: 323px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
}
@media screen and (min-width: 1800px) {
  .tokenomics .bg .ahead {
    height: auto;
    aspect-ratio: 172/30;
  }
}
.tokenomics__body {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  z-index: 5;
}
.tokenomics__body .section__header {
  text-align: center;
}
.tokenomics__body .section__subtitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 88ch;
}
.tokenomics__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1024px) {
  .tokenomics__wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: row;
    column-gap: clamp(20px, -3.25rem + 6vw, 50px);
  }
}
.tokenomics__chart {
  position: relative;
  width: 100%;
  flex: 0.5;
  min-height: 270px;
  text-align: center;
}
.tokenomics__chart.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.tokenomics__chart.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-left cubic-bezier(0.075, 0.5, 0, 1) 0.7s;
}
@media (min-width: 1024px) {
  .tokenomics__chart {
    width: auto;
    height: auto;
    flex: 0 1 50%;
  }
}
.tokenomics__chart img {
  position: absolute;
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(300px, 16.629rem + 8.2vw, 350px);
  height: auto;
}
@media (min-width: 1024px) {
  .tokenomics__chart img {
    width: 130%;
  }
}
.tokenomics__legend {
  flex: 1;
}
.tokenomics__legend.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.tokenomics__legend.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-right cubic-bezier(0.075, 0.5, 0, 1) 1s;
}
.tokenomics__legend .mobil-thead {
  padding-right: 15vw;
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: var(--white);
}
@media screen and (min-width: 680px) {
  .tokenomics__legend .mobil-thead {
    display: none;
  }
}
@media screen and (max-width: 680px) {
  .tokenomics__legend {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .tokenomics__legend {
    flex: 0 1 50%;
  }
}
.tokenomics__legend .table__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 680px) {
  .tokenomics__legend .table__wrapper {
    height: 315px;
    max-height: 315px;
  }
}
.tokenomics__legend table {
  width: 100%;
  max-width: 760px;
  margin: 0;
  padding: 0;
}
.tokenomics__legend table tr {
  margin-bottom: 2px;
}
.tokenomics__legend table th, .tokenomics__legend table td {
  padding: 5px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: var(--white);
  text-align: center;
}
.tokenomics__legend table tr th {
  padding-bottom: 25px;
}
.tokenomics__legend table tr th:first-child, .tokenomics__legend table tr td:nth-child(2) {
  text-align: left;
}
.tokenomics__legend table tbody tr td:first-child span {
  display: block;
  border-radius: 20px;
  width: 19px;
  height: 36px;
}
.tokenomics__legend table tbody tr td:nth-child(2) span {
  display: block;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--yellow);
}
.tokenomics__legend table tbody tr td:last-child {
  border-radius: 20px 4px;
  width: 250px;
  height: 56px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  background: var(--bg);
}
.tokenomics__legend table tbody tr td:last-child span {
  color: var(--yellow);
}
@media screen and (max-width: 680px) {
  .tokenomics__legend table {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    overflow-y: scroll;
  }
  .tokenomics__legend table td {
    display: flex;
  }
  .tokenomics__legend table thead {
    display: none;
  }
  .tokenomics__legend table thead tr {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.6fr;
  }
  .tokenomics__legend table thead tr th:last-child {
    display: none;
  }
  .tokenomics__legend table tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .tokenomics__legend table tbody tr {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr 0.45fr;
  }
  .tokenomics__legend table tbody tr td:first-child {
    grid-column: 1;
  }
  .tokenomics__legend table tbody tr td:nth-child(2) {
    grid-column: 2;
    flex-direction: column;
  }
  .tokenomics__legend table tbody tr td:nth-child(3) {
    grid-column: 3;
  }
  .tokenomics__legend table tbody tr td:last-child {
    width: 100%;
    padding: 12px clamp(20px, -3.361rem + 19.6vw, 80px);
    grid-column: 1/4;
    grid-row: 2;
    justify-content: space-between;
  }
  .tokenomics__legend table tbody tr td:last-child:before {
    content: attr(data-label);
    color: white;
  }
}

.bonuses {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;
}
.bonuses .section__header {
  margin-bottom: clamp(32px, 1.737rem + 1.02vw, 40px);
  text-align: center;
}
.bonuses .section__subtitle {
  margin-left: auto;
  margin-right: auto;
  max-width: 120ch;
}
.bonuses__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
@media screen and (max-width: 992px) {
  .bonuses__body {
    padding-bottom: 20px;
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .bonuses__body {
    display: flex;
    flex-direction: column;
  }
}
.bonuses__item {
  min-width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bonuses .section__body {
  flex: auto;
}
.bonuses__wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;
}
@media screen and (min-width: 1200px) {
  .bonuses__wrapper {
    flex-direction: column;
  }
}
.bonuses__position, .bonuses__index {
  flex: 1;
  padding: 0 20px 20px;
  border: 1px solid var(--gray-90);
  border-radius: 22px 5px;
}
@media screen and (min-width: 1200px) {
  .bonuses__position, .bonuses__index {
    padding: 2px;
  }
}
.bonuses .position {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bonuses .position.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.bonuses .position.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-left cubic-bezier(0.075, 0.5, 0, 1) 2s;
}
@media screen and (min-width: 1200px) {
  .bonuses .position {
    flex-direction: row;
  }
}
.bonuses .position__name {
  padding: clamp(12px, -1.517rem + 2.7vw, 22px) clamp(12px, -1.706rem + 3.02vw, 25px);
  flex: 0 0 87px;
  text-align: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: var(--white);
}
@media screen and (min-width: 1200px) {
  .bonuses .position__name {
    text-align: left;
    flex: 0 0 clamp(255px, -1.61rem + 23.4vw, 379px);
  }
}
.bonuses .position__list {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2px;
}
@media screen and (min-width: 1200px) {
  .bonuses .position__list {
    width: auto;
    flex-direction: row;
  }
}
.bonuses .position__item {
  border-radius: 20px 4px;
  flex: 1;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-light);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--yellow);
}
.bonuses .index {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bonuses .index.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.bonuses .index.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-left cubic-bezier(0.075, 0.5, 0, 1) 2.5s;
}
@media screen and (min-width: 1200px) {
  .bonuses .index {
    flex-direction: row;
  }
}
.bonuses .index__name {
  padding: clamp(12px, -1.517rem + 2.7vw, 22px) clamp(12px, -1.706rem + 3.02vw, 25px);
  flex: 0 0 87px;
  text-align: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: var(--white);
}
@media screen and (min-width: 1200px) {
  .bonuses .index__name {
    text-align: left;
    flex: 0 0 clamp(255px, -1.61rem + 23.4vw, 379px);
  }
}
.bonuses .index__list {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2px;
}
@media screen and (min-width: 1200px) {
  .bonuses .index__list {
    width: auto;
    flex-direction: row;
  }
}
.bonuses .index__item {
  border-radius: 20px 4px;
  flex: 1;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-light);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--gray-30);
}

.referral {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media screen and (min-width: 1800px) {
  .referral {
    aspect-ratio: 184/110;
  }
}
.referral .section__header {
  position: relative;
  margin-bottom: 24px;
  z-index: 5;
}
.referral .section__subtitle {
  margin-left: auto;
  margin-right: auto;
  max-width: 105ch;
  text-align: center;
}
.referral .bg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.referral .bg .layer {
  position: absolute;
  z-index: 1;
}
.referral .bg .fon {
  inset: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
}
.referral .bg .top-jungle {
  top: -185px;
  left: 0;
  width: 100%;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}
@media screen and (min-width: 1800px) {
  .referral .bg .top-jungle {
    height: auto;
    aspect-ratio: 178/45;
  }
}
.referral .bg .ahead {
  bottom: -92px;
  left: 0;
  height: 323px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 5;
}
@media screen and (min-width: 1800px) {
  .referral .bg .ahead {
    height: auto;
    aspect-ratio: 172/30;
  }
}
.referral__wrapper {
  position: relative;
  display: grid;
  grid-template: 1fr;
}
@media (min-width: 1024px) {
  .referral__wrapper {
    grid-template-columns: 0.7fr 1fr;
  }
}
.referral .card__wrapper {
  grid-column: 1;
  grid-row: 1;
}
.referral .card__wrapper.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.referral .card__wrapper.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-left cubic-bezier(0.075, 0.5, 0, 1) 0.7s;
}
.referral__image {
  position: relative;
  grid-column: 1;
  grid-row: 3;
  min-height: 350px;
}
.referral__image.element-animation {
  opacity: 0;
  transform: translateX(-100%);
  transition: var(--transition);
}
.referral__image.element-animation.element-show {
  opacity: 1;
  transform: translateX(0);
  animation: fade-right cubic-bezier(0.075, 0.5, 0, 1) 1s;
}
@media (min-width: 1024px) {
  .referral__image {
    grid-column: 2;
    grid-row: 1;
  }
}
.referral__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 110%;
  aspect-ratio: 77/63;
  transform: translate(-50%, -50%);
  object-fit: contain;
}
.referral__actions {
  width: 100%;
  margin-top: 16px;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}
@media (min-width: 1024px) {
  .referral__actions {
    grid-column: 1/3;
    grid-row: 2;
  }
}
.referral__actions .btn {
  width: 100%;
  text-align: center;
  height: 70px;
  max-height: none;
}
.referral__actions .btn:active {
  color: var(--full-dark);
}
@media screen and (min-width: 510px) {
  .referral__actions .btn {
    width: 411px;
    height: 60px;
  }
}
.referral__actions p {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--yellow);
}
.referral .card__wrapper {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}
.referral .card__wrapper::after {
  content: "";
  position: absolute;
  right: 398px;
  top: 0;
  width: 130px;
  height: 255px;
  rotate: -3deg;
  background: url(../img/hero/lian.png) no-repeat center/contain;
  z-index: 0;
}
.referral .bonus-card {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 471px;
  position: relative;
  padding: clamp(25px, 0.753rem + 4.05vw, 35px) clamp(18px, 0.2rem + 4.45vw, 46px);
  display: flex;
  flex-direction: column;
  aspect-ratio: 47/51;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
@media screen and (max-width: 510px) {
  .referral .bonus-card {
    padding-bottom: 40px;
    aspect-ratio: unset;
  }
}
@media (min-width: 1024px) {
  .referral .bonus-card {
    margin-left: auto;
    margin-right: 0;
  }
}
.referral .bonus-card.lazy-background.visible {
  background-image: url(../img/referral/bonus-card.svg);
}
@media screen and (max-width: 1180px) {
  .referral .bonus-card.lazy-background.visible {
    background-image: url(../img/referral/card-mobil.svg);
  }
}
.referral .bonus-card__wrapper {
  position: relative;
}
.referral .bonus-card__header {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: var(--gray-90);
}
.referral .bonus-card__list {
  display: flex;
  flex-direction: column;
}
.referral .bonus-card__item {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.referral .bonus-card__item span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.926rem + 1.62vw, 24px);
  color: var(--full-dark);
}
.referral .bonus-card__item span:last-child {
  border-radius: 20px 4px;
  width: 90px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.926rem + 1.62vw, 24px);
  color: var(--yellow);
  background: var(--bg-light);
}
.referral .bonus-card__item:not(:first-of-type) {
  background-image: linear-gradient(to right, var(--gray-30) 50%, transparent 50%);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 8px 1px;
}

.roadmap {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
}
.roadmap__body {
  width: min(var(--content-width), 100%);
}
.roadmap .section__header {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: clamp(24px, 0.315rem + 4.58vw, 60px);
}
@media screen and (min-width: 1555px) {
  .roadmap .section__header {
    padding-left: 0;
    padding-right: 0;
  }
}
.roadmap .section__subtitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 126ch;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: var(--white);
}
.roadmap-slider {
  position: relative;
  width: 100%;
  user-select: none;
}
.roadmap-slider .swiper-wrapper {
  padding-top: 18px;
  padding-bottom: 18px;
}
@media screen and (max-width: 1555px) {
  .roadmap-slider {
    padding-left: 15px;
  }
}
.roadmap-item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 135px;
  z-index: 4;
  opacity: 0.5;
}
.roadmap-item::after {
  content: "";
  position: absolute;
  top: 117px;
  right: calc(100% - clamp(290px, -2.993rem + 90.1vw, 463px));
  width: clamp(99px, -7.484rem + 58.3vw, 211px);
  height: 12px;
  background-image: url(../img/roadmap/chain.svg);
  background-repeat: space no-repeat;
  background-size: 12px 12px;
  z-index: -1;
}
@media screen and (min-width: 567px) {
  .roadmap-item::after {
    right: calc(100% - clamp(250px, -5.054rem + 58.35vw, 498px));
    width: clamp(80px, -8.841rem + 39.06vw, 246px);
  }
}
@media screen and (min-width: 992px) {
  .roadmap-item::after {
    right: calc(100% - clamp(274px, -5.231rem + 36.06vw, 349px));
    width: clamp(100px, -8.654rem + 24.04vw, 150px);
  }
}
@media screen and (min-width: 1200px) {
  .roadmap-item::after {
    right: calc(100% - clamp(255px, 5rem + 14.58vw, 360px));
    width: clamp(96px, -1.292rem + 9.72vw, 166px);
  }
}
.roadmap-item__quarter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.roadmap-item__quarter .count {
  position: relative;
  padding: 5px 17px 4px;
  border: 0.5px solid var(--white);
  border-radius: 22px 6px;
  width: 115px;
  height: 48px;
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--white);
}
.roadmap-item__quarter .count::after {
  content: "";
  position: absolute;
  top: 3px;
  right: 3px;
  width: 51px;
  height: 41px;
  background: url(../img/roadmap/no.svg) no-repeat center/contain;
}
.roadmap-item__quarter span {
  margin-top: 4px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--gray-60);
}
.roadmap-item.active-item {
  opacity: 1;
}
.roadmap-item.active-item .count::after {
  background: url(../img/roadmap/yes.svg) no-repeat center/contain;
}
.roadmap-item__image {
  position: absolute;
  top: 52px;
  left: 50%;
  width: 203px;
  height: 136px;
  transform: translateX(-50%);
}
.roadmap-item__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.roadmap-item__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}
.roadmap-item__item {
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--yellow);
}
.roadmap-item__item::before {
  content: "";
  width: 8px;
  height: 7px;
  margin-right: 10px;
  background: url(../img/roadmap/marker.svg) no-repeat center/contain;
}
.roadmap .buttons-arrow {
  margin-top: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
.roadmap .buttons-arrow .btn-arrow {
  transition: 0.3s !important;
}
.roadmap .buttons-arrow .btn-arrow svg .fon, .roadmap .buttons-arrow .btn-arrow svg .arrow {
  transition: 0.3s !important;
}
.roadmap .buttons-arrow .btn-arrow:hover .fon {
  fill: var(--yellow);
  transition: 0.2s !important;
}
.roadmap .buttons-arrow .btn-arrow:hover .arrow {
  stroke: black;
  transition: 0.2s !important;
}
.roadmap .buttons-arrow .btn-arrow.swiper-button-disabled {
  opacity: 0.5;
}
.roadmap .buttons-arrow .btn-arrow.swiper-button-disabled:hover .fon {
  fill: black;
}
.roadmap .buttons-arrow .btn-arrow.swiper-button-disabled:hover .arrow {
  stroke: #FFB800;
}

.games {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
}
.games__body {
  width: min(var(--content-width), 100% - 4px);
}
.games .section__header {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: clamp(30px, 1.546rem + 1.27vw, 40px);
}
@media screen and (min-width: 1555px) {
  .games .section__header {
    padding-left: 0;
    padding-right: 0;
  }
}
.games .section__subtitle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 145ch;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  text-align: center;
  color: var(--white);
}
.games-slider {
  position: relative;
  width: 100%;
  user-select: none;
}
.games-slider .swiper-slide {
  display: flex;
  justify-content: center;
  height: 387px;
}
.games__item {
  position: relative;
  padding: 8px 17px 7px;
  width: 100%;
  max-width: 371px;
  aspect-ratio: 37/39;
  display: flex;
  align-items: center;
  background: url(../img/games/bg.svg) no-repeat center/contain;
}
.games__image {
  width: 100%;
  aspect-ratio: 37/39;
  background: #d9d9d9;
  border-radius: 20px;
  overflow: hidden;
}
.games__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.games .buttons-arrow {
  margin-top: clamp(25px, 0.668rem + 4.2vw, 40px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
.games .buttons-arrow .btn-arrow {
  transition: 0.3s !important;
}
.games .buttons-arrow .btn-arrow svg .fon, .games .buttons-arrow .btn-arrow svg .arrow {
  transition: 0.3s !important;
}
.games .buttons-arrow .btn-arrow:hover .fon {
  fill: var(--yellow);
  transition: 0.2s !important;
}
.games .buttons-arrow .btn-arrow:hover .arrow {
  stroke: black;
  transition: 0.2s !important;
}
.games .buttons-arrow .btn-arrow.swiper-button-disabled {
  opacity: 0.5;
}
.games .buttons-arrow .btn-arrow.swiper-button-disabled:hover .fon {
  fill: black;
}
.games .buttons-arrow .btn-arrow.swiper-button-disabled:hover .arrow {
  stroke: #FFB800;
}

.qestions {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;
}
.qestions .section__header {
  margin-bottom: clamp(25px, 0.969rem + 2.97vw, 60px);
}
@media screen and (max-width: 1200px) {
  .qestions .section__title {
    text-align: center;
  }
}
.qestions__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1024px) {
  .qestions__wrapper {
    flex-direction: row;
  }
}
.qestions__accordion {
  position: relative;
  max-width: clamp(640px, 26.506rem + 28.11vw, 902px);
  z-index: 2;
}
.qestions .accordion__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.qestions .accordion__control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.qestions .accordion__title {
  text-align: left;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 1.009rem + 0.93vw, 32px);
  color: var(--yellow);
}
.qestions .accordion__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--white);
  will-change: max-height;
  transition: all 0.2s ease-out;
}
.qestions .accordion__content p {
  margin-bottom: 6px;
}
.qestions .accordion__content a {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (any-hover: hover) {
  .qestions .accordion__content a:hover {
    color: var(--color-yellow-light);
    text-decoration: underline;
    transition: 0.2s;
  }
}
.qestions .accordion__icon {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.qestions .accordion__icon svg path {
  transform-origin: center;
}
.qestions .accordion__icon svg path .fon {
  transition: 0.2s;
}
.qestions .accordion__icon svg path .arrow {
  transition: 0.2s;
  transform-origin: center;
}
.qestions .accordion__item.open .accordion__control {
  border-color: var(--accent-color);
}
.qestions .accordion__item.open .accordion__icon svg .arrow {
  transform-origin: center;
  transform: rotate(180deg);
}
.qestions .accordion__item.open .accordion__content {
  opacity: 1;
  overflow: visible;
  padding-top: 12px;
  padding-bottom: 12px;
}
.qestions .accordion__item:hover .accordion__icon svg .fon {
  fill: var(--yellow);
  transition: 0.2s;
}
.qestions .accordion__item:hover .accordion__icon svg .arrow {
  stroke: black;
  transition: 0.2s;
}
.qestions__image {
  position: relative;
  width: 100%;
  min-height: 820px;
  z-index: 0;
}
.qestions__image img {
  position: absolute;
  will-change: transform;
}
.qestions__image::after {
  content: "";
  position: absolute;
  width: 750px;
  height: 474px;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  background: url(../img/ellipse-2.svg) no-repeat center/contain;
  z-index: -1;
}
@media (min-width: 1024px) {
  .qestions__image {
    position: absolute;
    width: 900px;
    top: 30px;
    right: -140px;
    z-index: 0;
  }
  .qestions__image::after {
    left: 50%;
  }
}
.qestions__image .confident {
  top: 0;
  left: 50%;
  min-width: 410px;
  transform: translateX(-50%);
  width: 410px;
  height: 580;
  object-fit: contain;
  z-index: 3;
}
@media screen and (min-width: 1024px) {
  .qestions__image .confident {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
  }
}
.qestions__image .group {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 525px;
  height: 525px;
}
@media screen and (min-width: 1024px) {
  .qestions__image .group {
    left: 50%;
  }
}
.qestions__image .light {
  top: 28px;
  right: 84px;
}
.qestions__image .stone {
  left: 52%;
  transform: translateX(-50%);
  top: 473px;
}
.qestions__image .rings {
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 52%;
  width: 347px;
  height: 287px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  transition: var(--transition);
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  .qestions__image .rings {
    background: url(../img/ecosystem/rings.png) no-repeat center/contain;
  }
}
.qestions__image .rings img {
  position: absolute;
}
.qestions__image .rings .rings-1 {
  bottom: 157px;
  opacity: 0;
  width: 314px;
  height: 73px;
}
.qestions__image .rings .rings-1.element-show {
  animation-delay: 1s;
  animation: rings-1-up 0.7s ease-in-out;
  bottom: 215px;
  opacity: 1;
}
.qestions__image .rings .rings-2 {
  bottom: 10px;
  opacity: 0;
  width: 347px;
  height: 107px;
}
.qestions__image .rings .rings-2.element-show {
  animation-delay: 2s;
  animation: rings-2-up 0.7s ease-in-out;
  bottom: 157px;
  opacity: 1;
}
.qestions__image .rings .rings-3 {
  bottom: -160px;
  opacity: 0;
  width: 258px;
  height: 152px;
}
.qestions__image .rings .rings-3.element-show {
  animation-delay: 3s;
  animation: rings-3-up 0.7s ease-in-out;
  bottom: 10px;
  opacity: 1;
}
.qestions__image .stone-1 {
  bottom: 146px;
  left: 10vw;
  transition: var(--transition);
}
@media screen and (min-width: 567px) {
  .qestions__image .stone-1 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .qestions__image .stone-1 {
    opacity: 0;
    left: -120px;
    bottom: -30px;
  }
}
@media screen and (min-width: 768px) {
  .qestions__image .stone-1.element-show {
    animation: stone-left 1.2s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 146px;
    left: 222px;
    opacity: 1;
  }
}
.qestions__image .stone-2 {
  bottom: 156px;
  right: 10vw;
}
@media screen and (min-width: 567px) {
  .qestions__image .stone-2 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .qestions__image .stone-2 {
    opacity: 0;
    bottom: -20px;
    right: -10px;
    transition: var(--transition);
  }
}
@media screen and (min-width: 768px) {
  .qestions__image .stone-2.element-show {
    animation: stone-right 1.45s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 156px;
    right: 203px;
    opacity: 1;
  }
}

.giveaway {
  position: relative;
  overflow: hidden;
}
.giveaway__grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
}
@media screen and (min-width: 1300px) {
  .giveaway__grid {
    grid-template-columns: 1fr 540px;
    column-gap: 20px;
  }
}
.giveaway__body {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .giveaway__body {
    max-width: 941px;
  }
}
@media screen and (min-width: 1300px) {
  .giveaway__body {
    grid-column: 1/3;
  }
}
@media screen and (min-width: 1530px) {
  .giveaway__body {
    margin: 0;
    max-width: 941px;
  }
}
.giveaway__body .section__header {
  margin-bottom: 25px;
}
.giveaway__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 17px;
}
@media screen and (min-width: 992px) {
  .giveaway__wrapper {
    flex-direction: row;
    align-items: flex-start;
    column-gap: 7px;
  }
}
.giveaway__entries {
  width: 100%;
  max-width: 386px;
  aspect-ratio: 39/43;
  display: flex;
  justify-content: center;
  background: url(../img/hero/tabs/bg-tabs.svg) no-repeat center/contain;
}
.giveaway__entries .entries {
  padding: 10px 24px 23px 22px;
  width: 100%;
  max-width: 375px;
  aspect-ratio: 375/420;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../img/giveaway/bg.svg) no-repeat center/contain;
}
.giveaway__entries .entries__counter {
  display: flex;
  flex-direction: column;
}
.giveaway__entries .entries__counter span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 80px;
  line-height: 1;
  color: var(--color-dark-red);
}
.giveaway__entries .entries__counter .desc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-90);
}
.giveaway__entries .entries__description {
  flex: auto;
  margin-top: 25px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: var(--bg);
}
.giveaway__entries .entries__description p {
  margin-bottom: 15px;
}
.giveaway__entries .entries__description a {
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (any-hover: hover) {
  .giveaway__entries .entries__description a:hover {
    color: var(--color-yellow-light);
    text-decoration: underline;
    transition: 0.2s;
  }
}
.giveaway__entries .entries__description span {
  color: var(--color-dark-red);
}
.giveaway__entries .entries__footer {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: var(--gray-90);
}
.giveaway__entries .entries__footer .btn {
  width: 263px;
  min-height: 60px;
  margin-bottom: 9px;
}
.giveaway__winners .desc {
  margin-top: 16px;
  margin-bottom: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
}
.giveaway__winners .what__list {
  row-gap: clamp(15px, 0.682rem + 0.99vw, 20px);
}
.giveaway__winners .what__item {
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--yellow);
}
.giveaway__winners .what__item::before {
  margin: 0;
}
.giveaway__winners .what__item span {
  color: var(--white);
}
.giveaway .winners__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.giveaway .winners__wrapper .referral__wrapper {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.giveaway .winners__wrapper .referral__link {
  display: inline-block;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
  text-align: center;
}
.giveaway .winners__wrapper .referral__link a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (any-hover: hover) {
  .giveaway .winners__wrapper .referral__link a:hover {
    color: var(--color-yellow-light);
    text-decoration: underline;
    transition: 0.2s;
  }
}
.giveaway .winners__wrapper .referral__link span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--yellow);
}
.giveaway .winners__wrapper .referral__rules a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (any-hover: hover) {
  .giveaway .winners__wrapper .referral__rules a:hover {
    color: var(--color-yellow-light);
    text-decoration: underline;
    transition: 0.2s;
  }
}
.giveaway .winners__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  color: var(--gray-30);
}
.giveaway .winners__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: clamp(5px, -0.126rem + 1.69vw, 20px);
}
.giveaway .winners__item {
  position: relative;
  border: 1px solid var(--gray-90);
  border-radius: 22px 5px;
  width: clamp(110px, -2.088rem + 34.64vw, 163px);
  height: clamp(156px, 9.546rem + 0.79vw, 163px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
}
.giveaway .winners__item::after {
  content: "";
  position: absolute;
  inset: 2px;
  border-radius: 20px 4px;
  background: var(--bg-light);
  z-index: -1;
}
.giveaway .winners__item span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(16px, 0.766rem + 0.9vw, 24px);
  text-align: center;
  color: var(--yellow);
}
.giveaway .winners__image {
  width: 80px;
  height: 98px;
  flex-shrink: 0;
}
.giveaway .winners__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.giveaway__mask {
  margin-left: auto;
  margin-right: auto;
  grid-column: 1;
  grid-row: 2;
  position: relative;
  border-radius: 20px;
  width: clamp(290px, -1.875rem + 100vw, 540px);
  height: auto !important;
  flex-shrink: 0;
  overflow: hidden;
}
.giveaway__mask iframe {
  width: 100%;
  height: calc(3000px - clamp(1296px, 75.88rem + 25.6vw, 1360px)) !important;
  object-fit: cover;
}
@media screen and (min-width: 570px) {
  .giveaway__mask {
    height: auto;
    aspect-ratio: 54/164;
  }
}
@media screen and (min-width: 1300px) {
  .giveaway__mask {
    grid-column: 1/3;
  }
}
@media screen and (min-width: 1530px) {
  .giveaway__mask {
    grid-column: 2;
    grid-row: 1/3;
  }
}
.giveaway__image {
  position: relative;
  width: 100%;
  min-height: 820px;
  z-index: 0;
  grid-column: 1/3;
  grid-row: 3;
}
@media screen and (min-width: 1530px) {
  .giveaway__image {
    bottom: unset;
    position: relative;
    grid-column: 1;
    grid-row: 2;
  }
}
.giveaway__image img {
  position: absolute;
  will-change: transform;
}
.giveaway__image::after {
  content: "";
  position: absolute;
  width: 750px;
  height: 474px;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  background: url(../img/ellipse-2.svg) no-repeat center/contain;
  z-index: -1;
}
.giveaway__image .confident {
  top: 0;
  left: 50%;
  min-width: 410px;
  transform: translateX(-50%);
  width: 410px;
  height: 580;
  object-fit: contain;
  z-index: 3;
}
@media screen and (min-width: 1024px) {
  .giveaway__image .confident {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
  }
}
.giveaway__image .group {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 525px;
  height: 525px;
}
@media screen and (min-width: 1024px) {
  .giveaway__image .group {
    left: 50%;
  }
}
.giveaway__image .light {
  top: 28px;
  right: 84px;
}
.giveaway__image .stone {
  left: 52%;
  transform: translateX(-50%);
  top: 473px;
}
.giveaway__image .rings {
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 52%;
  width: 347px;
  height: 287px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  transition: var(--transition);
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  .giveaway__image .rings {
    background: url(../img/ecosystem/rings.png) no-repeat center/contain;
  }
}
.giveaway__image .rings img {
  position: absolute;
}
.giveaway__image .rings .rings-1 {
  bottom: 157px;
  opacity: 0;
  width: 314px;
  height: 73px;
}
.giveaway__image .rings .rings-1.element-show {
  animation-delay: 1s;
  animation: rings-1-up 0.7s ease-in-out;
  bottom: 215px;
  opacity: 1;
}
.giveaway__image .rings .rings-2 {
  bottom: 10px;
  opacity: 0;
  width: 347px;
  height: 107px;
}
.giveaway__image .rings .rings-2.element-show {
  animation-delay: 2s;
  animation: rings-2-up 0.7s ease-in-out;
  bottom: 157px;
  opacity: 1;
}
.giveaway__image .rings .rings-3 {
  bottom: -160px;
  opacity: 0;
  width: 258px;
  height: 152px;
}
.giveaway__image .rings .rings-3.element-show {
  animation-delay: 3s;
  animation: rings-3-up 0.7s ease-in-out;
  bottom: 10px;
  opacity: 1;
}
.giveaway__image .stone-1 {
  bottom: 146px;
  left: 10vw;
  transition: var(--transition);
}
@media screen and (min-width: 567px) {
  .giveaway__image .stone-1 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .giveaway__image .stone-1 {
    opacity: 0;
    left: -120px;
    bottom: -30px;
  }
}
@media screen and (min-width: 768px) {
  .giveaway__image .stone-1.element-show {
    animation: stone-left 1.2s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 146px;
    left: 222px;
    opacity: 1;
  }
}
.giveaway__image .stone-2 {
  bottom: 156px;
  right: 10vw;
}
@media screen and (min-width: 567px) {
  .giveaway__image .stone-2 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .giveaway__image .stone-2 {
    opacity: 0;
    bottom: -20px;
    right: -10px;
    transition: var(--transition);
  }
}
@media screen and (min-width: 768px) {
  .giveaway__image .stone-2.element-show {
    animation: stone-right 1.45s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 156px;
    right: 203px;
    opacity: 1;
  }
}

.howtobuy {
  overflow: hidden;
}
.howtobuy .section__header {
  margin-bottom: 35px;
}
.howtobuy__wrapper {
  transition: all 0.3s ease-out;
}
.howtobuy-link {
  position: relative;
  max-width: 890px;
  z-index: 5;
}
.howtobuy-link__list {
  margin-bottom: clamp(25px, 0.74rem + 3.18vw, 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: clamp(25px, -0.413rem + 7.63vw, 85px);
  row-gap: 35px;
}
@media screen and (min-width: 992px) {
  .howtobuy-link__list {
    justify-content: flex-start;
  }
}
.howtobuy-link__item a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  transition: 0.2s;
  border-bottom: 1px dashed var(--white);
}
@media (any-hover: hover) {
  .howtobuy-link__item a:hover {
    color: var(--color-yellow-light);
    border-bottom: 1px solid var(--color-yellow-light);
    transition: 0.2s;
  }
}
.howtobuy-link .install {
  margin-bottom: clamp(45px, 2.319rem + 1.91vw, 60px);
  display: grid;
  grid-template-columns: clamp(45px, 1.66rem + 4.45vw, 80px) 1fr;
  column-gap: 24px;
  row-gap: 20px;
}
.howtobuy-link .install__img {
  grid-column: 1;
  grid-row: 1/3;
}
.howtobuy-link .install__img img {
  width: 100%;
  height: auto;
  aspect-ratio: 8/7;
  object-fit: contain;
}
.howtobuy-link .install__title {
  margin-bottom: 3px;
  grid-column: 2;
  grid-row: 1;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--yellow);
}
.howtobuy-link .install__description {
  grid-column: 1/3;
  grid-row: 2;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--white);
}
@media (min-width: 768px) {
  .howtobuy-link .install__description {
    grid-column: 2;
  }
}
.howtobuy-link .install__description a {
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (any-hover: hover) {
  .howtobuy-link .install__description a:hover {
    color: var(--color-yellow-light);
    text-decoration: underline;
    transition: 0.2s;
  }
}
.howtobuy-buywith {
  position: relative;
  padding-bottom: clamp(25px, 0.74rem + 3.18vw, 50px);
  max-width: 890px;
  transition: all 0.3s ease-out;
  z-index: 5;
}
@media screen and (max-width: 992px) {
  .howtobuy-buywith {
    background: var(--bg);
  }
}
.howtobuy-buywith__title {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--yellow);
}
@media screen and (max-width: 992px) {
  .howtobuy-buywith__title {
    background: var(--bg);
  }
}
.howtobuy-buywith__title .icon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__title .accordion__icon {
  margin-left: auto;
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__title .accordion__icon svg path {
  transform-origin: center;
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__title .accordion__icon svg path .fon {
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__title .accordion__icon svg path .arrow {
  transition: all 0.3s ease-out;
  transform-origin: center;
}
@media screen and (min-width: 992px) {
  .howtobuy-buywith__title .accordion__icon {
    display: none;
  }
}
.howtobuy-buywith__list {
  transition: all 1s ease-out;
}
.howtobuy-buywith__list.accordion__content {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
@media screen and (min-width: 992px) {
  .howtobuy-buywith__list.accordion__content {
    height: auto;
    overflow: visible;
    max-height: none;
  }
}
.howtobuy-buywith.accordion__item {
  transition: all 0.6s ease-out;
}
@media screen and (max-width: 992px) {
  .howtobuy-buywith.accordion__item .accordion__control {
    position: relative;
    background: var(--bg);
    z-index: 1;
  }
}
.howtobuy-buywith.accordion__item.open .accordion__icon svg .fon {
  fill: var(--yellow);
  transition: all 0.3s ease-out;
}
.howtobuy-buywith.accordion__item.open .accordion__icon svg .arrow {
  stroke: black;
  transition: all 0.3s ease-out;
  transform-origin: center;
  transform: rotate(180deg);
}
@media screen and (max-width: 992px) {
  .howtobuy-buywith.accordion__item.open .accordion__content {
    position: relative;
    height: 100%;
    overflow: visible;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
    z-index: 0;
  }
}
.howtobuy-buywith__item {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  column-gap: 13px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--white);
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__item:not(:has(img))::before, .howtobuy-buywith__item:not(:has(.no-star))::before {
  content: "";
  padding-top: clamp(0px, -0.227rem + 1.14vw, 8px);
  display: block;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  background: url(../img/star.svg) no-repeat center/contain;
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__item.image-item, .howtobuy-buywith__item:has(img) {
  justify-content: center;
}
.howtobuy-buywith__item.image-item::before, .howtobuy-buywith__item:has(img)::before {
  display: none;
}
.howtobuy-buywith__item.image-item img, .howtobuy-buywith__item:has(img) img {
  flex-shrink: 0;
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__item.no-star {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--gray-30);
  transition: all 0.3s ease-out;
}
.howtobuy-buywith__item.no-star::before {
  display: none;
}
.howtobuy__image {
  position: relative;
  width: 100%;
  min-height: 820px;
  z-index: 0;
}
.howtobuy__image img {
  position: absolute;
  will-change: transform;
}
.howtobuy__image::after {
  content: "";
  position: absolute;
  width: 750px;
  height: 474px;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  background: url(../img/ellipse-2.svg) no-repeat center/contain;
  z-index: -1;
}
@media (min-width: 1024px) {
  .howtobuy__image {
    position: absolute;
    width: 900px;
    top: 30px;
    right: -140px;
    z-index: 0;
  }
  .howtobuy__image::after {
    left: 50%;
  }
}
.howtobuy__image .confident {
  top: 0;
  left: 50%;
  min-width: 410px;
  transform: translateX(-50%);
  width: 410px;
  height: 580;
  object-fit: contain;
  z-index: 3;
}
@media screen and (min-width: 1024px) {
  .howtobuy__image .confident {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
  }
}
.howtobuy__image .group {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 525px;
  height: 525px;
}
@media screen and (min-width: 1024px) {
  .howtobuy__image .group {
    left: 50%;
  }
}
.howtobuy__image .light {
  top: 28px;
  right: 84px;
}
.howtobuy__image .stone {
  left: 52%;
  transform: translateX(-50%);
  top: 473px;
}
.howtobuy__image .rings {
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 52%;
  width: 347px;
  height: 287px;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  transition: var(--transition);
  z-index: -1;
}
@media screen and (max-width: 1024px) {
  .howtobuy__image .rings {
    background: url(../img/ecosystem/rings.png) no-repeat center/contain;
  }
}
.howtobuy__image .rings img {
  position: absolute;
}
.howtobuy__image .rings .rings-1 {
  bottom: 157px;
  opacity: 0;
  width: 314px;
  height: 73px;
}
.howtobuy__image .rings .rings-1.element-show {
  animation-delay: 1s;
  animation: rings-1-up 0.7s ease-in-out;
  bottom: 215px;
  opacity: 1;
}
.howtobuy__image .rings .rings-2 {
  bottom: 10px;
  opacity: 0;
  width: 347px;
  height: 107px;
}
.howtobuy__image .rings .rings-2.element-show {
  animation-delay: 2s;
  animation: rings-2-up 0.7s ease-in-out;
  bottom: 157px;
  opacity: 1;
}
.howtobuy__image .rings .rings-3 {
  bottom: -160px;
  opacity: 0;
  width: 258px;
  height: 152px;
}
.howtobuy__image .rings .rings-3.element-show {
  animation-delay: 3s;
  animation: rings-3-up 0.7s ease-in-out;
  bottom: 10px;
  opacity: 1;
}
.howtobuy__image .stone-1 {
  bottom: 146px;
  left: 10vw;
  transition: var(--transition);
}
@media screen and (min-width: 567px) {
  .howtobuy__image .stone-1 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .howtobuy__image .stone-1 {
    opacity: 0;
    left: -120px;
    bottom: -30px;
  }
}
@media screen and (min-width: 768px) {
  .howtobuy__image .stone-1.element-show {
    animation: stone-left 1.2s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 146px;
    left: 222px;
    opacity: 1;
  }
}
.howtobuy__image .stone-2 {
  bottom: 156px;
  right: 10vw;
}
@media screen and (min-width: 567px) {
  .howtobuy__image .stone-2 {
    right: 15vw;
  }
}
@media screen and (min-width: 768px) {
  .howtobuy__image .stone-2 {
    opacity: 0;
    bottom: -20px;
    right: -10px;
    transition: var(--transition);
  }
}
@media screen and (min-width: 768px) {
  .howtobuy__image .stone-2.element-show {
    animation: stone-right 1.45s cubic-bezier(0.075, 0.5, 0, 1);
    bottom: 156px;
    right: 203px;
    opacity: 1;
  }
}

.rewarded {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
}
.rewarded__body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 45px;
  column-gap: 25px;
}
@media screen and (min-width: 1535px) {
  .rewarded__body {
    grid-template-columns: 1fr 567px;
  }
}
.rewarded .section__header {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1535px) {
  .rewarded .section__header {
    align-items: flex-start;
  }
}
.rewarded .section__header .section__title {
  margin-bottom: 20px;
  text-align: center;
  text-wrap: pretty;
}
@media screen and (min-width: 1535px) {
  .rewarded .section__header .section__title {
    text-align: left;
    max-width: 30ch;
  }
}
.rewarded .section__header .btn {
  width: 263px;
  min-height: 60px;
}
.rewarded .section__header .btn:active {
  color: var(--full-dark);
}
.rewarded .business-model {
  grid-column: 1;
  grid-row: 2;
}
.rewarded .business-model__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.rewarded .business-model__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
  transition: var(--transition);
  will-change: transform;
}
@media screen and (min-width: 1535px) {
  .rewarded .business-model__list {
    width: 100%;
    flex-wrap: nowrap;
    gap: 6px;
  }
}
.rewarded .business-model__item {
  flex: 0 0 300px;
}
@media screen and (max-width: 374px) {
  .rewarded .business-model__item {
    flex: 0 0 290px;
  }
}
.rewarded .business-model__item.lazy-background.visible {
  background-image: url(../img/business-model/bg.svg);
}
.rewarded .business-model__item.item {
  position: relative;
  padding: 11px 16px 16px;
  aspect-ratio: 300/258;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.rewarded .business-model__item.item .item__wrapper {
  padding: 15px 20px 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  background: var(--bg);
  border-radius: 20px;
  overflow: hidden;
}
.rewarded .business-model__item.item .item__image {
  width: 70px;
  height: 60px;
  flex-shrink: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.rewarded .business-model__item.item .item__desc {
  margin-top: 25px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: var(--white);
}
.rewarded .business-model__item.item .btn {
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.rewarded__image {
  display: none;
}
@media (min-width: 1024px) {
  .rewarded__image {
    grid-column: 1;
    grid-row: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1535px) {
  .rewarded__image {
    grid-column: 2;
    grid-row: 1/3;
  }
}

.profile {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
}
.profile .section__header {
  margin-bottom: 45px;
}
.profile__wrapper img {
  width: 100%;
  height: auto;
  aspect-ratio: 355/157;
  object-fit: contain;
}

.notifications {
  padding-top: 125px;
  padding-bottom: 20px;
  background: var(--bg);
}
.notifications__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0;
  border-radius: 20px;
  background: var(--full-dark);
}
.notification.error {
  border: 1px solid var(--color-dark-red);
}
.notification.info {
  border: 1px solid var(--color-green);
}
@media screen and (min-width: 992px) {
  .notification {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
  }
}
.notification__info {
  padding: 9px;
  display: flex;
  align-items: center;
  column-gap: clamp(15px, -0.046rem + 2.05vw, 30px);
}
@media screen and (min-width: 992px) {
  .notification__info {
    padding: 19px;
  }
}
.notification__icon {
  width: 42px;
  height: 44px;
  flex-shrink: 0;
}
.notification__desc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(16px, 0.642rem + 1.38vw, 24px);
  color: var(--white);
}
.notification.error .notification__desc {
  color: var(--color-dark-red);
}
.notification.info .notification__desc {
  color: var(--color-green);
}
.notification__form {
  padding: 0 0 9px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (min-width: 992px) {
  .notification__form {
    padding: 15px 15px 15px 0;
  }
}
.notification__form input {
  width: clamp(177px, -17.813rem + 140vw, 240px);
  height: 51px;
  padding: 15px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  border-radius: 6px;
  color: var(--full-dark);
  background: var(--white);
}
.notification__form input::placeholder {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--full-dark);
}
@media screen and (min-width: 375px) {
  .notification__form input {
    width: clamp(240px, -11.362rem + 42.52vw, 450px);
  }
}
.notification__form button[type=submit] {
  width: clamp(94px, 0.139rem + 9.25vw, 141px);
  min-height: 50px;
}

.profile-info {
  background: var(--bg);
}
.profile-info .section__header {
  margin-bottom: 15px;
}
.profile-info__wrapper {
  padding: clamp(0px, -1.456rem + 4.11vw, 26px) clamp(15px, -0.294rem + 3.48vw, 37px) clamp(15px, -0.462rem + 3.95vw, 40px) clamp(5px, -0.079rem + 1.11vw, 12px);
  border: 1px solid var(--gray-90);
  border-radius: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  background: var(--full-dark);
  column-gap: clamp(25px, -1.489rem + 4.92vw, 50px);
  row-gap: 20px;
}
@media screen and (min-width: 768px) {
  .profile-info__wrapper {
    grid-template-columns: 384px 1fr 194px;
    gap: 50px;
  }
}
.profile-info__card {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .profile-info__card {
    grid-column: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .profile-info__card {
    grid-column: 1;
    grid-row: 1/3;
  }
}
.profile-info__balance:has(.balance__title) {
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .profile-info__balance:has(.balance__title) {
    grid-column: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .profile-info__balance:has(.balance__title) {
    grid-column: 2/4;
    grid-row: 1;
  }
}
@media screen and (min-width: 1300px) {
  .profile-info__balance:has(.balance__title) {
    grid-column: 2;
    grid-row: 1;
    justify-self: auto;
  }
}
.profile-info__balance:not(:has(.balance__title)) {
  grid-column: 1;
  grid-row: 4;
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .profile-info__balance:not(:has(.balance__title)) {
    grid-column: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .profile-info__balance:not(:has(.balance__title)) {
    grid-column: 2/4;
    grid-row: 2;
  }
}
@media screen and (min-width: 1300px) {
  .profile-info__balance:not(:has(.balance__title)) {
    grid-column: 2;
    grid-row: 2;
    justify-self: auto;
  }
}
.profile-info__spent {
  grid-column: 1;
  grid-row: 3;
  justify-self: center;
}
@media screen and (min-width: 768px) {
  .profile-info__spent {
    grid-column: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .profile-info__spent {
    grid-column: 3;
    grid-row: 3;
  }
}
@media screen and (min-width: 1300px) {
  .profile-info__spent {
    grid-column: 3;
    grid-row: 1/3;
  }
}
.profile-info__entries {
  grid-column: 1;
  grid-row: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(16px, 0.552rem + 1.26vw, 24px);
  color: var(--white);
}
@media screen and (min-width: 768px) {
  .profile-info__entries {
    grid-column: 1/4;
    flex-direction: row;
    column-gap: 20px;
  }
}
@media screen and (min-width: 992px) {
  .profile-info__entries {
    grid-column: 1/3;
    grid-row: 3;
  }
}
@media screen and (min-width: 1300px) {
  .profile-info__entries {
    grid-column: 2/4;
    grid-row: 3;
  }
}
.profile-info__entries .entries__counter {
  width: 125px;
  height: 75px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-30);
  border-radius: 20px 4px;
  background: var(--bg-light);
}
.profile-info__entries .entries__counter span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--yellow);
}

.profile-card {
  padding: clamp(12px, -6.708rem + 35.56vw, 26px) clamp(18px, -0.79rem + 11.11vw, 30px);
  width: 100%;
  max-width: 375px;
  aspect-ratio: 375/352;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../img/profile/info.svg) no-repeat center/contain;
}
.profile-card__image {
  --size-image-card: clamp(80px, -8.75rem + 66.67vw, 110px);
  width: var(--size-image-card);
  height: var(--size-image-card);
  border-radius: var(--size-image-card);
  flex-shrink: 0;
  overflow: hidden;
}
.profile-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-card__token {
  position: relative;
  margin-top: clamp(17px, 0.174rem + 4.44vw, 21px);
  margin-bottom: clamp(20px, -0.972rem + 11.11vw, 30px);
  padding: 7px 11px 7px 7px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 14px;
  border-radius: 10px;
  height: 42px;
  background: var(--gray-90);
}
.profile-card__token input {
  min-width: 163px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
  background-color: transparent;
}
.profile-card__token .btn {
  margin-left: auto;
  padding: 0;
}
.profile-card__token .btn svg path {
  transition: 0.2s !important;
}
@media (any-hover: hover) {
  .profile-card__token .btn:hover svg path {
    stroke: var(--color-green);
    transition: 0.2s !important;
  }
}
.profile-card__token .btn:active {
  color: var(--full-dark);
}
.profile-card .primary-btn {
  margin-bottom: clamp(17px, -0.715rem + 8.89vw, 25px);
  width: clamp(220px, -5.958rem + 95.56vw, 263px);
  min-height: 60px;
}
.profile-card .primary-btn:active {
  color: var(--full-dark);
}
.profile-card p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(14px, -0.042rem + 4.44vw, 16px);
  color: var(--gray-90);
}

.balance__title {
  margin-bottom: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  color: var(--white);
}
.balance .forms__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.balance__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 19px;
}
.balance__form .field-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
@media screen and (min-width: 768px) {
  .balance__form .field-group {
    flex-direction: row;
    column-gap: 20px;
  }
}
.balance__form .input-box {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.balance__form input {
  width: 100%;
  padding: 11px 15px 11px 55px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--color-dark-red);
  border-radius: 6px;
  height: 51px;
  background: var(--white);
}
.balance__form input::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--color-dark-red);
}
.balance__form .icon {
  position: absolute;
  bottom: 5px;
  left: 7px;
  width: 40px;
  height: 41px;
  z-index: 1;
}
.balance__form .actions {
  display: flex;
  align-items: center;
  column-gap: clamp(9px, -2.708rem + 16.67vw, 25px);
}
.balance__form .actions .btn:active {
  color: var(--full-dark);
}
.balance__form .actions .btn:first-of-type {
  width: clamp(165px, 2.979rem + 35.56vw, 180px);
}
.balance__form .actions .btn:last-of-type {
  width: clamp(113px, 4.2rem + 10.91vw, 140px);
}

.spent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
}
@media screen and (min-width: 1300px) {
  .spent {
    padding-top: 50px;
  }
}
.spent-item {
  min-width: 194px;
  padding: 8px 7px;
  width: 100%;
  aspect-ratio: 194/145;
  background: url(../img/profile/spent-bg.svg) no-repeat center/contain;
}
.spent-item__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  background: var(--bg);
}
.spent-item__icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}
.spent-item__count {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
  color: var(--color-dark-red);
}
.spent-item__desc {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-30);
}

.profile-transaction {
  padding-top: 40px;
}
.profile-transaction .section__header {
  margin-bottom: 15px;
}
.profile-transaction__wrapper {
  width: 100%;
  padding: 17px 8px 19px clamp(14px, -1.458rem + 4.86vw, 35px);
  border: 1px solid var(--gray-90);
  border-radius: 20px;
  background: var(--full-dark);
}
.profile-transaction__desc {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(16px, 0.652rem + 1.69vw, 20px);
  line-height: 160%;
  color: var(--gray-60);
  text-align: center;
}
.profile-transaction table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
}
.profile-transaction table tr {
  display: flex;
  column-gap: 3px;
  width: 100%;
  padding: 5px;
}
.profile-transaction table th, .profile-transaction table td {
  text-align: left;
}
.profile-transaction table th,
.profile-transaction tbody tr td:first-of-type {
  display: flex;
  align-items: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(14px, 0.208rem + 1.39vw, 20px);
  line-height: 160%;
  color: var(--white);
}
.profile-transaction thead tr th:first-of-type,
.profile-transaction tbody tr td:first-of-type {
  width: clamp(27px, -1.979rem + 7.64vw, 60px);
}
.profile-transaction thead tr th:not(:first-of-type),
.profile-transaction tbody tr td:not(:first-of-type) {
  width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4);
}
.profile-transaction thead tr th:not(:first-of-type) {
  display: flex;
  align-items: center;
  padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
}
.profile-transaction tbody tr td:not(:first-of-type) {
  height: 75px;
  display: flex;
  align-items: center;
  padding: clamp(11px, -0.09rem + 1.62vw, 18px) clamp(11px, 0.062rem + 2.42vw, 30px);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, -0.083rem + 2.78vw, 32px);
  color: var(--yellow);
  border-radius: 20px 4px;
  background: var(--bg-light);
}
@media screen and (max-width: 600px) {
  .profile-transaction thead {
    display: none;
  }
  .profile-transaction tbody tr {
    display: grid;
    grid-template-columns: 27px 1fr;
    align-items: center;
  }
  .profile-transaction tbody tr td {
    height: 45px !important;
  }
  .profile-transaction tbody tr td:first-of-type {
    grid-column: 1;
    grid-row: 1/5;
  }
  .profile-transaction tbody tr td:not(:first-of-type) {
    width: 100%;
  }
  .profile-transaction tbody tr td:not(:first-of-type):not(:last-child) {
    margin-bottom: 3px;
  }
  .profile-transaction tbody tr td:not(:first-of-type)::before {
    grid-column: 2;
    content: attr(data-label);
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    color: var(--white);
    margin-right: 15px;
  }
}

.pagination {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination__list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination__item {
  position: relative;
  width: 52px;
  height: 64px;
}
.pagination__item a {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__item a svg {
  position: absolute;
  inset: 0;
  z-index: 0;
}
.pagination__item a svg path {
  fill: var(--full-dark);
  stroke: var(--full-dark);
  transition: 0.3s !important;
}
.pagination__item a span {
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  transition: 0.3s !important;
  z-index: 1;
}
@media (any-hover: hover) {
  .pagination__item a:hover span {
    color: var(--full-dark);
    transition: 0.2s !important;
  }
  .pagination__item a:hover svg path {
    fill: #ffb800;
    stroke: #ffb800;
    transition: 0.2s !important;
  }
}
.pagination__arrow svg path {
  transition: 0.3s !important;
}
.pagination__arrow:hover .fon {
  fill: var(--yellow);
  transition: 0.2s !important;
}
.pagination__arrow:hover .arrow {
  stroke: black;
  transition: 0.2s !important;
}
.pagination__arrow.disabled {
  opacity: 0.5;
}

.profile-referral-bonus {
  padding-top: 50px;
}
.profile-referral-bonus .section__header {
  margin-bottom: 15px;
}
.profile-referral-bonus__wrapper {
  width: 100%;
  padding: clamp(15px, 0.653rem + 1.21vw, 25px) clamp(10px, 0.341rem + 1.21vw, 20px);
  border: 1px solid var(--gray-90);
  border-radius: 20px;
  background: var(--full-dark);
}

.referral-bonus__link {
  margin-bottom: clamp(10px, 0.296rem + 1.27vw, 20px);
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.referral-bonus__link input {
  flex: 1 1 auto;
  padding: 14px;
  height: 51px;
  border-radius: 6px;
  background: var(--white);
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--full-dark);
}
.referral-bonus__link input::placeholder {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--full-dark);
}
.referral-bonus__link .btn {
  width: clamp(83px, 3.54rem + 7.03vw, 141px);
}
.referral-bonus__link .btn:active {
  color: var(--full-dark);
}
.referral-bonus__desc {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(14px, 0.677rem + 0.76vw, 20px);
  line-height: 160%;
  color: var(--gray-60);
}

.profile-referral-statistics {
  padding-top: 50px;
}
.profile-referral-statistics .section__header {
  margin-bottom: 15px;
}
.profile-referral-statistics__wrapper {
  width: 100%;
  padding: clamp(10px, 0.483rem + 0.61vw, 15px) clamp(10px, 0.341rem + 1.21vw, 20px);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-90);
  border-radius: 20px;
  background: var(--full-dark);
}

.statistics {
  --count-index-bonus: 7;
}
.statistics__form {
  margin-bottom: 35px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media screen and (min-width: 520px) {
  .statistics__form {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 768px) {
  .statistics__form {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 992px) {
  .statistics__form {
    grid-template-columns: 1fr 1fr 1fr auto;
  }
}
.statistics__form .input-box {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.statistics__form input {
  width: 100%;
  padding: 11px 15px 11px 55px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--color-dark-red);
  border-radius: 6px;
  height: 51px;
  background: var(--white);
}
.statistics__form input::placeholder {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--color-dark-red);
}
.statistics__form .icon {
  position: absolute;
  bottom: 5px;
  left: 7px;
  width: 40px;
  height: 41px;
  z-index: 1;
}
.statistics__form button[type=submit] {
  margin-left: auto;
  grid-column: 1;
  grid-row: 4;
}
.statistics__form button[type=submit]:active {
  color: var(--full-dark);
}
@media screen and (min-width: 520px) {
  .statistics__form button[type=submit] {
    margin-top: auto;
    grid-column: 2;
    grid-row: 2;
  }
}
@media screen and (min-width: 768px) {
  .statistics__form button[type=submit] {
    grid-column: 3;
    grid-row: 2;
  }
}
@media screen and (min-width: 992px) {
  .statistics__form button[type=submit] {
    margin-top: auto;
    grid-column: 4;
    grid-row: 1;
  }
}
.statistics__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(24px, 1.358rem + 0.6vw, 32px);
  color: var(--white);
}
.statistics__link {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(16px, 0.929rem + 0.3vw, 20px);
  line-height: 160%;
  color: var(--color-text-link);
  transition: 0.2s;
}
@media (min-width: 768px) {
  .statistics__link {
    width: max-content;
  }
}
@media (any-hover: hover) {
  .statistics__link:hover {
    color: var(--yellow);
  }
}
.statistics-bonus {
  --width-active: 0;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 31px;
}
@media screen and (min-width: 375px) {
  .statistics-bonus {
    row-gap: 29px;
  }
}
@media screen and (min-width: 567px) {
  .statistics-bonus {
    row-gap: 27px;
  }
}
@media screen and (min-width: 768px) {
  .statistics-bonus {
    row-gap: 25px;
  }
}
@media screen and (min-width: 1200px) {
  .statistics-bonus {
    row-gap: 23px;
  }
}
@media screen and (min-width: 1500px) {
  .statistics-bonus {
    row-gap: 21px;
  }
}
.statistics-bonus__info {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.statistics-bonus__title {
  width: clamp(64px, -0.688rem + 20vw, 229px);
}
.statistics-bonus__title span {
  width: max-content;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.909rem + 1.45vw, 32px);
  background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.statistics-bonus__list {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics-bonus__item {
  position: relative;
  --size-point: clamp(24px, 1.252rem + 1.06vw, 38px);
}
.statistics-bonus__item span {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.909rem + 1.45vw, 32px);
  line-height: 1;
  color: var(--gray-30);
}
.statistics-bonus__item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -46px;
  transform: translateX(-50%);
  width: var(--size-point);
  height: var(--size-point);
  background: url(../img/profile/point.svg) no-repeat center/contain;
  z-index: 2;
}
.statistics-bonus__item.bonus-active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -46px;
  transform: translateX(-50%);
  width: var(--size-point);
  height: var(--size-point);
  background: url(../img/profile/point-ok.svg) no-repeat center/contain;
  z-index: 3;
}
.statistics-bonus__item.no-active::after {
  display: none;
}
.statistics-bonus__progress {
  width: 100%;
  position: relative;
  height: 8px;
}
.statistics-bonus__progress::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  height: 8px;
  width: var(--width-active);
  background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
  z-index: 1;
}
.statistics-bonus__progress::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 98%;
  height: 8px;
  background: var(--gray-90);
}

.profile-referral-transactions {
  padding-top: 50px;
}
.profile-referral-transactions .section__header {
  margin-bottom: 15px;
}
.profile-referral-transactions__wrapper {
  width: 100%;
  padding: clamp(10px, 0.484rem + 0.54vw, 17px) 8px clamp(10px, 0.444rem + 0.7vw, 19px) clamp(14px, -1.458rem + 4.86vw, 35px);
  border: 1px solid var(--gray-90);
  border-radius: 20px;
  background: var(--full-dark);
}
.profile-referral-transactions table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
}
.profile-referral-transactions table tr {
  display: flex;
  column-gap: 3px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.profile-referral-transactions table th, .profile-referral-transactions table td {
  text-align: left;
}
.profile-referral-transactions table th,
.profile-referral-transactions tbody tr td:first-of-type {
  display: flex;
  align-items: center;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(14px, 0.208rem + 1.39vw, 20px);
  line-height: 160%;
  color: var(--white);
}
.profile-referral-transactions thead tr th:first-of-type,
.profile-referral-transactions tbody tr td:first-of-type {
  width: clamp(27px, -1.979rem + 7.64vw, 60px);
}
.profile-referral-transactions thead tr th:not(:first-of-type),
.profile-referral-transactions tbody tr td:not(:first-of-type) {
  width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4);
}
.profile-referral-transactions thead tr th:not(:first-of-type) {
  display: flex;
  align-items: center;
  padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
}
.profile-referral-transactions tbody tr td:not(:first-of-type) {
  height: 75px;
  display: flex;
  align-items: center;
  padding: clamp(11px, -0.09rem + 1.62vw, 18px) clamp(11px, 0.062rem + 2.42vw, 30px);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, -0.083rem + 2.78vw, 32px);
  color: var(--yellow);
  border-radius: 20px 4px;
  background: var(--bg-light);
}
.profile-referral-transactions thead tr th:nth-child(2),
.profile-referral-transactions tbody tr td:nth-child(2) {
  width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4 + 11vw);
  font-weight: 400;
  font-size: clamp(16px, 0.839rem + 0.62vw, 24px);
  color: var(--white);
}
.profile-referral-transactions thead tr th:nth-child(4),
.profile-referral-transactions tbody tr td:nth-child(4) {
  width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4 - 11vw);
}
.profile-referral-transactions thead tr th:nth-child(4),
.profile-referral-transactions tbody tr td:nth-child(4),
.profile-referral-transactions thead tr th:nth-child(5),
.profile-referral-transactions tbody tr td:nth-child(5) {
  padding-left: clamp(11px, 0.062rem + 1.2vw, 15px);
  padding-right: clamp(11px, 0.062rem + 1.2vw, 15px);
  justify-content: center;
  text-align: center;
}
.profile-referral-transactions tbody tr.verification td:nth-child(5) {
  color: var(--color-green);
}
.profile-referral-transactions tbody tr.waiting td:nth-child(5) {
  color: var(--gray-30);
}
.profile-referral-transactions tbody tr.error td:nth-child(5) {
  color: var(--color-dark-red);
}
@media screen and (max-width: 920px) {
  .profile-referral-transactions thead {
    display: none;
  }
  .profile-referral-transactions tbody tr {
    display: grid;
    grid-template-columns: 27px 1fr;
    align-items: center;
  }
  .profile-referral-transactions tbody tr td {
    height: 45px !important;
  }
  .profile-referral-transactions tbody tr td:first-of-type {
    grid-column: 1;
    grid-row: 1/5;
  }
  .profile-referral-transactions tbody tr td:not(:first-of-type) {
    width: 100%;
  }
  .profile-referral-transactions tbody tr td:not(:first-of-type):not(:last-child) {
    margin-bottom: 3px;
  }
  .profile-referral-transactions tbody tr td:not(:first-of-type)::before {
    grid-column: 2;
    content: attr(data-label);
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    color: var(--white);
    margin-right: 15px;
  }
  .profile-referral-transactions thead tr th:nth-child(2),
  .profile-referral-transactions tbody tr td:nth-child(2) {
    width: 100%;
  }
  .profile-referral-transactions thead tr th:nth-child(4),
  .profile-referral-transactions tbody tr td:nth-child(4),
  .profile-referral-transactions thead tr th:nth-child(5),
  .profile-referral-transactions tbody tr td:nth-child(5) {
    padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
    padding-right: clamp(11px, 0.062rem + 2.42vw, 30px);
    justify-content: flex-start;
    text-align: left;
  }
}

.simplebar-track.simplebar-vertical {
  background-color: #453A37;
  width: 10px;
  border-radius: 5px;
}

.simplebar-content-wrapper {
  padding-right: 15px;
}

.simplebar-scrollbar::before {
  left: 2px;
  top: 0;
  right: 2px;
  bottom: 0;
  opacity: 1;
  background-color: var(--white);
  border-radius: 10px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 0;
  bottom: 0;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-placeholder {
  display: none !important;
}

.footer {
  position: relative;
  width: 100%;
  min-height: 570px;
  padding: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.footer .bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: -1;
}
.footer .bg .layer {
  position: absolute;
  z-index: 1;
}
.footer .bg .fon {
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer .bg .snake {
  display: none;
}
@media screen and (min-width: 1350px) {
  .footer .bg .snake {
    display: block;
    width: 300px;
    height: 313px;
    bottom: 117px;
    left: 220px;
  }
}
.footer .bg .top-jungle {
  left: 50%;
  top: -203px;
  height: 500px;
  width: 100%;
  transform: translateX(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}
.footer .bg .left {
  left: calc(100% - clamp(1250px, 20.575rem + 92.82vw, 2000px));
  bottom: -15px;
  width: 875px;
  height: 809px;
}
@media screen and (max-width: 992px) {
  .footer .bg .left {
    display: none;
  }
}
.footer .bg .right {
  right: calc(100% - clamp(1320px, 30.322rem + 84.16vw, 2000px));
  bottom: 88px;
  width: 712px;
  height: 658px;
}
@media screen and (max-width: 992px) {
  .footer .bg .right {
    display: none;
  }
}
.footer .bg .grass {
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 255px;
  transform: translateX(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}
.footer .bg .group-left {
  left: -2px;
  bottom: 150px;
  width: 155px;
  height: 243px;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .footer .bg .group-left {
    display: none;
  }
}
.footer .bg .group-right {
  right: 0;
  bottom: 20px;
  width: 244px;
  height: 143px;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .footer .bg .group-right {
    display: none;
  }
}
.footer__container {
  display: flex;
  flex-direction: column;
  aspect-ratio: 152/56;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer__container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.footer__body {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  flex: auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  z-index: 10;
}
@media (min-width: 768px) {
  .footer__body {
    padding-top: 20px;
    grid-template-columns: 1fr 1fr;
    transform: translate3d(0, calc(var(--scrollTop) / 220), 0);
  }
}
@media screen and (min-width: 1200px) {
  .footer__body {
    padding-top: 0;
    grid-template-columns: 1fr auto 1fr;
  }
}
.footer__logo {
  padding-top: 5px;
  align-self: flex-end;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--white);
  z-index: 3;
}
@media (min-width: 768px) {
  .footer__logo {
    align-self: center;
  }
}
@media screen and (min-width: 1200px) {
  .footer__logo {
    grid-column: 2;
  }
}
.footer__logo img {
  position: relative;
  display: block;
}
.footer__block {
  grid-column: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  column-gap: clamp(25px, 0.053rem + 5.83vw, 100px);
}
@media (min-width: 768px) {
  .footer__block {
    grid-column: 2;
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .footer__block {
    grid-column: 3;
  }
}
.footer-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  z-index: 3;
}
.footer-menu__link {
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(16px, 0.92rem + 0.31vw, 1.2px);
  color: var(--white);
  transition: 0.3s !important;
}
.footer-menu__link:focus {
  outline: none;
}
.footer-menu__link:focus-visible {
  outline: 2px solid var(--color-green);
  outline-offset: 3px;
}
@media (any-hover: hover) {
  .footer-menu__link:hover {
    color: var(--yellow) !important;
    text-decoration: underline;
    transition: 0.2s !important;
  }
}
.footer-menu__link:active {
  color: var(--yellow);
  transition: 0.2s !important;
}
.footer__bottom {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 5;
}
.footer__bottom p {
  max-width: 90ch;
  padding-bottom: 20px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--gray-30);
}

.profile--page .footer {
  min-height: 100px;
}

.profile--page .footer__container {
  aspect-ratio: initial;
}

.profile--page .footer__body {
  padding-top: clamp(30px, 1.271rem + 2.33vw, 60px);
  height: auto !important;
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 20px;
}
@media (min-width: 768px) {
  .profile--page .footer__body {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1200px) {
  .profile--page .footer__body {
    grid-template-columns: auto 1fr 1fr;
  }
}

.profile--page .footer__logo {
  grid-column: 1;
}

.profile--page .footer-menu {
  row-gap: 20px;
}

.profile--page .footer__bottom {
  justify-content: flex-start;
}
.profile--page .footer__bottom p {
  text-align: left;
}

.graph-modal {
  background: var(--bg, rgba(3, 7, 19, 0.95));
  overflow: hidden;
}
.graph-modal.is-open {
  display: flex;
}
.graph-modal__container {
  background-color: transparent;
}
.graph-modal__container[data-modal-target] {
  width: 100%;
  max-width: 80%;
  height: 90%;
  padding: clamp(30px, 0.229rem + 6.36vw, 80px);
}
.graph-modal__container[data-modal-target] .graph-modal__content {
  width: 100%;
  color: var(--white, #fff);
}
.graph-modal__container[data-modal-target] .graph-modal__title {
  color: var(--white, #fff);
  text-align: center;
  font-family: var(--font-family);
  font-size: clamp(30px, 1.217rem + 2.54vw, 50px);
  font-weight: 500;
  line-height: normal;
}
.graph-modal__container[data-modal-target] .graph-modal__title span {
  background: linear-gradient(91deg, var(--color-green) 0%, var(--yellow) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.graph-modal__container[data-modal-target] .graph-modal__close {
  color: var(--gray-gray_20, #d7d7e2);
  transition: 0.3s !important;
}
.graph-modal__container[data-modal-target] .graph-modal__close:hover {
  color: var(--yellow, #ffb800);
  transition: 0.2s !important;
}
.graph-modal__container[data-modal-target] .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.graph-modal__container[data-modal-target] .form .input__box {
  position: relative;
  width: 100%;
}
.graph-modal__container[data-modal-target] .form .input__box input {
  width: 100%;
  padding: 14px 20px;
  color: #222;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  border-radius: 10px;
  border: 1px solid var(--gray-1, #c1c6ca);
}
.graph-modal__container[data-modal-target] .form .input__box input:focus + .form__label, .graph-modal__container[data-modal-target] .form .input__box input:valid + .form__label, .graph-modal__container[data-modal-target] .form .input__box input:invalid:not(:focus):placeholder-shown + .form__label {
  top: 0;
  left: 15px;
}
.graph-modal__container[data-modal-target] .form .input__box .form__label {
  position: absolute;
  padding: 5px;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: #222;
  background-color: var(--light-color);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  pointer-events: none;
  transition: 0.3s;
}
.graph-modal__container[data-modal-target] .form .input__box .form__label span {
  color: var(--accent-color);
  font-size: 16px;
}
.graph-modal__container[data-modal-target] .form__btn {
  margin-top: 12px;
  padding: 14px 35px;
  align-self: center;
  border-radius: 10px;
  background: var(--accent-color);
}
.graph-modal__container[data-modal-target] .list__wrapper {
  margin-top: 30px;
  display: grid;
  place-content: center;
}
.graph-modal__container[data-modal-target] .language__list {
  columns: 2;
  column-gap: 60px;
}
@media (min-width: 1024px) {
  .graph-modal__container[data-modal-target] .language__list {
    columns: 3;
    column-gap: 80px;
  }
}
.graph-modal__container[data-modal-target] .language__item {
  margin-bottom: 15px;
}
.graph-modal__container[data-modal-target].thank-you .graph-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.graph-modal__container[data-modal-target].thank-you .graph-modal__title {
  margin-bottom: 0;
  max-width: 19ch;
}
.graph-modal__container[data-modal-target].thank-you .graph-modal__icon {
  width: 100px;
  height: 100px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-color);
  border-radius: 100px;
}

@keyframes bounce-3 {
  0% {
    transform: translateY(0) scale(1);
  }
  30% {
    transform: translateY(-30px) scale(0.9);
  }
  50% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}
@keyframes tabs__down {
  from {
    height: 0;
    transform: scale(1, 0);
    opacity: 0;
    z-index: 0;
  }
  10% {
    opacity: 0.5;
  }
  to {
    height: 100%;
    transform: scale(1, 1);
    opacity: 1;
    z-index: 1;
  }
}
@keyframes tabs__up {
  from {
    transform: scale(1, 1);
    opacity: 1;
    height: 100%;
    z-index: 1;
  }
  10% {
    opacity: 0.5;
  }
  to {
    transform: scale(1, 0);
    opacity: 0;
    height: 0;
    z-index: 0;
  }
}
@keyframes stone-left {
  from {
    opacity: 0;
    left: -120px;
    bottom: -50px;
  }
  30% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
    bottom: 146px;
    left: 222px;
  }
}
@keyframes stone-right {
  from {
    opacity: 0;
    bottom: -20px;
    right: -10px;
  }
  35% {
    opacity: 0.3;
  }
  to {
    opacity: 1;
    bottom: 156px;
    right: 203px;
  }
}
@keyframes rings-1-up {
  from {
    opacity: 0;
    bottom: 157px;
  }
  50% {
    opacity: 60%;
  }
  to {
    opacity: 1;
    bottom: 215px;
  }
}
@keyframes rings-2-up {
  from {
    opacity: 0;
    bottom: 10px;
  }
  50% {
    opacity: 60%;
  }
  to {
    opacity: 1;
    bottom: 157px;
  }
}
@keyframes rings-3-up {
  from {
    opacity: 0;
    bottom: -160px;
  }
  50% {
    opacity: 60%;
  }
  to {
    opacity: 1;
    bottom: 10px;
  }
}
@keyframes scroll-up {
  from {
    opacity: 0;
    bottom: -130px;
  }
  50% {
    opacity: 0.3;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  35% {
    opacity: 0.3;
  }
  55% {
    opacity: 0.6;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  35% {
    opacity: 0.3;
  }
  55% {
    opacity: 0.6;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}