.profile-transaction {
  padding-top: 40px;

  .section__header {
    margin-bottom: 15px;
  }

  &__wrapper {
    width: 100%;
    padding: 17px 8px 19px clamp(14px, -1.458rem + 4.86vw, 35px);
    border: 1px solid var(--gray-90);
    border-radius: 20px;
    background: var(--full-dark);
  }

  &__desc {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(16px, 0.652rem + 1.69vw, 20px);
    line-height: 160%;
    color: var(--gray-60);
    text-align: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0;
    padding: 0;

    & tr {
      display: flex;
      column-gap: 3px;
      width: 100%;
      padding: 5px;
    }

    & th,
    & td {
      text-align: left;
    }
  }

  table th,
  tbody tr td:first-of-type {
    display: flex;
    align-items: center;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(14px, 0.208rem + 1.39vw, 20px);
    line-height: 160%;
    color: var(--white);
  }

  thead tr th:first-of-type,
  tbody tr td:first-of-type {
    width: clamp(27px, -1.979rem + 7.64vw, 60px);
  }

  thead tr th:not(:first-of-type),
  tbody tr td:not(:first-of-type) {
    width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4);
  }

  thead tr th:not(:first-of-type) {
    display: flex;
    align-items: center;
    padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
  }

  tbody tr td:not(:first-of-type) {
    height: 75px;
    display: flex;
    align-items: center;
    padding: clamp(11px, -0.09rem + 1.62vw, 18px) clamp(11px, 0.062rem + 2.42vw, 30px);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, -0.083rem + 2.78vw, 32px);
    color: var(--yellow);
    border-radius: 20px 4px;
    background: var(--bg-light);
  }

  @media screen and (max-width:600px) {
    thead {
      display: none;
    }

    tbody tr {
      display: grid;
      grid-template-columns: 27px 1fr;
      align-items: center;
    }

    tbody tr td {
      height: 45px !important;
    }

    tbody tr td:first-of-type {
      grid-column: 1;
      grid-row: 1/5;
    }

    tbody tr td:not(:first-of-type) {
      width: 100%;
    }

    tbody tr td:not(:first-of-type):not(:last-child) {
      margin-bottom: 3px;
    }

    tbody tr td:not(:first-of-type)::before {
      grid-column: 2;
      content: attr(data-label);
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      color: var(--white);
      margin-right: 15px;
    }
  }

}