.giveaway {
  position: relative;
  overflow: hidden;

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;

    @media screen and (min-width: 1300px) {
      grid-template-columns: 1fr 540px;
      column-gap: 20px;
    }
  }

  &__body {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 992px) {
      // margin: 0;
      max-width: 941px;
    }

    @media screen and (min-width: 1300px) {
      grid-column: 1/3;
    }

    @media screen and (min-width: 1530px) {
      margin: 0;
      max-width: 941px;
    }



    .section__header {
      margin-bottom: 25px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 17px;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 7px;
    }
  }

  &__entries {
    width: 100%;
    max-width: 386px;
    aspect-ratio: 39/43;
    display: flex;
    justify-content: center;
    background: url(../img/hero/tabs/bg-tabs.svg) no-repeat center / contain;

    .entries {
      padding: 10px 24px 23px 22px;
      width: 100%;
      max-width: 375px;
      aspect-ratio: 375/420;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url(../img/giveaway/bg.svg) no-repeat center/contain;

      &__counter {
        display: flex;
        flex-direction: column;

        span {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 80px;
          line-height: 1;
          color: var(--color-dark-red);
        }

        & .desc {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 16px;
          color: var(--gray-90);
        }
      }

      &__description {
        flex: auto;
        margin-top: 25px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: var(--bg);

        & p {
          margin-bottom: 15px;
        }

        & a {
          color: var(--color-text-link);
          transition: 0.2s;

          @include hover {
            color: var(--color-yellow-light);
            text-decoration: underline;
            transition: 0.2s;
          }
        }

        span {
          color: var(--color-dark-red);
        }
      }

      &__footer {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: var(--gray-90);

        & .btn {
          width: 263px;
          min-height: 60px;
          margin-bottom: 9px;
        }
      }
    }
  }

  &__winners {

    .desc {
      margin-top: 16px;
      margin-bottom: 20px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--white);
    }

    .what {
      &__list {
        row-gap: clamp(15px, 0.682rem + 0.99vw, 20px);
      }

      &__item {
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--yellow);

        &::before {
          margin: 0;
        }

        span {
          color: var(--white);
        }
      }
    }

  }

  .winners {
    &__wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      & .referral__wrapper {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
      }

      & .referral__link {
        display: inline-block;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        color: var(--white);
        text-align: center;

        & a {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 16px;
          color: var(--color-text-link);
          transition: 0.2s;

          @include hover {
            color: var(--color-yellow-light);
            text-decoration: underline;
            transition: 0.2s;
          }
        }

        & span {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 16px;
          color: var(--yellow);
        }
      }

      & .referral__rules {
        & a {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 16px;
          color: var(--color-text-link);
          transition: 0.2s;

          @include hover {
            color: var(--color-yellow-light);
            text-decoration: underline;
            transition: 0.2s;
          }
        }
      }
    }

    &__title {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 20px;
      color: var(--gray-30);
    }

    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: clamp(5px, -0.126rem + 1.69vw, 20px);
    }

    &__item {
      position: relative;
      border: 1px solid var(--gray-90);
      border-radius: 22px 5px;
      width: clamp(110px, -2.088rem + 34.64vw, 163px);
      height: clamp(156px, 9.546rem + 0.79vw, 163px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 5px;

      &::after {
        content: '';
        position: absolute;
        inset: 2px;
        border-radius: 20px 4px;
        background: var(--bg-light);
        z-index: -1;
      }

      & span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(16px, 0.766rem + 0.9vw, 24px);
        text-align: center;
        color: var(--yellow);
      }
    }

    &__image {
      width: 80px;
      height: 98px;
      flex-shrink: 0;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &__mask {
    margin-left: auto;
    margin-right: auto;
    grid-column: 1;
    grid-row: 2;
    position: relative;
    border-radius: 20px;
    width: clamp(290px, -1.875rem + 100vw, 540px);
    height: auto !important;
    flex-shrink: 0;
    overflow: hidden;

    & iframe {
      width: 100%;
      height: calc(3000px - clamp(1296px, 75.88rem + 25.6vw, 1360px)) !important;
      object-fit: cover;
    }

    @media screen and (min-width: 570px) {
      height: auto;
      aspect-ratio: 54/164;
    }

    @media screen and (min-width: 1300px) {
      // margin: 0;
      grid-column: 1/3;
    }

    @media screen and (min-width: 1530px) {
      grid-column: 2;
      grid-row: 1/3;
    }
  }


  &__image {
    position: relative;
    width: 100%;
    min-height: 820px;
    z-index: 0;

    grid-column: 1 / 3;
    grid-row: 3;

    @media screen and (min-width: 1530px) {
      bottom: unset;
      position: relative;
      grid-column: 1;
      grid-row: 2;
    }

    & img {
      position: absolute;
      will-change: transform;
    }

    &::after {
      content: '';
      position: absolute;
      width: 750px;
      height: 474px;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      background: url(../img/ellipse-2.svg) no-repeat center/contain;
      z-index: -1;
    }

    // @include for-desktop {
    //   position: absolute;
    //   width: 900px;
    //   top: 30px;
    //   right: -140px;
    //   z-index: 0;

    //   &::after {
    //     left: 50%;
    //   }
    // }

    & .confident {
      top: 0;
      left: 50%;
      min-width: 410px;

      transform: translateX(-50%);
      width: 410px;
      height: 580;
      object-fit: contain;
      z-index: 3;

      @media screen and (min-width:1024px) {
        left: 50%;
        right: 0;
        transform: translateX(-50%);
      }
    }

    & .group {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 525px;
      height: 525px;

      @media screen and (min-width:1024px) {
        left: 50%;
      }
    }

    & .light {
      top: 28px;
      right: 84px;
    }

    & .stone {
      left: 52%;
      transform: translateX(-50%);
      top: 473px;
    }

    & .rings {
      position: absolute;
      will-change: transform;
      bottom: 0;
      left: 52%;
      width: 347px;
      height: 287px;
      display: flex;
      justify-content: center;
      transform: translateX(-50%);
      transition: var(--transition);
      z-index: -1;

      @media screen and (max-width:1024px) {
        background: url(../img/ecosystem/rings.png) no-repeat center/contain;
      }

      & img {
        position: absolute;
      }

      & .rings-1 {
        bottom: 157px;
        opacity: 0;
        width: 314px;
        height: 73px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 1s;
          animation: rings-1-up 0.7s ease-in-out;
          bottom: 215px;
          opacity: 1;
          // }
        }
      }

      & .rings-2 {
        bottom: 10px;
        opacity: 0;
        width: 347px;
        height: 107px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 2s;
          animation: rings-2-up 0.7s ease-in-out;
          bottom: 157px;
          opacity: 1;
          // }
        }
      }

      & .rings-3 {
        bottom: -160px;
        opacity: 0;
        width: 258px;
        height: 152px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 3s;
          animation: rings-3-up 0.7s ease-in-out;
          bottom: 10px;
          opacity: 1;
          // }
        }
      }
    }

    & .stone-1 {
      bottom: 146px;
      left: 10vw;
      transition: var(--transition);

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        left: -120px;
        bottom: -30px;
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-left 1.2s cubic-bezier(.075, .5, 0, 1);
          bottom: 146px;
          left: 222px;
          opacity: 1;
        }
      }
    }

    & .stone-2 {
      bottom: 156px;
      right: 10vw;

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        bottom: -20px;
        right: -10px;
        transition: var(--transition);
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-right 1.45s cubic-bezier(.075, .5, 0, 1);
          bottom: 156px;
          right: 203px;
          opacity: 1;
        }
      }
    }
  }
}