.games {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);

  &__body {
    width: min(var(--content-width), 100% - 4px);
  }

  & .section__header {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: clamp(30px, 1.546rem + 1.27vw, 40px);

    @media screen and (min-width: 1555px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & .section__subtitle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 145ch;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    text-align: center;
    color: var(--white);
  }

  &-slider {
    position: relative;
    width: 100%;
    user-select: none;

    .swiper-wrapper {}

    .swiper-slide {
      display: flex;
      justify-content: center;
      height: 387px;
    }
  }

  &__item {
    position: relative;
    padding: 8px 17px 7px;
    width: 100%;
    max-width: 371px;
    aspect-ratio: 37/39;
    display: flex;
    align-items: center;
    background: url(../img/games/bg.svg) no-repeat center/contain;
  }

  &__image {
    width: 100%;
    aspect-ratio: 37/39;
    background: #d9d9d9;
    border-radius: 20px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  & .buttons-arrow {
    margin-top: clamp(25px, 0.668rem + 4.2vw, 40px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;

    & .btn-arrow {
      transition: 0.3s !important;
    }

    & .btn-arrow svg .fon,
    & .btn-arrow svg .arrow {
      transition: 0.3s !important;
    }

    & .btn-arrow:hover .fon {
      fill: var(--yellow);
      transition: 0.2s !important;
    }

    & .btn-arrow:hover .arrow {
      stroke: black;
      transition: 0.2s !important;
    }

    & .btn-arrow.swiper-button-disabled {
      opacity: 0.5;

      &:hover .fon {
        fill: black;
      }

      &:hover {
        .arrow {
          stroke: #FFB800;
        }
      }
    }
  }
}