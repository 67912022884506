.island {
  position: relative;
  min-height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 10;

  @include for-desktop {
    width: 100%;
  }

  &.lazy-background.visible {
    background-image: url(../img/island/malenkii-mir.png);
  }

  & .bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -70px;
      width: 260px;
      height: 150px;
      background: var(--bg);
      z-index: 3;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    & .layer {
      position: absolute;
      background-repeat: no-repeat;
      pointer-events: none;
      transition: var(--transition);
    }

    & .top-jungle {
      top: -170px;
      left: -1%;
      width: 101%;
      min-width: 900px;
      height: 422px;
      background-position: center bottom;
      background-size: cover;
      z-index: 1;
    }

    & .rays {
      top: calc(var(--index-wh) * -3);
      right: calc(100vw - clamp(514px, 13.635rem + 78.89vw, 1026px));
      width: 472px;
      height: 559px;
      z-index: 0;

      @include for-desktop {
        right: clamp(0px, -18.391rem + 28.74vw, 200px);
      }

      @media screen and (min-width: 1700px) {
        right: calc(var(--index-wh) * 6);
      }
    }

    & .floating {
      width: clamp(212px, 11.479rem + 6.84vw, 300px);
      height: auto;
      bottom: 385px;
      right: clamp(75px, 0.7rem + 17.5vw, 325px);
      z-index: 2;

      @media screen and (min-width: 992px) {
        bottom: 420px;
      }

      @media screen and (min-width: 1700px) {
        right: calc(var(--index-wh) * 12);
      }

      & img {
        width: 100%;
        height: auto;
        aspect-ratio: 30/38;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 589px;
        height: auto;
        aspect-ratio: 60/47;
        transform: translate(-50%, -50%);
        background: url(../img/island/ellipse.svg) no-repeat center/contain;
        z-index: -1;
      }
    }

    & .light {
      width: 900px;
      aspect-ratio: 90/47;
      right: calc(100vw - clamp(536px, 10.545rem + 88.71vw, 1322px));
      bottom: 160px;
      z-index: 2;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;

      @media screen and (min-width: 1300px) {
        right: clamp(0px, -12.712rem + 16.65vw, 115px);
      }
    }

    & .owners {
      width: clamp(274px, 12.095rem + 19.44vw, 524px);
      height: auto;
      aspect-ratio: 52/45;
      bottom: calc(var(--index-wh) * 7);
      left: clamp(0px, -4.97rem + 19.21vw, 247px);

      @media screen and (min-width: 414px) {
        bottom: calc(var(--index-wh) * 5);
      }

      @media screen and (min-width: 567px) {
        bottom: calc(var(--index-wh) * 7);
      }

      @media screen and (min-width:992px) {
        bottom: calc(var(--index-wh) * 4);
        z-index: 3;
      }

      @media screen and (min-width: 1750px) {
        bottom: calc(var(--index-wh) * 4);
        left: calc((100vw - 1700px) / 2);
      }

      & svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 68%;
        width: clamp(600px, 14.549rem + 35.86vw, 900px);
        aspect-ratio: 40/15;
        transform: translate(-50%, -50%);
        background: url(../img/island/ellipse2.svg) no-repeat center/contain;
        z-index: -1;
      }
    }

    & .grass {
      width: clamp(111px, 4.865rem + 8.01vw, 214px);
      height: auto;
      aspect-ratio: 21/22;
      bottom: calc(var(--index-wh) * 6);
      right: clamp(20px, -13.791rem + 58.13vw, 785px);
      z-index: 2;

      @include for-desktop {
        bottom: calc(var(--index-wh) * 5);
      }
    }

    & .leaf {

      display: none;

      @include for-desktop {
        display: block;
        width: clamp(199px, 8.776rem + 14.15vw, 381px);
        height: auto;
        aspect-ratio: 38/17;
        bottom: calc(var(--index-wh) * 7);
        right: clamp(0px, -14.357rem + 22.5vw, 205px);
        z-index: 3;
      }
    }

    & .ahead {
      bottom: -115px;
      left: 0;
      top: unset;
      width: 100%;
      height: 517px;
      background-position: center bottom;
      background-size: cover;
      min-width: clamp(852px, 46.405rem + 17.26vw, 900px);
      z-index: 10;

      @include for-desktop {}

      @media screen and (min-width: 1800px) {
        height: auto;
        aspect-ratio: 173/53;
      }
    }
  }

  & .section__header {
    position: relative;
    margin-top: calc(100% - clamp(183px, -0.482rem + 73.6vw, 546px));
    max-width: 55vw;
    transition: var(--transition);
    z-index: 4;

    @media screen and (max-width: 767px) {
      max-width: none !important;
    }

    @media screen and (min-width: 768px) {
      margin-top: 215px;
    }
  }

  & .section__subtitle {
    font-size: clamp(16px, 0.92rem + 0.31vw, 20px);

    @media screen and (max-width: 767px) {
      text-align: justify;
    }
  }
}

.faq--page .island-lite,
.howtobuy--page .island-lite,
.referral--page .island-lite,
.win--page .island-lite {
  height: auto;
  min-height: clamp(306px, 15.127rem + 12.44vw, 453px);
  overflow: hidden;

  & .bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: -134px;
      width: clamp(120px, 3.333rem + 20.83vw, 260px);
      height: 150px;
      background: var(--bg);
      z-index: 9;

      @media screen and (min-width:992px) {
        bottom: -90px;
        z-index: 10;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  & .bg .bg {
    display: block;
    position: absolute;
    width: 100%;
    aspect-ratio: 173/35;
  }

  & .bg .icicles {
    display: none;

    @media screen and (min-width:600px) {
      display: block;
      position: absolute;
      width: 697px;
      height: 199px;
      top: 0;
      left: -17vw;
      z-index: 5;
    }

    @media screen and (min-width:992px) {
      left: -3vw;
    }

    @media screen and (min-width:1728px) {
      left: calc((100vw - 1728px) / 2 + -22px);
    }
  }

  & .bg .grass {
    width: clamp(101px, 4.039rem + 8.79vw, 214px);
    right: clamp(-50px, -16.791rem + 58.13vw, 785px);
    height: auto;
    aspect-ratio: 21/22;
    bottom: calc(var(--index-wh) * 3);
    right: clamp(20px, -13.791rem + 58.13vw, 785px);
    z-index: 2;
  }

  & .bg .green {
    width: 62px;
    height: 62px;
    left: 10px;
    bottom: 84px;
    z-index: 2;
  }

  & .bg .leaf {

    display: none;

    @include for-desktop {
      display: block;
      width: clamp(199px, 8.776rem + 14.15vw, 381px);
      height: auto;
      aspect-ratio: 38/17;
      bottom: calc(var(--index-wh) * 7);
      right: clamp(0px, -14.357rem + 22.5vw, 205px);
      z-index: 3;
    }
  }

  & .bg .owners {
    width: clamp(198px, 10.688rem + 8.44vw, 333px);
    height: auto;
    aspect-ratio: 52/45;
    bottom: calc(var(--index-wh) * 2);
    left: clamp(0px, -4.97rem + 19.21vw, 247px);

    & svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 68%;
      width: clamp(600px, 14.549rem + 35.86vw, 900px);
      aspect-ratio: 40/15;
      transform: translate(-50%, -50%);
      background: url(../img/island/ellipse2.svg) no-repeat center/contain;
      z-index: -1;
    }
  }

  & .bg .ahead {
    bottom: -54px;
    min-width: clamp(802px, 48.153rem + 7.62vw, 900px);
    height: auto;
    aspect-ratio: 173/52;
    left: 0;
    top: unset;
    width: 100%;
    background-position: center bottom;
    background-size: cover;
    z-index: 10;

    @media screen and (min-width: 992px) {
      bottom: -60px;
    }
  }

}