.profile-referral-transactions {
  padding-top: 50px;

  .section__header {
    margin-bottom: 15px;
  }

  &__wrapper {
    width: 100%;
    padding: clamp(10px, 0.484rem + 0.54vw, 17px) 8px clamp(10px, 0.444rem + 0.7vw, 19px) clamp(14px, -1.458rem + 4.86vw, 35px);
    border: 1px solid var(--gray-90);
    border-radius: 20px;
    background: var(--full-dark);
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0;
    padding: 0;

    & tr {
      display: flex;
      column-gap: 3px;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & th,
    & td {
      text-align: left;
    }
  }

  table th,
  tbody tr td:first-of-type {
    display: flex;
    align-items: center;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(14px, 0.208rem + 1.39vw, 20px);
    line-height: 160%;
    color: var(--white);
  }

  thead tr th:first-of-type,
  tbody tr td:first-of-type {
    width: clamp(27px, -1.979rem + 7.64vw, 60px);
  }

  thead tr th:not(:first-of-type),
  tbody tr td:not(:first-of-type) {
    width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4);
  }

  thead tr th:not(:first-of-type) {
    display: flex;
    align-items: center;
    padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
  }

  tbody tr td:not(:first-of-type) {
    height: 75px;
    display: flex;
    align-items: center;
    padding: clamp(11px, -0.09rem + 1.62vw, 18px) clamp(11px, 0.062rem + 2.42vw, 30px);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, -0.083rem + 2.78vw, 32px);
    color: var(--yellow);
    border-radius: 20px 4px;
    background: var(--bg-light);
  }

  thead tr th:nth-child(2),
  tbody tr td:nth-child(2) {
    width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4 + 11vw);
    font-weight: 400;
    font-size: clamp(16px, 0.839rem + 0.62vw, 24px);
    color: var(--white);
  }

  thead tr th:nth-child(4),
  tbody tr td:nth-child(4) {
    width: calc((100% - clamp(27px, -1.979rem + 7.64vw, 60px)) / 4 - 11vw);
  }

  thead tr th:nth-child(4),
  tbody tr td:nth-child(4),
  thead tr th:nth-child(5),
  tbody tr td:nth-child(5) {
    padding-left: clamp(11px, 0.062rem + 1.2vw, 15px);
    padding-right: clamp(11px, 0.062rem + 1.2vw, 15px);
    justify-content: center;
    text-align: center;
  }

  tbody tr.verification td:nth-child(5) {
    color: var(--color-green);
  }

  tbody tr.waiting td:nth-child(5) {
    color: var(--gray-30);
  }

  tbody tr.error td:nth-child(5) {
    color: var(--color-dark-red);
  }

  @media screen and (max-width:920px) {
    thead {
      display: none;
    }

    tbody tr {
      display: grid;
      grid-template-columns: 27px 1fr;
      align-items: center;
    }

    tbody tr td {
      height: 45px !important;
    }

    tbody tr td:first-of-type {
      grid-column: 1;
      grid-row: 1/5;
    }

    tbody tr td:not(:first-of-type) {
      width: 100%;
    }

    tbody tr td:not(:first-of-type):not(:last-child) {
      margin-bottom: 3px;
    }

    tbody tr td:not(:first-of-type)::before {
      grid-column: 2;
      content: attr(data-label);
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      color: var(--white);
      margin-right: 15px;
    }

    thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
      width: 100%;
    }

    thead tr th:nth-child(4),
    tbody tr td:nth-child(4),
    thead tr th:nth-child(5),
    tbody tr td:nth-child(5) {
      padding-left: clamp(11px, 0.062rem + 2.42vw, 30px);
      padding-right: clamp(11px, 0.062rem + 2.42vw, 30px);
      justify-content: flex-start;
      text-align: left;
    }
  }
}