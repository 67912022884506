.ecosystem {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 9.28vw, 80px);
  background: var(--bg);
  overflow: hidden;
  // z-index: 10;

  & .section__header {
    margin-bottom: clamp(25px, 0.969rem + 2.97vw, 60px);
  }

  & .section__title {
    @media screen and (max-width:1200px) {
      text-align: center;
    }
  }

  &__wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @include for-desktop {
      flex-direction: row;
    }
  }

  & .what__list {
    position: relative;
    z-index: 1;
  }

  &__image {
    position: relative;
    width: 100%;
    min-height: 820px;
    z-index: 0;

    & img {
      position: absolute;
      will-change: transform;
    }

    &::after {
      content: '';
      position: absolute;
      width: 750px;
      height: 474px;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      background: url(../img/ellipse-2.svg) no-repeat center/contain;
      z-index: -1;
    }

    @include for-desktop {
      position: absolute;
      width: 900px;
      top: -45px;
      right: -140px;
      z-index: 0;

      &::after {
        left: 50%;
      }
    }

    & .confident {
      top: 0;
      left: 50%;
      min-width: 410px;

      transform: translateX(-50%);
      width: 410px;
      height: 580;
      object-fit: contain;
      z-index: 3;

      @media screen and (min-width:1024px) {
        left: 50%;
        right: 0;
        transform: translateX(-50%);
      }
    }

    & .group {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 525px;
      height: 525px;

      @media screen and (min-width:1024px) {
        left: 50%;
      }
    }

    & .light {
      top: 28px;
      right: 84px;
    }

    & .stone {
      left: 52%;
      transform: translateX(-50%);
      top: 473px;
    }

    & .rings {
      position: absolute;
      will-change: transform;
      bottom: 0;
      left: 52%;
      width: 347px;
      height: 287px;
      display: flex;
      justify-content: center;
      transform: translateX(-50%);
      transition: var(--transition);
      z-index: -1;

      // @media screen and (max-width:1024px) {
      //   background: url(../img/ecosystem/rings.png) no-repeat center/contain;
      // }

      & img {
        position: absolute;
      }

      & .rings-1 {
        bottom: 157px;
        opacity: 0;
        width: 314px;
        height: 73px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 1s;
          animation: rings-1-up 0.7s ease-in-out;
          bottom: 215px;
          opacity: 1;
          // }
        }
      }

      & .rings-2 {
        bottom: 10px;
        opacity: 0;
        width: 347px;
        height: 107px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 2s;
          animation: rings-2-up 0.7s ease-in-out;
          bottom: 157px;
          opacity: 1;
          // }
        }
      }

      & .rings-3 {
        bottom: -160px;
        opacity: 0;
        width: 258px;
        height: 152px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 3s;
          animation: rings-3-up 0.7s ease-in-out;
          bottom: 10px;
          opacity: 1;
          // }
        }
      }
    }

    & .stone-1 {
      bottom: 146px;
      left: 10vw;
      transition: var(--transition);

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        left: -120px;
        bottom: -30px;
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-left 1.2s cubic-bezier(.075, .5, 0, 1);
          bottom: 146px;
          left: 222px;
          opacity: 1;
        }
      }
    }

    & .stone-2 {
      bottom: 156px;
      right: 10vw;

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        bottom: -20px;
        right: -10px;
        transition: var(--transition);
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-right 1.45s cubic-bezier(.075, .5, 0, 1);
          bottom: 156px;
          right: 203px;
          opacity: 1;
        }
      }
    }
  }
}