.profile-referral-bonus {
  padding-top: 50px;

  & .section__header {
    margin-bottom: 15px;
  }

  &__wrapper {
    width: 100%;
    padding: clamp(15px, 0.653rem + 1.21vw, 25px) clamp(10px, 0.341rem + 1.21vw, 20px);
    border: 1px solid var(--gray-90);
    border-radius: 20px;
    background: var(--full-dark);
  }
}

.referral-bonus {

  &__link {
    margin-bottom: clamp(10px, 0.296rem + 1.27vw, 20px);
    display: flex;
    align-items: center;
    column-gap: 10px;

    & input {
      flex: 1 1 auto;
      padding: 14px;
      height: 51px;
      border-radius: 6px;
      background: var(--white);
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 137%;
      color: var(--full-dark);

      &::placeholder {
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 16px;
        line-height: 137%;
        color: var(--full-dark);
      }
    }

    & .btn {
      width: clamp(83px, 3.54rem + 7.03vw, 141px);

      &:active {
        color: var(--full-dark);
      }
    }
  }

  &__desc {
    font-family: var(--second-family);
    font-weight: 500;
    font-size: clamp(14px, 0.677rem + 0.76vw, 20px);
    line-height: 160%;
    color: var(--gray-60);
  }
}