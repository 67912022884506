.presale-stage {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);

  & .section__header {
    margin-bottom: clamp(12px, -0.336rem + 4.2vw, 45px);
  }

  .presale-slider {
    width: 100%;
    user-select: none;

    .swiper-wrapper {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    &__item {
      height: 100%;
      padding-top: 80px;
      padding-left: 34px;
      padding-right: 32px;
      padding-bottom: 45px;
      display: flex;
      flex-direction: column;
      position: relative;
      aspect-ratio: 37/39;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.sold {
        opacity: 0.7;

        & .sold-out {
          display: flex;
          width: 157px;
          height: 80px;
          background: url(../img/presale/sold.svg) no-repeat center/contain;
        }

        & .primary-btn {
          display: none;
        }
      }

      &.lazy-background.visible {
        background-image: url(../img/presale/bg.svg);
      }

      &-header {
        position: absolute;
        top: -7px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &-title {
        width: 213px;
      }

      &-discont {
        padding-bottom: 8px;
        position: absolute;
        top: -10px;
        right: 0;
        width: 85px;
        height: 87px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../img/presale/discont.svg) no-repeat center/contain;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--white);
      }

      & .feature__list {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      & .feature__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 20px;
          color: var(--bg);

          &:last-child {
            color: var(--color-dark-red);
          }
        }
      }

      &-footer {
        flex: auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        & .sold-out {
          display: none;
        }

        & .btn {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }

    & .buttons-arrow {
      margin-top: 22px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 15px;

      & .btn-arrow {
        transition: 0.3s !important;
      }

      & .btn-arrow svg {
        transition: 0.3s !important;
      }

      & .btn-arrow svg .fon,
      & .btn-arrow svg .arrow {
        transition: 0.3s !important;
      }

      & .btn-arrow:hover .fon {
        fill: var(--yellow);
        transition: 0.2s !important;
      }

      & .btn-arrow:hover .arrow {
        stroke: black;
        transition: 0.2s !important;
      }

      & .btn-arrow.swiper-button-disabled {
        opacity: 0.5;

        &:hover .arrow {
          stroke: #FFB800;
        }

        &:hover .fon {
          fill: black !important;
        }
      }
    }
  }
}