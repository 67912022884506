.roadmap {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);

  &__body {
    width: min(var(--content-width), 100%);
  }

  & .section__header {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: clamp(24px, 0.315rem + 4.58vw, 60px);

    @media screen and (min-width: 1555px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & .section__subtitle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 126ch;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    text-align: center;
    color: var(--white);
  }

  &-slider {
    position: relative;
    width: 100%;
    user-select: none;

    .swiper-wrapper {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    @media screen and (max-width: 1555px) {
      padding-left: 15px;
    }
  }

  &-item {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 135px;
    z-index: 4;
    opacity: 0.5;

    &::after {
      content: '';
      position: absolute;
      top: 117px;
      right: calc(100% - clamp(290px, -2.993rem + 90.1vw, 463px));
      width: clamp(99px, -7.484rem + 58.3vw, 211px);
      height: 12px;
      background-image: url(../img/roadmap/chain.svg);
      background-repeat: space no-repeat;
      background-size: 12px 12px;
      z-index: -1;

      @media screen and (min-width:567px) {
        right: calc(100% - clamp(250px, -5.054rem + 58.35vw, 498px));
        width: clamp(80px, -8.841rem + 39.06vw, 246px);
      }

      @media screen and (min-width:992px) {
        right: calc(100% - clamp(274px, -5.231rem + 36.06vw, 349px));
        width: clamp(100px, -8.654rem + 24.04vw, 150px);
      }

      @media screen and (min-width:1200px) {
        right: calc(100% - clamp(255px, 5rem + 14.58vw, 360px));
        width: clamp(96px, -1.292rem + 9.72vw, 166px);
      }
    }

    &__quarter {
      display: flex;
      flex-direction: column;
      align-items: center;

      .count {
        position: relative;
        padding: 5px 17px 4px;
        border: 0.50px solid var(--white);
        border-radius: 22px 6px;
        width: 115px;
        height: 48px;
        display: flex;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 32px;
        color: var(--white);

        &::after {
          content: '';
          position: absolute;
          top: 3px;
          right: 3px;
          width: 51px;
          height: 41px;
          background: url(../img/roadmap/no.svg) no-repeat center/contain;
        }
      }

      & span {
        margin-top: 4px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 20px;
        color: var(--gray-60);
      }
    }

    &.active-item {
      opacity: 1;
    }

    &.active-item .count::after {
      background: url(../img/roadmap/yes.svg) no-repeat center/contain;
    }

    &__image {
      position: absolute;
      top: 52px;
      left: 50%;
      width: 203px;
      height: 136px;
      transform: translateX(-50%);

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 7px;
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 137%;
      color: var(--yellow);

      &::before {
        content: '';
        width: 8px;
        height: 7px;
        margin-right: 10px;
        background: url(../img/roadmap/marker.svg) no-repeat center/contain;
      }
    }
  }

  & .buttons-arrow {
    margin-top: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;

    & .btn-arrow {
      transition: 0.3s !important;
    }

    & .btn-arrow svg .fon,
    & .btn-arrow svg .arrow {
      transition: 0.3s !important;
    }

    & .btn-arrow:hover .fon {
      fill: var(--yellow);
      transition: 0.2s !important;
    }

    & .btn-arrow:hover .arrow {
      stroke: black;
      transition: 0.2s !important;
    }

    & .btn-arrow.swiper-button-disabled {
      opacity: 0.5;

      &:hover .fon {
        fill: black;
      }

      &:hover {
        .arrow {
          stroke: #FFB800;
        }
      }
    }
  }
}