.tabs {
  max-width: 395px;

  &__nav {
    margin-bottom: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: clamp(50px, 1.525rem + 8vw, 56px);

    &-item {
      flex: 0 1 180px;
      aspect-ratio: 180/56;
      height: inherit;
    }

    &-btn {
      position: relative;
      padding: 16px 18px;
      width: 100%;
      height: 100%;
      transition: 0.2s;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: 2px solid var(--color-green);
        outline-offset: 3px;

      }

      @include hover {
        color: var(--full-dark);
      }

      &:active {
        color: var(--full-dark);
      }

      & svg {
        position: absolute;
        inset: 0;
        height: 100%;
        z-index: -1;
      }

      &.tabs__nav-btn--active {
        opacity: 1;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;

      }
    }
  }


  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    width: 100%;
    max-width: 380px;
    height: 424px;
    background: url(../img/hero/tabs/bg-tabs.svg) no-repeat center / contain;
  }

  &__panel {
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: top center;
    width: 100%;
    background: url(../img/hero/tabs/bg-panel.svg) no-repeat center/contain;
    opacity: 0;
    animation-name: tabs__up;
    animation-duration: .7s;
    animation-fill-mode: forwards;

    &.tabs__panel--active {
      animation-name: tabs__down;
      animation-duration: .7s;
      animation-fill-mode: forwards;
    }
  }

  &__wrapper {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__header {
    padding-left: 13px;
    padding-right: 13px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    color: var(--gray-90);

    & span {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
      color: var(--color-dark-red);
      text-transform: uppercase;
    }

  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: clamp(138px, -1.2rem + 45.33vw, 172px);
    height: 48px;
    background: url(../img/hero/tabs/title.svg) no-repeat center/contain;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
    color: var(--white);
    text-transform: capitalize;
  }

  &__bonus {
    padding-left: 16px;
    padding-right: 16px;
  }

  & .bonus {

    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 7px;
      column-gap: 14px;
    }

    &__item {
      position: relative;
      flex: 0 0 clamp(82px, 0.4rem + 24vw, 105px);
      padding: 5px clamp(7px, -2.708rem + 6.67vw, 15px);
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3px;
      border-radius: 5px;
      background: var(--gray-90);

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100.35%;
        width: 14px;
        height: 13px;
        background: url(../img/hero/tabs/plus.svg) no-repeat center/contain;
      }

      &:nth-child(2n + 3)::after {
        display: none;
      }

      & p {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 12px;
        color: var(--white);
      }

      & span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        color: var(--yellow);

      }
    }
  }

  .token {
    padding-left: clamp(25px, -1.104rem + 13.33vw, 35px);
    padding-right: clamp(30px, -0.792rem + 13.33vw, 40px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      text-align: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 32px;
      color: var(--color-dark-red);

    }

    &__index {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

    }

    & .current,
    & .next {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      text-align: center;

      & .index {
        display: flex;
        align-items: center;
        gap: 6px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 20px;
        color: var(--bg);

        span {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 20px;
          color: var(--color-dark-red);

        }
      }


      & .desc {
        color: var(--gray-90);
      }
    }

  }

  .clock {
    margin-top: -5px;
    padding-left: clamp(24px, -2.104rem + 13.33vw, 35px);
    padding-right: clamp(28px, -1.792rem + 13.33vw, 40px);
    display: flex;
    flex-direction: column;

    &__wrapper,
    & .trend {
      display: flex;
    }

    &__wrapper {
      padding-top: 5px;
      padding-bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: clamp(15px, -2.699rem + 18.18vw, 25px);
      padding-right: clamp(15px, -2.699rem + 18.18vw, 25px);
      background: var(--white);
      border-radius: 4px;

      & .block {
        flex: 0 0 45px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child) span::after {
          content: ':';
          position: absolute;
          top: 0;
          right: -35%;
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 32px;
          color: var(--color-dark-red);

          @media screen and (max-width: 375px) {
            right: -3.2vw;
          }
        }
      }

      & span {

        font-family: var(--font-family);
        font-weight: 400;
        font-size: 32px;
        line-height: 1;
        color: var(--color-dark-red);
      }


      & .smalltext {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        color: var(--gray-90);

      }
    }

    .trend {
      background: var(--gray-60);
      border-radius: 4px;

      &__desc {
        position: relative;
        padding: 2px 5px 2px 7px;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 12px;
        line-height: 167%;
        letter-spacing: -0.02em;
        color: var(--white);
        background: var(--color-green);
        border-radius: 4px;
        z-index: 1;

        @media screen and (max-width:375px) {
          line-height: 1.2;
        }
      }

      &__index {
        padding: 2px 5px 2px 7px;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 12px;
        line-height: 167%;
        letter-spacing: -0.02em;
        color: var(--white);

        @media screen and (max-width:375px) {
          line-height: 1.2;
        }
      }
    }

  }

  &__footer {
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    column-gap: 6px;

    & .total__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 20px;
      color: var(--bg);

      & span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 32px;
        color: var(--color-dark-red);

      }
    }

    & .btn {
      @media screen and (max-width:375px) {
        font-size: 18px;
      }
    }
  }

}