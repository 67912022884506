.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--white-white100);
  background: rgba(0, 0, 0, 0.5);
  transition: 0.4s ease-in-out;
  z-index: 20;

  &__container {
    max-width: 1428px;
  }

  &.topheader {
    top: -300px;
  }

  &.sticky {
    position: fixed;
    top: 0;
    backdrop-filter: blur(25px);

    & .header__container {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & .header__logo {
      margin-top: -15px;

      @include for-desktop {
        margin-top: 0;
        width: clamp(110px, -4.019rem + 17.02vw, 174px);
        left: 43.5%;
      }

      @media screen and (min-width: 1200px) {
        left: 45.2%;
      }

      @media screen and (min-width: 1500px) {
        left: 48vw;
      }

      @media screen and (min-width: 1700px) {
        left: 48vw;
      }

      & .logo-desktop {
        display: none;
      }

      & .logo-mobile {
        display: block;
        left: 0;
        top: 6px;
        transform: none;

        @media screen and (min-width: 414px) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      & .lian {
        display: none;
      }
    }
  }

  &.menu--active {
    &::after {
      left: 0;
    }
  }

  &__logo {
    position: relative;
    flex-shrink: 0;
    width: clamp(140px, 1.516rem + 36.17vw, 174px);
    height: 50px;
    z-index: 5;

    & .logo-desktop {
      display: none;
    }

    & .logo-mobile {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 1024px) {
        left: 50%;
        transform: translateX(-50%);
      }

      @media screen and (max-width: 414px) {
        width: 110px;
        left: 0;
        transform: none;
      }
    }

    & .lian {
      position: absolute;
      width: 66px;
      height: 129px;
      bottom: -128px;
      left: 4px;
      z-index: -1;
      rotate: -17deg;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @media screen and (max-width: 414px) {
        bottom: -104px;
        left: -6px;
      }

      @include for-desktop {
        width: 130px;
        height: 255px;
        bottom: calc(100% - clamp(16.313rem, 9.027rem + 11.38vw, 22.688rem));
        left: clamp(1.25rem, -5.179rem + 10.04vw, 110px);
      }

    }

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: clamp(435px, -6rem + 51.57vw, 787px);
      transform: translateX(-50%);
      width: clamp(170px, -9.665rem + 31.52vw, 390px);
      height: auto;
      aspect-ratio: 27/14;

      & .logo-desktop {
        display: block;
      }

      & .logo-mobile {
        display: none;
      }
    }

    @media screen and (min-width: 1728px) {
      left: 46%;
    }

    & img {
      position: absolute;
      inset: 0;
      object-fit: contain;
    }
  }

  &__container {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: clamp(0.625rem, -0.185rem + 4.05vw, 1.25rem);

    @include for-desktop {
      padding-top: 10px;
      padding-bottom: 13px;
      gap: clamp(15px, -2.297rem + 5.05vw, 34px);
    }
  }

  &__nav {
    position: fixed;
    padding: clamp(0.938rem, 0.511rem + 2.13vw, 1.875rem) 11px;
    top: 65px;
    right: -102%;
    width: 100%;
    max-width: 375px;
    height: 100vh;
    height: 100lvh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background: var(--bg);
    transition: 0.4s ease-in-out;
    overflow-y: auto;

    & .mobile__actions {
      width: 100%;
      padding-top: 5vh;
      display: flex;
      justify-content: center;
      gap: 15px;

      @include for-desktop {
        display: none !important;
      }
    }

    & .btn-win {
      width: 154px;
      background: url(../img/buttons/primary.png) no-repeat center/contain;
    }

    & .primary-btn {
      width: 100%;
      max-width: 330px;
      max-height: 60px;
      margin: 0 auto;
      white-space: nowrap;

      @include for-desktop {
        display: none !important;
      }
    }

    &.menu--active {
      right: 0;
    }

    @include for-desktop {
      flex: auto;
      width: auto;
      max-width: none;
      position: relative;
      padding: 0;
      top: 0;
      left: 0;
      height: auto;
      box-shadow: none;
      background-color: transparent;
      gap: clamp(10px, -9rem + 7.83vw, 70px);
      overflow: initial;
    }
  }

  &-menu {
    padding-left: 9px;
    padding-right: 9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    transition: 0.3s;

    @include for-desktop {
      padding: 0;
      width: 100%;
      flex-direction: row;
      gap: 0;
    }

    &__item {
      position: relative;
      margin-right: clamp(25px, -2.588rem + 6.45vw, 70px);
      transition: 0.3s;

      @include for-desktop {
        // margin-right: auto;
      }

      &:nth-of-type(5) {
        margin-left: auto;
        margin-right: clamp(25px, -0.601rem + 2.88vw, 40px);
      }

      &:nth-of-type(6) {
        margin-right: clamp(15px, -2.668rem + 4.81vw, 40px);
      }

      &:nth-of-type(7) {
        margin-right: 0;
      }
    }

    &__link {
      position: relative;
      margin: 0 auto;
      border: none;
      display: flex;
      justify-content: center;
      padding-top: clamp(0.375rem, 0.261rem + 0.57vw, 0.625rem);
      padding-bottom: clamp(0.375rem, 0.261rem + 0.57vw, 0.625rem);
      font-size: 20px;
      font-family: var(--font-family);
      text-decoration: none;
      white-space: nowrap;
      transition: 0.2s;
      cursor: pointer;

      @include for-desktop {
        padding-top: 8px;
        padding-bottom: 8px;
        font-weight: 400;
        font-size: 20px;
        color: var(--white);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0%;
        height: 2px;
        background: linear-gradient(91deg, var(--color-green) 0%, var(--yellow) 80%);
        transition: 0.2s;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: 2px solid var(--color-text-link);
        outline-offset: 3px;
        color: var(--yellow);
      }

      @include hover {
        color: var(--yellow);

        &::after {
          width: 100%;
        }
      }

      &._active::after {
        width: 100%;
      }

      &:active {
        color: var(--yellow);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 14px;
  }

  .burger {
    position: relative;
    @include burger;
    pointer-events: none;

    &__wrapper {
      position: relative;
      padding: clamp(6px, -0.902rem + 6.38vw, 12px) clamp(10px, -3.63rem + 21.28vw, 30px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-desktop {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: clamp(78px, 1.896rem + 14.89vw, 92px);
        height: clamp(36px, -0.729rem + 14.89vw, 50px);
        transform: translate(-50%, -50%);
        background: url(../img/buttons/burger-bg.svg) no-repeat center/contain;
        z-index: -1;
      }
    }


    @include for-desktop {
      display: none;
    }
  }

  &-btn {
    display: none;

    @include for-desktop {
      display: flex;
      width: 182px;
      height: 50px;
    }

    &:active {
      color: var(--color-green);
    }
  }
}

.faq--page .header__logo {
  width: 175px;
  left: 48%;

  @media screen and (max-width: 1024px) {
    left: 0;
  }

  @media screen and (min-width: 1024px) {
    width: clamp(100px, -26.188rem + 50.68vw, 174px);
    left: clamp(417px, -19.192rem + 70.9vw, 898px);
  }
}

.faq--page .header__logo,
.howtobuy--page .header__logo,
.referral--page .header__logo,
.win--page .header__logo {
  width: 110px;
  top: -5px;
  left: -10px;

  @media screen and (min-width: 414px) {
    top: 0;
    left: 0;
  }

  @media screen and (min-width: 1024px) {
    width: clamp(110px, 0.983rem + 9.21vw, 175px);
    left: clamp(445px, -7.088rem + 54.53vw, 830px);
  }

  @media screen and (min-width: 1730px) {
    left: 48vw;
  }

}

.faq--page .header .lian,
.howtobuy--page .header .lian,
.referral--page .header .lian,
.win--page .header .lian,
.faq--page .header .logo-desktop,
.howtobuy--page .header .logo-desktop,
.referral--page .header .logo-desktop,
.win--page .header .logo-desktop {
  display: none;
}

.faq--page .header .logo-mobile,
.howtobuy--page .header .logo-mobile,
.referral--page .header .logo-mobile,
.win--page .header .logo-mobile {
  display: block;
}

.profile--page {
  & .header {
    &__logo {
      position: relative;
      left: 0 !important;
      width: 175px !important;
      height: 50px !important;
      transform: none !important;

      & .logo-mobile {
        display: flex;
        position: absolute;
        top: -9px;
        left: 0 !important;
        transform: none !important;
      }
    }

    &-menu {
      justify-content: flex-end;
      column-gap: clamp(9px, -3.319rem + 6.07vw, 30px);

      &__item {
        margin: 0;
      }
    }

    & .user-mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & .user-info {
        display: flex;
        align-items: center;
        gap: 9px;

        &__avatar {
          width: 47px;
          height: 47px;
          flex-shrink: 0;
          border-radius: 50%;
          overflow: hidden;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      & .exit-btn {
        display: flex;

        & svg path {
          transition: 0.3s !important;
        }

        &:hover svg path {
          stroke: var(--color-green);
          transition: 0.2s !important;
        }
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    & .user-info {
      display: none;

      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        gap: 9px;

        &__avatar {
          width: 47px;
          height: 47px;
          flex-shrink: 0;
          border-radius: 50%;
          overflow: hidden;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    & .exit-btn {

      display: none;

      @media screen and (min-width: 1024px) {
        display: flex;

        & svg path {
          transition: 0.3s !important;
        }

        &:hover svg path {
          stroke: var(--color-green);
          transition: 0.2s !important;
        }
      }

    }
  }
}