.btn {
  position: relative;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
  color: var(--white);
  line-height: 1;
  transition: 0.3s !important;

  & .bg {
    position: absolute;
    inset: 0;
    top: -1px;
    width: 100%;
    z-index: 0;

    & path {
      transition: 0.3s !important;
    }
  }

  & span {
    position: relative;
    z-index: 1;
    // transition: 0.3s !important;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--color-green);
    outline-offset: 3px;
  }

  @include hover {
    color: var(--full-dark);
    transition: 0.2s !important;
  }

  &:active {
    color: var(--full-dark);
    transition: 0.2s !important;

    & .bg path {
      fill: var(--full-dark);
      stroke: var(--color-green);
      transition: 0.2s !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    color: var(--white);
    pointer-events: none;

    & .bg path {
      fill: var(--gray-60);
      stroke-width: 2px;
      stroke: var(--bg-light);
    }
  }

  &-lang {
    background-color: transparent;
    padding: 0 10px;

    & .flag__wrapper {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    & .arrow path {
      transition: 0.3s !important;
    }

    &:hover {
      .arrow path {
        stroke: var(--color-green);
        transition: 0.2s !important;
      }
    }
  }

  &-trans {
    display: flex;
    align-items: center;
    gap: 14px;
    color: var(--white);
    background-color: transparent;
    padding: 0;
    font-family: var(--font-family);
    font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
    pointer-events: all;
    transition: 0.3s !important;

    & .flag {
      font-size: 0;
      pointer-events: none;
    }

    & .lang {
      transition: 0.2s;
      pointer-events: none;
    }

    &:hover {
      transition: 0.2s !important;
    }

    &:hover .lang {
      color: var(--yellow);
      transition: 0.2s !important;
    }
  }

  &.primary-btn {
    width: 182px;
    height: 50px;
    max-height: 50px;

    & .bg,
    & svg {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      top: -1px;
      z-index: 0;
    }

    &:hover {
      transition: 0.2s !important;
    }

    &:active {
      color: var(--color-green);
    }
  }

  &.btn-win {
    width: 154px;
    height: 64px;
    padding-top: 17px;
    background: url(../img/hero/win.png) no-repeat center / contain;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
    color: var(--white);
    text-transform: uppercase;

    &:hover {
      color: var(--yellow);
      transition: 0.2s !important;
    }
  }

  &.btn-buy-pre {
    width: 181px;
    height: 64px;
    padding-top: 17px;
    background: url(../img/hero/buy.png) no-repeat center / contain;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(20px, 0.399rem + 4.26vw, 24px);
    color: var(--white);
    text-transform: capitalize;

    &:hover {
      transition: 0.2s !important;
    }
  }
}