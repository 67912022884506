*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:where(ul, ol):where([class]) {
  padding-left: 0;
}

body,
:where(blockquote, figure):where([class]) {
  margin: 0;
}

body {
  background: var(--bg);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  text-rendering: optimizeSpeed;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;


  &.disable-scroll {
    overflow: hidden !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
  }
}

.container {
  width: min(var(--content-width), 100% - var(--content-offset) * 2);
  margin-left: auto;
  margin-right: auto;
}

.profile--page .container {
  width: min(var(--content-width), 100% - 20px);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

section {
  position: relative;
  scroll-snap-align: start;
}

.section__title {
  --size-fz-section-title: clamp(32px, 1.476rem + 2.03vw, 54px);

  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--size-fz-section-title);
  color: var(--white);

  & span {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: var(--size-fz-section-title);
    background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.title-center {
    text-align: center;
  }
}

.profile--page .section__title {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 32px;
  background: linear-gradient(106deg, #f7ff49 0%, #ffba0a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__subtitle {
  margin-top: 10px;
  font-family: var(--second-family);
  font-weight: 500;
  font-size: clamp(16px, 0.921rem + 0.3vw, 20px);
  line-height: 160%;
  color: var(--white);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

.stage-title {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: url(../img/hero/tabs/title.svg) no-repeat center / contain;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  text-transform: capitalize;
}

p {
  text-wrap: pretty;
}

img,
picture,
video,
iframe {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
}

picture img,
picture source {
  position: absolute;
  display: block;
  inset: 0;
  max-width: 100%;
  object-fit: contain;
}

.img-wrapper {
  position: relative;
  shape-margin: 0.75rem;
  margin-right: 1rem;
  float: left;
}

.img-wrapper .img-alt {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: -1;
}

iframe {
  width: 100%;
  background: #0A0E14 !important;
  border-radius: 20px;
}

.icon {
  flex-shrink: 0;
  fill: currentColor;
  transition: 0.2s;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s !important;

  @include hover {
    transition: 0.2s !important;
  }
}

svg * {
  transition-property: fill, stroke;
}

.is-hidden {
  display: none !important;
}

*::selection,
*::-moz-selection,
*::-webkit-selection {
  color: var(--light-color);
  background-color: var(--accent-color);
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: transparent;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.dis-scroll {
  position: fixed;
  top: unset !important;
  overflow: hidden;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.no-mobile {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}