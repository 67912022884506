.partners {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  overflow: hidden;

  & .section__header {
    margin-bottom: clamp(11px, 0.062rem + 2.42vw, 30px);
  }

  &__body {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 30px;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__item {
    flex: 0 0 clamp(165px, 8.3rem + 6.91vw, 260px);
    width: clamp(165px, 8.3rem + 6.91vw, 260px);
    height: 81px;

    & a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;

      & .bg {
        position: absolute;
        inset: 0;
        z-index: 0;

        & svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(145px, 8.115rem + 3.66vw, 180px);
    height: 34px;
    z-index: 1;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__statistic {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    z-index: 1;

    & span {
      display: flex;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 12px;
      line-height: 167%;
      letter-spacing: -0.02em;
      color: var(--yellow);
    }

  }
}