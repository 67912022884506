.graph-modal {
  background: var(--bg, rgba(3, 7, 19, 0.95));
  overflow: hidden;

  &.is-open {
    display: flex;
  }

  &__container {
    background-color: transparent;

    &[data-modal-target] {
      width: 100%;
      max-width: 80%;
      height: 90%;
      padding: clamp(30px, 0.229rem + 6.36vw, 80px);

      .graph-modal__content {
        width: 100%;
        color: var(--white, #fff);
      }

      .graph-modal__title {
        color: var(--white, #fff);
        text-align: center;
        font-family: var(--font-family);
        font-size: clamp(30px, 1.217rem + 2.54vw, 50px);
        font-weight: 500;
        line-height: normal;

        & span {
          background: linear-gradient(91deg, var(--color-green) 0%, var(--yellow) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .graph-modal__close {
        color: var(--gray-gray_20, #d7d7e2);
        transition: 0.3s !important;

        &:hover {
          color: var(--yellow, #ffb800);
          transition: 0.2s !important;
        }
      }

      .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;

        .input__box {
          position: relative;
          width: 100%;

          & input {
            width: 100%;
            padding: 14px 20px;
            color: #222;
            font-family: var(--font-family);
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            border-radius: 10px;
            border: 1px solid var(--gray-1, #c1c6ca);

            &:focus+.form__label,
            &:valid+.form__label,
            &:invalid:not(:focus):placeholder-shown+.form__label {
              top: 0;
              left: 15px;
            }
          }

          .form__label {
            position: absolute;
            padding: 5px;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            color: #222;
            background-color: var(--light-color);
            font-family: var(--font-family);
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            pointer-events: none;
            transition: 0.3s;

            span {
              color: var(--accent-color);
              font-size: 16px;
            }
          }
        }

        &__btn {
          margin-top: 12px;
          padding: 14px 35px;
          align-self: center;
          border-radius: 10px;
          background: var(--accent-color);
        }
      }

      .list__wrapper {
        margin-top: 30px;
        display: grid;
        place-content: center;
      }

      .language__list {
        columns: 2;
        column-gap: 60px;

        @include for-desktop {
          columns: 3;
          column-gap: 80px;
        }
      }

      .language__item {
        margin-bottom: 15px;
      }

      &.thank-you .graph-modal__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }

      &.thank-you .graph-modal__title {
        margin-bottom: 0;
        max-width: 19ch;
      }

      &.thank-you .graph-modal__icon {
        width: 100px;
        height: 100px;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--accent-color);
        border-radius: 100px;
      }
    }

  }
}