.hero {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 660px;
  overflow: hidden;

  @include for-desktop {
    min-height: 870px;
    // max-height: 950px;
  }

  @media screen and (min-width: 1820px) {
    min-height: clamp(870px, 51.902rem + 2.17vw, 900px);
  }

  &::before {
    content: '';
    position: absolute;
    top: -31%;
    left: -21%;
    width: 69%;
    aspect-ratio: 78/47;
    background: url(../img/what/bg.svg) no-repeat center/contain;
    z-index: 3;
  }

  &__bg {
    position: relative;
    flex-shrink: 0;
    height: clamp(500px, 133.33vw, 800px);
    pointer-events: none;

    @include for-desktop {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }

    & .mobile__actions {
      margin-top: clamp(350px, 11.161rem + 53.57vw, 500px);
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      pointer-events: all;
      z-index: 12;

      & .btn-win {
        display: flex;
        top: 0;
        left: 0;
        width: 154px;
        background: url(../img/buttons/primary.png) no-repeat center/contain;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--white);
      }

      & .primary-btn {
        width: 100%;
        max-width: 330px;
        max-height: 60px;
        margin: 0 auto;
        white-space: nowrap;
      }

      @media screen and (min-width:600px) {
        display: none;
      }
    }

    &::before,
    &::after {

      @media screen and (min-width:600px) {
        content: '';
        position: absolute;
        bottom: -130px;
        width: 260px;
        height: 150px;
        background: var(--bg);
        z-index: 10;
      }

      @media screen and (min-width:1200px) {
        bottom: -70px;
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    & .layer {
      position: absolute;
      background-repeat: no-repeat;
      pointer-events: none;
      transition: var(--transition);
      will-change: transform;
    }

    & .bg {
      top: 0;
      right: 0;
      width: 1130px;
      height: 610px;
      // aspect-ratio: 113/61;
      background-position: right top;
      background-size: contain;
      z-index: -1;
    }

    & .icicles {
      display: none;

      @media screen and (min-width:600px) {
        display: block;
        position: absolute;
        width: 697px;
        height: 199px;
        top: 0;
        left: -17vw;
        z-index: 12;
      }

      @media screen and (min-width:992px) {
        left: -3vw;
      }

      @media screen and (min-width:1728px) {
        left: calc((100vw - 1728px) / 2 + -22px);
      }
    }

    & .fon-mobil {
      display: none;
      z-index: 1;

      @media screen and (min-width:600px) {
        display: none;
      }
    }

    & .fon {
      display: none;

      @media screen and (min-width:600px) {
        --offset-right: clamp(0px, -37.103rem + 40.38vw, 105px);

        display: block;
        top: 8px;
        right: calc(100vw - clamp(786px, 29.621rem + 52.01vw, 1006px));
        width: 896px;
        height: auto;
        aspect-ratio: 90/69;
        background-position: right top;
        background-size: contain;
        // transform: translate3d(0, calc(var(--scrollTop) / 9), 0);
        z-index: 0;
      }

      @include for-desktop {
        right: calc(100vw - clamp(1224px, 41.917rem + 54.04vw, 1465px));
      }

      @media screen and (min-width:1470px) {
        right: clamp(0px, -37.103rem + 40.38vw, 105px);
      }

      @media screen and (min-width:1760px) {
        margin-right: calc((100vw - 1728px) / 2);
      }
    }

    & .fon-mini {
      top: 98px;
      left: 0;
      width: 100%;
      min-width: 480px;
      height: clamp(418px, 14.2rem + 58.57vw, 644px);
      z-index: 1;
      background-position: left;
      background-size: contain;

      @media screen and (min-width:600px) {
        display: none;
      }
    }

    & .finger {
      position: absolute;
      width: 172px;
      height: 172px;
      top: 244px;
      right: 233px;
      background-position: center;
      background-size: contain;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-3;
      animation-timing-function: cubic-bezier(.075, .5, 0, 1);
    }

    & .btn-win {
      top: 414px;
      left: 445px;
      width: 203px;
      height: 80px;
      padding-top: 17px;
      background: url(../img/hero/win.png) no-repeat center/contain;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 32px;
      color: var(--white);
      text-transform: uppercase;
      pointer-events: all;
      z-index: 12;
    }

    & .grass {
      display: none;

      @media screen and (min-width: 600px) {
        display: block;
        width: clamp(111px, 4.865rem + 8.01vw, 214px);
        height: auto;
        aspect-ratio: 21/22;
        bottom: 152px;
        left: calc(5vw + clamp(0rem, -15.301rem + 40.8vw, 173px));
        // transform: translate3d(0, calc(var(--scrollTop) / 4), 0);
        z-index: 2;
      }

      @include for-desktop {
        left: calc(40vw + clamp(20px, -4.926rem + 9.65vw, 230px));
        bottom: clamp(187px, 8.737rem + 2.27vw, 179px);
      }
    }

    & .leaf {
      display: none;

      @media screen and (min-width: 600px) {
        display: block;
        width: clamp(199px, 8.776rem + 14.15vw, 381px);
        height: auto;
        aspect-ratio: 38/17;
        bottom: clamp(151px, 6.961rem + 6.6vw, 179px);
        left: calc(50% + 20px);
        // transform: translate3d(0, calc(var(--scrollTop) / 7), 0);
        z-index: 3;
      }

      @include for-desktop {
        bottom: clamp(179px, 6.746rem + 6.94vw, 228px);
        left: calc(50% + clamp(175px, 6.379rem + 7.12vw, 330px));
      }
    }

    & .black {
      bottom: calc(var(--index-wh) * 2.8);
      left: 50%;
      width: 100%;
      height: 450px;
      transform: translateX(-50%);
      background-position: center bottom;
      background-size: cover;
      min-width: 1600px;

      z-index: 8;
    }

    & .ahead {
      bottom: calc(100% - 594px);
      left: 30%;
      width: 100%;
      aspect-ratio: 179/44;
      transform: translateX(-50%);
      background-position: center bottom;
      background-size: contain;
      min-width: clamp(788px, -48.875rem + 418.67vw, 1730px);
      z-index: 10;

      @media screen and (min-width: 375px) {
        bottom: calc(100% - clamp(594px, 2.125rem + 149.33vw, 930px));
      }


      @media screen and (min-width: 600px) {
        left: 50%;
        bottom: -92px;
      }

      @include for-desktop {
        bottom: -39px;
      }

      @media screen and (min-width: 1730px) {
        bottom: calc(100% - clamp(910px, 32.949rem + 22.13vw, 1153px));
      }
    }
  }

  &-content {
    padding-top: 45px;
    position: relative;
    display: flex;
    justify-content: center;
    pointer-events: none;
    z-index: 11;

    @media screen and (min-width:600px) {
      padding-top: 0;
    }

    @include for-desktop {
      padding-top: 77px;
      padding-left: calc(100% - clamp(1020px, 15.893rem + 76.78vw, 1685px));
      justify-content: flex-start;
    }
  }

  &-tabs {
    width: clamp(320px, 4rem + 80vw, 380px);
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;

  }


}