.business-model {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  z-index: 10;

  &__body {
    width: min(var(--content-width), 100% - 4px);
  }

  & .section__header {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    transition: var(--transition);
    // will-change: transform;

    @include for-desktop {
      // transform: translate3d(0, calc(var(--scrollTop) / -10), 0);
    }

    @media screen and (min-width: 1555px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(293px, 1fr));
    justify-items: center;
    gap: 30px 15px;
    transition: var(--transition);
    // will-change: transform;

    @include for-desktop {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      // transform: translate3d(0, calc(var(--scrollTop) / -20), 0);
    }
  }

  &.notitle {
    // padding-top: 0;
  }

  &.notitle .business-model__list {
    grid-template-columns: repeat(auto-fill, minmax(293px, 1fr));
    gap: 12px 6px;

    transition: var(--transition);
    // will-change: transform;

    @include for-desktop {
      transform: none
    }
  }

  .item {
    position: relative;
    padding-top: 11px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
    width: 100%;
    aspect-ratio: 37/26;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/business-model/bg.svg);
    z-index: 0;

    &.element-animation {
      opacity: 0;
      bottom: -130px;
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        bottom: 0;
        animation: scroll-up cubic-bezier(.075, .5, 0, 1);
      }

      &:nth-child(2).element-show {
        transition: all 0.5s cubic-bezier(.075, .5, 0, 1) 0.25s;
      }

      &:nth-child(3).element-show {
        transition: all 0.5s cubic-bezier(.075, .5, 0, 1) 0.5s;
      }

      &:nth-child(4).element-show {
        transition: all 0.5s cubic-bezier(.075, .5, 0, 1) 0.75s;
      }

      &:nth-child(5).element-show {
        transition: all 0.5s cubic-bezier(.075, .5, 0, 1) 1s;
      }
    }

    &__wrapper {
      position: relative;
      padding: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      text-align: center;
      background: var(--bg);
      border-radius: 20px;
      overflow: hidden;
      z-index: 1;
    }

    &__image {
      width: clamp(57px, 2.312rem + 4.83vw, 95px);
      height: clamp(60px, 3.092rem + 2.54vw, 80px);
      flex-shrink: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__title {
      position: relative;
      margin-bottom: 9px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 24px;
      color: var(--white);
      z-index: 2;
    }

    &__desc {
      position: relative;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: var(--yellow);
      z-index: 2;
    }

    & .btn {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  &.notitle .item {
    padding: 0;
    display: flex;
    aspect-ratio: 30/26;
    background-image: url(../img/business-model/bg.png);

    @media screen and (max-width: 596px) {
      // padding-top: 15px;
      // padding-right: clamp(13px, -0.534rem + 5.8vw, 26px);
      // padding-left: clamp(13px, -0.534rem + 5.8vw, 26px);
      // padding-bottom: 16px;
    }

    & .item__wrapper {
      margin: auto;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
    }
  }
}