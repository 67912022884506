/* stylelint-disable */

@mixin burger {
  --burger-width: 30px;
  --burger-height: 23px;
  --burger-line-height: 3px;

  position: relative;
  border: none;
  padding: 0;
  width: var(--burger-width);
  height: var(--burger-height);
  color: var(--text-color);
  background-color: transparent;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  }

  &::before {
    top: 0;
  }

  &::after {
    top: calc(100% - var(--burger-line-height));
  }

  &__line {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: var(--burger-line-height);
    background-color: currentColor;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
  }

  &--active {
    &::before {
      top: 50%;
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    &::after {
      top: 50%;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    .burger__line {
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }
}