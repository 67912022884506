.footer {
  position: relative;
  width: 100%;
  min-height: 570px;
  padding: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  // z-index: 0;

  & .bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1800px;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;

    & .layer {
      position: absolute;
      z-index: 1;
    }

    & .fon {
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    & .snake {
      display: none;

      @media screen and (min-width: 1350px) {
        display: block;
        width: 300px;
        height: 313px;
        bottom: 117px;
        left: 220px;
      }
    }

    & .top-jungle {
      left: 50%;
      top: -203px;
      height: 500px;
      width: 100%;
      transform: translateX(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
    }

    & .left {
      left: calc(100% - clamp(1250px, 20.575rem + 92.82vw, 2000px));
      bottom: -15px;
      width: 875px;
      height: 809px;

      @media screen and (max-width:992px) {
        display: none;
      }
    }

    & .right {
      right: calc(100% - clamp(1320px, 30.322rem + 84.16vw, 2000px));
      bottom: 88px;
      width: 712px;
      height: 658px;

      @media screen and (max-width:992px) {
        display: none;
      }
    }

    & .grass {
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 255px;
      transform: translateX(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;
    }

    & .group-left {
      left: -2px;
      bottom: 150px;
      width: 155px;
      height: 243px;
      z-index: 2;

      @media screen and (max-width:1200px) {
        display: none;
      }
    }

    & .group-right {
      right: 0;
      bottom: 20px;
      width: 244px;
      height: 143px;
      z-index: 2;

      @media screen and (max-width:1200px) {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    aspect-ratio: 152/56;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__body {
    position: relative;
    padding-bottom: 20px;
    width: 100%;
    flex: auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    z-index: 10;

    @include for-tablet {
      padding-top: 20px;
      grid-template-columns: 1fr 1fr;
      transform: translate3d(0, calc(var(--scrollTop) / 220), 0);
    }

    @media screen and (min-width: 1200px) {
      padding-top: 0;
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &__logo {
    padding-top: 5px;
    align-self: flex-end;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 167%;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--white);
    z-index: 3;

    @include for-tablet {
      align-self: center;
    }

    @media screen and (min-width: 1200px) {
      grid-column: 2;
    }

    & img {
      position: relative;
      display: block;
    }
  }

  &__block {
    grid-column: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    column-gap: clamp(25px, 0.053rem + 5.83vw, 100px);

    @include for-tablet {
      grid-column: 2;
      justify-content: center;
    }

    @media screen and (min-width: 1200px) {
      grid-column: 3;
    }
  }

  &-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 11px;
    z-index: 3;

    &__link {
      padding-top: 3px;
      padding-bottom: 3px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: clamp(16px, 0.92rem + 0.31vw, 1.20px);
      color: var(--white);
      transition: 0.3s !important;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: 2px solid var(--color-green);
        outline-offset: 3px;
      }

      @include hover {
        color: var(--yellow) !important;
        text-decoration: underline;
        transition: 0.2s !important;
      }

      &:active {
        color: var(--yellow);
        transition: 0.2s !important;
      }

    }
  }

  &__bottom {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 5;

    & p {
      max-width: 90ch;
      padding-bottom: 20px;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 12px;
      line-height: 167%;
      letter-spacing: -0.02em;
      text-align: center;
      color: var(--gray-30);
    }
  }
}

.profile--page .footer {
  min-height: 100px;
}

.profile--page .footer__container {
  aspect-ratio: initial;
}

.profile--page .footer__body {
  padding-top: clamp(30px, 1.271rem + 2.33vw, 60px);
  height: auto !important;
  grid-template-columns: 1fr;
  align-items: center;
  row-gap: 20px;

  @include for-tablet {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: auto 1fr 1fr;
  }
}

.profile--page .footer__logo {
  grid-column: 1;
}

.profile--page .footer-menu {
  row-gap: 20px;
}

.profile--page .footer__bottom {
  justify-content: flex-start;

  & p {
    text-align: left;
  }
}

// .howtobuy--page .bg .fon {
//   bottom: -225px;


//   @media screen and (min-width: 375px) {
//     bottom: -230px;
//   }

//   @media screen and (min-width: 530px) {
//     bottom: -250px;
//   }

//   @media screen and (min-width: 992px) {
//     bottom: -462px;
//   }

//   @media screen and (min-width: 1024px) {
//     bottom: -360px;
//   }

//   @media screen and (min-width: 1350px) {
//     bottom: -330px;
//   }

//   @media screen and (min-width: 1700px) {
//     bottom: -320px;
//   }
// }

// .faq--page .bg .fon {
//   bottom: -415px;


//   @media screen and (min-width: 375px) {
//     bottom: -330px;
//   }

//   @media screen and (min-width: 530px) {
//     bottom: -250px;
//   }

//   @media screen and (min-width: 992px) {
//     bottom: -322px;
//   }

//   @media screen and (min-width: 1024px) {
//     bottom: -170px;
//   }

//   @media screen and (min-width: 1350px) {
//     bottom: -140px;
//   }

//   @media screen and (min-width: 1700px) {
//     bottom: -135px;
//   }
// }

// .win--page .bg .fon {
//   bottom: -670px;


//   @media screen and (min-width: 375px) {
//     bottom: -530px;
//   }

//   @media screen and (min-width: 530px) {
//     bottom: -520px;
//   }

//   @media screen and (min-width: 992px) {
//     bottom: -422px;
//   }

//   @media screen and (min-width: 1024px) {
//     bottom: -440px;
//   }

//   @media screen and (min-width: 1350px) {
//     bottom: -420px;
//   }

//   @media screen and (min-width: 1530px) {
//     bottom: -275px;
//   }

//   @media screen and (min-width: 1700px) {
//     bottom: -235px;
//   }
// }

// .referral--page .bg .fon {
//   bottom: -540px;


//   @media screen and (min-width: 375px) {
//     bottom: -525px;
//   }

//   @media screen and (min-width: 530px) {
//     bottom: -465px;
//   }

//   @media screen and (min-width: 992px) {
//     bottom: -422px;
//   }

//   @media screen and (min-width: 1024px) {
//     bottom: -495px;
//   }

//   @media screen and (min-width: 1350px) {
//     bottom: -490px;
//   }

//   @media screen and (min-width: 1530px) {
//     bottom: -460px;
//   }

//   @media screen and (min-width: 1700px) {
//     bottom: -455px;
//   }
// }