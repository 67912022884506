.profile-info {
  background: var(--bg);

  .section__header {
    margin-bottom: 15px;
  }

  &__wrapper {
    padding: clamp(0px, -1.456rem + 4.11vw, 26px) clamp(15px, -0.294rem + 3.48vw, 37px) clamp(15px, -0.462rem + 3.95vw, 40px) clamp(5px, -0.079rem + 1.11vw, 12px);
    border: 1px solid var(--gray-90);
    border-radius: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background: var(--full-dark);
    column-gap: clamp(25px, -1.489rem + 4.92vw, 50px);
    row-gap: 20px;

    @media screen and (min-width:768px) {
      grid-template-columns: 384px 1fr 194px;
      gap: 50px;
    }
  }

  &__card {
    grid-column: 1;
    grid-row: 1;
    justify-self: center;

    @media screen and (min-width:768px) {
      grid-column: 1/4;
    }

    @media screen and (min-width:992px) {
      grid-column: 1;
      grid-row: 1/3;
    }
  }

  &__balance:has(.balance__title) {
    grid-column: 1;
    grid-row: 2;
    justify-self: center;

    @media screen and (min-width:768px) {
      grid-column: 1/4;
    }

    @media screen and (min-width:992px) {
      grid-column: 2/4;
      grid-row: 1;
    }

    @media screen and (min-width:1300px) {
      grid-column: 2;
      grid-row: 1;
      justify-self: auto;
    }
  }

  &__balance:not(:has(.balance__title)) {
    grid-column: 1;
    grid-row: 4;
    justify-self: center;

    @media screen and (min-width:768px) {
      grid-column: 1/4;
    }

    @media screen and (min-width:992px) {
      grid-column: 2/4;
      grid-row: 2;
    }

    @media screen and (min-width:1300px) {
      grid-column: 2;
      grid-row: 2;
      justify-self: auto;
    }
  }

  &__spent {
    grid-column: 1;
    grid-row: 3;
    justify-self: center;

    @media screen and (min-width:768px) {
      grid-column: 1/4;
    }

    @media screen and (min-width:992px) {
      grid-column: 3;
      grid-row: 3;
    }

    @media screen and (min-width:1300px) {
      grid-column: 3;
      grid-row: 1/3;
    }
  }

  &__entries {
    grid-column: 1;
    grid-row: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(16px, 0.552rem + 1.26vw, 24px);
    color: var(--white);

    @media screen and (min-width:768px) {
      grid-column: 1/4;
      flex-direction: row;
      column-gap: 20px;
    }

    @media screen and (min-width:992px) {
      grid-column: 1/3;
      grid-row: 3;
    }

    @media screen and (min-width:1300px) {
      grid-column: 2/4;
      grid-row: 3;
    }

    .entries__counter {
      width: 125px;
      height: 75px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--gray-30);
      border-radius: 20px 4px;
      background: var(--bg-light);

      & span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 32px;
        color: var(--yellow);
      }
    }
  }
}

.profile-card {
  padding: clamp(12px, -6.708rem + 35.56vw, 26px) clamp(18px, -0.79rem + 11.11vw, 30px);
  width: 100%;
  max-width: 375px;
  aspect-ratio: 375/352;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../img/profile/info.svg) no-repeat center/contain;

  &__image {
    --size-image-card: clamp(80px, -8.75rem + 66.67vw, 110px);

    width: var(--size-image-card);
    height: var(--size-image-card);
    border-radius: var(--size-image-card);
    flex-shrink: 0;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__token {
    position: relative;
    margin-top: clamp(17px, 0.174rem + 4.44vw, 21px);
    margin-bottom: clamp(20px, -0.972rem + 11.11vw, 30px);
    padding: 7px 11px 7px 7px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 14px;
    border-radius: 10px;
    height: 42px;
    background: var(--gray-90);

    input {
      min-width: 163px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--white);
      background-color: transparent;
    }

    & .btn {
      margin-left: auto;
      padding: 0;

      & svg path {
        transition: 0.2s !important;
      }

      @include hover {
        & svg path {
          stroke: var(--color-green);
          transition: 0.2s !important;
        }
      }

      &:active {
        color: var(--full-dark);
      }
    }
  }

  & .primary-btn {
    margin-bottom: clamp(17px, -0.715rem + 8.89vw, 25px);
    width: clamp(220px, -5.958rem + 95.56vw, 263px);
    min-height: 60px;

    &:active {
      color: var(--full-dark);
    }
  }

  & p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(14px, -0.042rem + 4.44vw, 16px);
    color: var(--gray-90);
  }
}

.balance {

  &__title {
    margin-bottom: 20px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 32px;
    color: var(--white);
  }

  & .forms__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 19px;

    & .field-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      @media screen and (min-width:768px) {
        flex-direction: row;
        column-gap: 20px;
      }
    }

    & .input-box {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    & input {
      width: 100%;
      padding: 11px 15px 11px 55px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 24px;
      color: var(--color-dark-red);
      border-radius: 6px;
      height: 51px;
      background: var(--white);

      &::placeholder {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--color-dark-red);
      }
    }

    & .icon {
      position: absolute;
      bottom: 5px;
      left: 7px;
      width: 40px;
      height: 41px;
      z-index: 1;
    }

    & .actions {
      display: flex;
      align-items: center;
      column-gap: clamp(9px, -2.708rem + 16.67vw, 25px);

      & .btn {
        &:active {
          color: var(--full-dark);
        }
      }

      & .btn:first-of-type {
        width: clamp(165px, 2.979rem + 35.56vw, 180px);
      }

      & .btn:last-of-type {
        width: clamp(113px, 4.2rem + 10.91vw, 140px);
      }
    }
  }
}

.spent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;

  @media screen and (min-width:1300px) {
    padding-top: 50px;

  }

  &-item {
    min-width: 194px;
    padding: 8px 7px;
    width: 100%;
    aspect-ratio: 194/145;
    background: url(../img/profile/spent-bg.svg) no-repeat center/contain;

    &__wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 20px;
      background: var(--bg);
    }

    &__icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    &__count {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 32px;
      line-height: 1;
      color: var(--color-dark-red);
    }

    &__desc {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      color: var(--gray-30);
    }
  }
}