@keyframes bounce-3 {
  0% {
    transform: translateY(0) scale(1);
  }

  30% {
    transform: translateY(-30px) scale(0.9);
  }

  50% {
    transform: translateY(0) scale(1);
  }

  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes tabs__down {
  from {
    height: 0;
    transform: scale(1, 0);
    opacity: 0;
    z-index: 0;
  }

  10% {
    opacity: 0.5;
  }

  to {
    height: 100%;
    transform: scale(1, 1);
    opacity: 1;
    z-index: 1;
  }
}

@keyframes tabs__up {
  from {
    transform: scale(1, 1);
    opacity: 1;
    height: 100%;
    z-index: 1;
  }

  10% {
    opacity: 0.5;
  }

  to {
    transform: scale(1, 0);
    opacity: 0;
    height: 0;
    z-index: 0;
  }
}

@keyframes stone-left {
  from {
    opacity: 0;
    left: -120px;
    bottom: -50px;
  }

  30% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
    bottom: 146px;
    left: 222px;
  }
}

@keyframes stone-right {
  from {
    opacity: 0;
    bottom: -20px;
    right: -10px;
  }

  35% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
    bottom: 156px;
    right: 203px;
  }
}

@keyframes rings-1-up {
  from {
    opacity: 0;
    bottom: 157px;
  }

  50% {
    opacity: 60%;
  }

  to {
    opacity: 1;
    bottom: 215px;
  }
}

@keyframes rings-2-up {
  from {
    opacity: 0;
    bottom: 10px;
  }

  50% {
    opacity: 60%;
  }

  to {
    opacity: 1;
    bottom: 157px;
  }
}

@keyframes rings-3-up {
  from {
    opacity: 0;
    bottom: -160px;
  }

  50% {
    opacity: 60%;
  }

  to {
    opacity: 1;
    bottom: 10px;
  }
}

@keyframes scroll-up {
  from {
    opacity: 0;
    bottom: -130px;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  35% {
    opacity: .3;
  }

  55% {
    opacity: .6;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  35% {
    opacity: .3;
  }

  55% {
    opacity: .6;
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}