.howtobuy {
  overflow: hidden;

  & .section__header {
    margin-bottom: 35px;
  }

  &__wrapper {
    transition: all 0.3s ease-out;
  }

  &-link {
    position: relative;
    max-width: 890px;
    z-index: 5;

    &__list {
      margin-bottom: clamp(25px, 0.74rem + 3.18vw, 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: clamp(25px, -0.413rem + 7.63vw, 85px);
      row-gap: 35px;

      @media screen and (min-width: 992px) {
        justify-content: flex-start;
      }
    }

    &__item {
      & a {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--white);
        transition: 0.2s;
        border-bottom: 1px dashed var(--white);

        @include hover {
          color: var(--color-yellow-light);
          border-bottom: 1px solid var(--color-yellow-light);
          transition: 0.2s;
        }
      }
    }

    .install {
      margin-bottom: clamp(45px, 2.319rem + 1.91vw, 60px);
      display: grid;
      grid-template-columns: clamp(45px, 1.66rem + 4.45vw, 80px) 1fr;
      column-gap: 24px;
      row-gap: 20px;

      &__img {
        grid-column: 1;
        grid-row: 1/3;

        & img {
          width: 100%;
          height: auto;
          aspect-ratio: 8/7;
          object-fit: contain;
        }
      }

      &__title {
        margin-bottom: 3px;
        grid-column: 2;
        grid-row: 1;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 32px;
        color: var(--yellow);
      }

      &__description {
        grid-column: 1/3;
        grid-row: 2;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 16px;
        line-height: 137%;
        color: var(--white);

        @include for-tablet {
          grid-column: 2;
        }

        & a {
          color: var(--color-text-link);
          transition: 0.2s;

          @include hover {
            color: var(--color-yellow-light);
            text-decoration: underline;
            transition: 0.2s;
          }
        }
      }
    }
  }

  &-buywith {
    position: relative;
    padding-bottom: clamp(25px, 0.74rem + 3.18vw, 50px);
    max-width: 890px;
    transition: all 0.3s ease-out;
    z-index: 5;

    @media screen and (max-width:992px) {
      background: var(--bg);
    }

    &__title {
      padding-bottom: 25px;
      // height: 45px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 32px;
      color: var(--yellow);

      @media screen and (max-width:992px) {
        background: var(--bg);
      }

      & .icon {
        width: 45px;
        height: 45px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-out;
      }

      & .accordion__icon {
        margin-left: auto;
        transition: all 0.3s ease-out;

        & svg path {
          transform-origin: center;
          transition: all 0.3s ease-out;
        }

        & svg path .fon {
          transition: all 0.3s ease-out;
        }

        & svg path .arrow {
          transition: all 0.3s ease-out;
          transform-origin: center;
        }

        @media screen and (min-width: 992px) {
          display: none;
        }
      }
    }

    &__list {
      transition: all 1s ease-out;

      // @media screen and (min-width: 992px) {
      //   background: var(--bg);
      // }

      &.accordion__content {
        max-height: 0;
        overflow: hidden;
        will-change: max-height;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

        @media screen and (min-width: 992px) {
          height: auto;
          overflow: visible;
          max-height: none;
        }
      }
    }

    &.accordion__item {
      transition: all 0.6s ease-out;

      & .accordion__control {
        @media screen and (max-width: 992px) {
          position: relative;
          background: var(--bg);
          z-index: 1;
        }
      }

      &.open .accordion__icon svg .fon {
        fill: var(--yellow);
        transition: all 0.3s ease-out;
      }

      &.open .accordion__icon svg .arrow {
        stroke: black;
        transition: all 0.3s ease-out;
        transform-origin: center;
        transform: rotate(180deg);
      }

      &.open .accordion__content {

        @media screen and (max-width: 992px) {
          position: relative;
          height: 100%;
          overflow: visible;
          transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
          z-index: 0;
        }
      }

    }

    &__item {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      column-gap: 13px;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 137%;
      color: var(--white);
      transition: all 0.3s ease-out;

      &:not(:has(img))::before,
      &:not(:has(.no-star))::before {
        content: '';
        padding-top: clamp(0px, -0.227rem + 1.14vw, 8px);
        display: block;
        width: 21px;
        height: 21px;
        flex-shrink: 0;
        background: url(../img/star.svg) no-repeat center / contain;
        transition: all 0.3s ease-out;
      }

      &.image-item,
      &:has(img) {
        justify-content: center;

        &::before {
          display: none;
        }

        & img {
          flex-shrink: 0;
          transition: all 0.3s ease-out;
        }
      }

      &.no-star {
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 16px;
        line-height: 137%;
        color: var(--gray-30);
        transition: all 0.3s ease-out;

        &::before {
          display: none;
        }
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    min-height: 820px;
    z-index: 0;

    & img {
      position: absolute;
      will-change: transform;
    }

    &::after {
      content: '';
      position: absolute;
      width: 750px;
      height: 474px;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      background: url(../img/ellipse-2.svg) no-repeat center/contain;
      z-index: -1;
    }

    @include for-desktop {
      position: absolute;
      width: 900px;
      top: 30px;
      right: -140px;
      z-index: 0;

      &::after {
        left: 50%;
      }
    }

    & .confident {
      top: 0;
      left: 50%;
      min-width: 410px;

      transform: translateX(-50%);
      width: 410px;
      height: 580;
      object-fit: contain;
      z-index: 3;

      @media screen and (min-width:1024px) {
        left: 50%;
        right: 0;
        transform: translateX(-50%);
      }
    }

    & .group {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 525px;
      height: 525px;

      @media screen and (min-width:1024px) {
        left: 50%;
      }
    }

    & .light {
      top: 28px;
      right: 84px;
    }

    & .stone {
      left: 52%;
      transform: translateX(-50%);
      top: 473px;
    }

    & .rings {
      position: absolute;
      will-change: transform;
      bottom: 0;
      left: 52%;
      width: 347px;
      height: 287px;
      display: flex;
      justify-content: center;
      transform: translateX(-50%);
      transition: var(--transition);
      z-index: -1;

      @media screen and (max-width:1024px) {
        background: url(../img/ecosystem/rings.png) no-repeat center/contain;
      }

      & img {
        position: absolute;
      }

      & .rings-1 {
        bottom: 157px;
        opacity: 0;
        width: 314px;
        height: 73px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 1s;
          animation: rings-1-up 0.7s ease-in-out;
          bottom: 215px;
          opacity: 1;
          // }
        }
      }

      & .rings-2 {
        bottom: 10px;
        opacity: 0;
        width: 347px;
        height: 107px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 2s;
          animation: rings-2-up 0.7s ease-in-out;
          bottom: 157px;
          opacity: 1;
          // }
        }
      }

      & .rings-3 {
        bottom: -160px;
        opacity: 0;
        width: 258px;
        height: 152px;

        &.element-show {
          // @media screen and (min-width:1024px) {
          animation-delay: 3s;
          animation: rings-3-up 0.7s ease-in-out;
          bottom: 10px;
          opacity: 1;
          // }
        }
      }
    }

    & .stone-1 {
      bottom: 146px;
      left: 10vw;
      transition: var(--transition);

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        left: -120px;
        bottom: -30px;
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-left 1.2s cubic-bezier(.075, .5, 0, 1);
          bottom: 146px;
          left: 222px;
          opacity: 1;
        }
      }
    }

    & .stone-2 {
      bottom: 156px;
      right: 10vw;

      @media screen and (min-width:567px) {
        right: 15vw;
      }

      @media screen and (min-width:768px) {
        opacity: 0;
        bottom: -20px;
        right: -10px;
        transition: var(--transition);
      }

      &.element-show {
        @media screen and (min-width:768px) {
          animation: stone-right 1.45s cubic-bezier(.075, .5, 0, 1);
          bottom: 156px;
          right: 203px;
          opacity: 1;
        }
      }
    }
  }
}