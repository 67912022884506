.pagination {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    position: relative;
    width: 52px;
    height: 64px;

    & a {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        position: absolute;
        inset: 0;
        z-index: 0;

        & path {
          fill: var(--full-dark);
          stroke: var(--full-dark);
          transition: 0.3s !important;
        }
      }

      & span {
        position: relative;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 24px;
        color: var(--white);
        transition: 0.3s !important;
        z-index: 1;
      }

      @include hover {

        span {
          color: var(--full-dark);
          transition: 0.2s !important;
        }

        svg path {
          fill: #ffb800;
          stroke: #ffb800;
          transition: 0.2s !important;
        }
      }
    }
  }

  &__arrow {

    & svg path {
      transition: 0.3s !important;
    }

    &:hover .fon {
      fill: var(--yellow);
      transition: 0.2s !important;
    }

    &:hover .arrow {
      stroke: black;
      transition: 0.2s !important;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}