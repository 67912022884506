.referral {
  position: relative;
  padding-top: clamp(20px, 0.659rem + 2.28vw, 50px);
  padding-bottom: clamp(20px, 0.659rem + 2.28vw, 50px);
  background: var(--bg);
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media screen and (min-width: 1800px) {
    aspect-ratio: 184/110;
  }

  & .section__header {
    position: relative;
    margin-bottom: 24px;
    z-index: 5;
  }

  & .section__subtitle {
    margin-left: auto;
    margin-right: auto;
    max-width: 105ch;
    text-align: center;
  }

  & .bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    & .layer {
      position: absolute;
      z-index: 1;
    }

    & .fon {
      inset: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center;
    }

    & .top-jungle {
      top: -185px;
      left: 0;
      width: 100%;
      height: 450px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 2;

      @media screen and (min-width: 1800px) {
        height: auto;
        aspect-ratio: 178/45;
      }
    }

    & .ahead {
      bottom: -92px;
      left: 0;
      height: 323px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 5;

      @media screen and (min-width: 1800px) {
        height: auto;
        aspect-ratio: 172/30;
      }
    }
  }

  &__wrapper {
    position: relative;
    display: grid;
    grid-template: 1fr;

    @include for-desktop {
      grid-template-columns: 0.7fr 1fr;
    }
  }

  .card__wrapper {
    grid-column: 1;
    grid-row: 1;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-left cubic-bezier(.075, .5, 0, 1) .7s;
      }
    }
  }

  &__image {
    position: relative;
    grid-column: 1;
    grid-row: 3;
    min-height: 350px;

    &.element-animation {
      opacity: 0;
      transform: translateX(-100%);
      transition: var(--transition);

      &.element-show {
        opacity: 1;
        transform: translateX(0);
        animation: fade-right cubic-bezier(.075, .5, 0, 1) 1s;
      }
    }

    @include for-desktop {
      grid-column: 2;
      grid-row: 1;
    }

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 110%;
      aspect-ratio: 77/63;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }

  &__actions {
    width: 100%;
    margin-top: 16px;
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    @include for-desktop {
      grid-column: 1/3;
      grid-row: 2;
    }

    & .btn {
      width: 100%;
      text-align: center;
      height: 70px;
      max-height: none;

      &:active {
        color: var(--full-dark);
      }

      @media screen and (min-width: 510px) {
        width: 411px;
        height: 60px;
      }
    }

    & p {
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 137%;
      color: var(--yellow);
    }
  }

  .card__wrapper {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 398px;
      top: 0;
      width: 130px;
      height: 255px;
      rotate: -3deg;
      background: url(../img/hero/lian.png) no-repeat center/contain;
      z-index: 0;
    }
  }

  & .bonus-card {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 471px;
    position: relative;
    padding: clamp(25px, 0.753rem + 4.05vw, 35px) clamp(18px, 0.2rem + 4.45vw, 46px);
    display: flex;
    flex-direction: column;
    aspect-ratio: 47/51;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;

    @media screen and (max-width: 510px) {
      padding-bottom: 40px;
      aspect-ratio: unset;
    }

    @include for-desktop {
      margin-left: auto;
      margin-right: 0;
    }

    &.lazy-background.visible {
      background-image: url(../img/referral/bonus-card.svg);

      @media screen and (max-width: 1180px) {
        background-image: url(../img/referral/card-mobil.svg);
      }
    }

    &__wrapper {
      position: relative;
    }

    &__header {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: var(--gray-90);
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__item {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(20px, 0.926rem + 1.62vw, 24px);
        color: var(--full-dark);

        &:last-child {
          border-radius: 20px 4px;
          width: 90px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: var(--font-family);
          font-weight: 400;
          font-size: clamp(20px, 0.926rem + 1.62vw, 24px);
          color: var(--yellow);
          background: var(--bg-light);
        }
      }

      &:not(:first-of-type) {
        background-image: linear-gradient(to right, var(--gray-30) 50%, transparent 50%);
        background-position: 0 0;
        background-repeat: repeat-x;
        background-size: 8px 1px;
      }
    }
  }
}