.notifications {
  padding-top: 125px;
  padding-bottom: 20px;
  background: var(--bg);

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0;
  border-radius: 20px;
  background: var(--full-dark);

  &.error {
    border: 1px solid var(--color-dark-red);
  }

  &.info {
    border: 1px solid var(--color-green);
  }

  @media screen and (min-width:992px) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 30px;
  }

  &__info {
    padding: 9px;
    display: flex;
    align-items: center;
    column-gap: clamp(15px, -0.046rem + 2.05vw, 30px);

    @media screen and (min-width:992px) {
      padding: 19px;
    }
  }

  &__icon {
    width: 42px;
    height: 44px;
    flex-shrink: 0;
  }

  &__desc {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(16px, 0.642rem + 1.38vw, 24px);
    color: var(--white);
  }

  &.error .notification__desc {
    color: var(--color-dark-red);
  }

  &.info .notification__desc {
    color: var(--color-green);
  }

  &__form {
    padding: 0 0 9px 0;
    display: flex;
    align-items: center;
    gap: 10px;

    @media screen and (min-width:992px) {
      padding: 15px 15px 15px 0;
    }

    input {
      width: clamp(177px, -17.813rem + 140vw, 240px);
      height: 51px;
      padding: 15px;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 137%;
      border-radius: 6px;
      color: var(--full-dark);
      background: var(--white);

      &::placeholder {
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 16px;
        line-height: 137%;
        color: var(--full-dark);
      }

      @media screen and (min-width:375px) {
        width: clamp(240px, -11.362rem + 42.52vw, 450px);
      }
    }

    & button[type="submit"] {
      width: clamp(94px, 0.139rem + 9.25vw, 141px);
      min-height: 50px;
    }
  }
}